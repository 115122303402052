import {
    ACCOUNT_GROUPING_API_FAILED,
    ACCOUNT_GROUPING_API_REQUEST,
    ACCOUNT_GROUPING_API_SUCCESS
} from "./AccountGroupingActionTypes";

const initialState={
    loading: false,
    message: "",
    accountGroupings: [],
    groupAccounts: [],
    error: {},
    pagination: {},
    level: 1
}
export default function AccountGroupingReducer(state=initialState, action){
    const {type,payload,message,loading,error} = action;
    switch (type) {
        /**
         * Shops
         */
        case ACCOUNT_GROUPING_API_REQUEST:
            return {
                loading,
            };
        case ACCOUNT_GROUPING_API_SUCCESS:
            return {
                loading,
                accountGroupings: payload?.service_provider_groupings,
                groupAccounts: payload?.service_provider_groupings?.saving_account,
                message,
                pagination: payload,
                level: payload?.service_provider_groupings?.level
            }
        case ACCOUNT_GROUPING_API_FAILED:
            return {
                loading,
                error
            };
        default:
            return state
    }
}