import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import {
    TableContainer, Table, TableBody, TableRow, TableCell, Paper, Grid, DialogContent, DialogActions, Button
} from '@mui/material';
import AuthService from 'src/core/access-control/AuthService';
import call from 'src/core/services/http';
import { CustomCell, CustomRow } from 'src/shared/TableComponents/TableStyles';
import ListHead from 'src/shared/TableComponents/ListHead';
import { toast } from 'react-toastify';
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import { LoadingButton } from '@mui/lab';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';


const TABLE_HEAD = [
    { id: 'id', label: " Id ", alignLeft: true },
    { id: 'name', label: " Description ", alignLeft: true },
    { id: 'amount', label: "Bill Amount", alignLeft: true },
    { id: 'paid_amount', label: "Paid Amount", alignLeft: true },
    { id: 'created_at', label: "Date", alignLeft: true },
    { id: 'status', label: "Status", alignLeft: true },
    { id: 'view', label: "Payers", alignLeft: true },




];

const CreatedSplitBills = () => {
    const [shop] = useLocalStorage('shop-details');
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState({});
    const merchantId = AuthService.getUserId();
    const [open, setOpen] = useState(false);
    const [row, setrow] = useState({});
    const [isNotFound, setIsNotFound] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [beneficiary, setbeneficiary] = useState({})




    




    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = Object.keys(data).map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected({});
    };

    const getCreatedBills = async () => {
        try {
            const response = await call('get', `/bill-presentment/split-bill/?account_number=${shop.account_number}`);
            if (response.status === 200) {
                setData(response.data);
                setIsLoading(false);
            } else {
                setIsNotFound(true);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsNotFound(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCreatedBills();
    }, []);

    const processBill = async (e) => {
        const { id } = row;

        setIsLoading(true);
        try {
            const response = await call('post', `/bill-presentment/process-split-bill/${id}`);
            if (response.status === 200) {
                toast.success("Payment successful!");
            } else {
                toast.error("Payment failed!");
            }
        } catch (error) {
            toast.error("Payment error!");
        } finally {
            setIsLoading(false);
            setOpen(false);
        }
    };


    const handleClickOpen = (rowData) => {
        setrow(rowData);
        setOpenView(true);
    };


    const handleClose = () => {
        setOpenView(false);

    };




  const getBeneficiaries= async () => {
    try {
      console.log(shop, "ShopId");
      const response = await call('get', `/all-subscribers/${shop.id}/beneficiary/${AuthService.getUserId()}`);
      if (response.data.status) {
        setbeneficiary(response.data);
        console.log(response.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getBeneficiaries();
  }, []);


  


    return (
        <>


            <CustomDialog fullWidth open={openView} onClose={handleClose}>
                <CustomDialogTitle>Split bill Payers</CustomDialogTitle>
                <DialogContent>
                    {row && row.split_bills ? (
                        row.split_bills.map((splitBill, index) => (
                            <div key={index}>
                                {/* <Typography variant='subtitle2'>
                                <strong>Bill Name:</strong> {splitBill.bill_name}
                            </Typography> */}
                                <Grid container>
                                    <Grid direction={'column'}>
                                        <Typography variant='subtitle2'>
                                            <strong>Mobile Number:</strong> {splitBill.notification_mobile_number}
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                         <strong>Status:</strong> {splitBill.status === 0 ? 'not paid' : splitBill.status === 1 ? 'paid' : ''}
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                            <strong>Amount:</strong> {splitBill.amount}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <br />
                            </div>
                        ))
                    ) : (
                        <Typography variant='subtitle2'>No payers found</Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </CustomDialog>

            {/* table header */}

            <Card sx={{ minWidth: 275 }}>
                <CardContent>
                    <Typography sx={{ fontSize: 18 }} color="text.error" gutterBottom>
                        Created Split Bills
                    </Typography>
                </CardContent>

                {isLoading ? (
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <SpinnerLoader />
                    </Grid>
                ) : (
                    <TableContainer sx={{ minWidth: 800,  position: 'relative' }}>
                        <Table size={'medium'}>
                            <ListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />

                            {isNotFound ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>
                                                <Typography variant="body2">
                                                    No results found for <strong>{shop.account_number}</strong>
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {data.my_created_split_bills.map((row, index) => {

                                        const { id, name, amount, paid_amount, created_at, status, split_bills } = row;
                                        const rowNumber = index + 1;
                                        return (
                                            <CustomRow hover key={id}>
                                                <CustomCell >{rowNumber}</CustomCell>
                                                <CustomCell >{name}</CustomCell>
                                                <CustomCell >{amount}</CustomCell>
                                                <CustomCell >{paid_amount}</CustomCell>
                                                <CustomCell >{created_at}</CustomCell>
                                                <CustomCell color='primary'>
                                                    {status === 0 ? "Pending" : status === 1 ? "Processed" : ""}
                                                </CustomCell>
                                                <CustomCell align="left">
                                                    <Button size='small' variant='text'
                                                        onClick={() => handleClickOpen(row)}

                                                    >
                                                        View
                                                    </Button>
                                                </CustomCell>
                                            </CustomRow>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                )}
            </Card>

        </>
    )
}

export default CreatedSplitBills;