export const AUTH_API_REQUEST = "AUTH/API_REQUEST";
export const AUTH_API_SUCCESS = "AUTH/API_SUCCESS";
export const AUTH_API_FAILED = "AUTH/API_FAILED";
export const VERIFY_AUTH_API_SUCCESS = "AUTH/VERIFY_AUTH_API_SUCCESS";
export const VERIFY_AUTH_API_FAILED = "AUTH/VERIFY_AUTH_API_FAILED";
export const OPERATOR_AUTH_API_REQUEST = "AUTH/OPERATOR_AUTH_API_REQUEST";
export const OPERATOR_AUTH_API_SUCCESS = "AUTH/OPERATOR_AUTH_API_SUCCESS";
export const OPERATOR_AUTH_API_FAILED = "AUTH/OPERATOR_AUTH_API_FAILED";

export const AUTH_LOGOUT_REQUEST = "AUTH/AUTH_LOGOUT_REQUEST"
export const REGISTER_MERCHANT_API_REQUEST = "AUTH/REGISTER_MERCHANT_API_REQUEST"
export const REGISTER_MERCHANT_API_SUCCESS = "AUTH/REGISTER_MERCHANT_API_SUCCESS"
export const REGISTER_MERCHANT_API_FAILED = "AUTH/REGISTER_MERCHANT_API_FAILED"