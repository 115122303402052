import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
// @mui
import { Box, Divider, Container, Stack } from '@mui/material';
import MainHeader from './MainHeader';
import MainFooter from './MainFooter';
// components


const ContainerStyle = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
}));



// ----------------------------------------------------------------------

export default function MainLayout() {


    return (
        <Stack sx={{ minHeight: 1 }}>
            <MainHeader />

            <Outlet />

            <Box sx={{ flexGrow: 1 }} />


            <Box
                sx={{
                    pt: 5,
                    textAlign: 'center',
                    position: 'relative',
                    bgcolor: 'background.default',
                }}
            >
                <Divider />
                <ContainerStyle>
                    <Container maxWidth="xl"
                    >
                        <MainFooter />
                    </Container>
                </ContainerStyle>
            </Box>
        </Stack>
    );
}
