import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const CommissionLinks = [
   // {
     //   title: 'Commission',
       // path: '/shop-dashboard/savings-account/commission',
        //icon: getIcon("game-icons:coins"),
    //},
    {
        title: 'Referred Accounts',
        path: '/shop-dashboard/savings-account/referred-accounts',
        icon: getIcon("material-symbols:switch-account-rounded"),
    },

    // {
    //     title: 'Dealer Taransactions',
    //     path: '/shop-dashboard/savings-account/dealer-transactions',
    //     icon: getIcon("carbon:cics-transaction-server-zos"),
    // },
]
