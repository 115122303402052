import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard/DashboardLayout";
import providerConfiguration from "src/layouts/dashboard/nav/providerConfiguration";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import Profile from "../JaziaProviderNew/Profile";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Statistics = Loadable(lazy(() => import("../JaziaProviderNew/Statistics")));
const ManageLoans = Loadable(lazy(() => import("../JaziaProviderNew/ManageLoans")));
const Settings = Loadable(lazy(() => import("../JaziaProviderNew/Settings")));
const UrlSettings = Loadable(lazy(() => import("../JaziaProviderNew/UrlSettings")));
const Subscribers = Loadable(lazy(() => import("../JaziaProviderNew/Subscribers")));
const MySponsors = Loadable(lazy(() => import("../JaziaProviderNew/MySponsors")));


export const ProviderRoutes = [
    {
        path: 'provider-dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout navConfig={providerConfiguration} />
            </AuthGuard>
        ),
        children: [
            { element: <Navigate to="/provider-dashboard/app" />, index: true },
            { path: 'app', element: <Statistics /> },
            { path: 'statistics', element: <Statistics /> },
            { path: 'configurations', element: <Settings /> },
            { path: 'url-settings', element: <UrlSettings /> },
            { path: 'my-sponsors', element: <MySponsors /> },
            { path: 'subscribers', element: <Subscribers /> },
            { path: 'manage-loans', element: <ManageLoans /> },
            { path: 'profile/:id', element: <Profile /> },
            { path: '*', element: <Statistics /> },
        ],
    },
];