import { styled } from "@mui/material/styles";
import { Slide, TableCell, tableCellClasses, TableRow } from "@mui/material";

const CustomCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.action.selected,
        color: theme.palette.common.black,
        fontWeight: 600,
        fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
        backgroundColor: theme.palette.primary,
        color: theme.palette.common.black,
        fontSize: 12,
    },
  
  
}));

const CustomRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        // backgroundColor: theme.palette.action.selected,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export { CustomRow, CustomCell }