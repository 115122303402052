import GuestGuard from "../../../shared/guards/GuestGuard";
import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import MainLayout from "src/shared/layouts/main/MainLayout";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};

const Login = Loadable(lazy(() => import('../../auth/view/LoginPage')));
const Register = Loadable(lazy(() => import('../../auth/view/RegisterPage')));
const Individuals = Loadable(lazy(() => import('../../auth/view/Individuals')));
const ForgotPasswordForm = Loadable(lazy(() => import('../../auth/components/ForgotPasswordForm')));

export const AuthRoutes = [
    {
        path: 'auth',
        element: <MainLayout />,

        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                )
            },
            {
                path: 'individuals',
                element: (
                    <GuestGuard>
                        <Individuals />
                    </GuestGuard>
                )
            },
            {
                path: 'individuals/:dealer_number',
                element: (
                    <GuestGuard>
                        <Individuals />
                    </GuestGuard>
                )
            },
            {
                path: 'forgot-password',
                element: (
                    <GuestGuard>
                        <ForgotPasswordForm />
                    </GuestGuard>
                )
            }
        ],
    },
];