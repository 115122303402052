import React from 'react'
import useLocalStorage from 'src/shared/hooks/useLocalStorage'
import { useState, useEffect } from 'react';
import call from 'src/core/services/http';
import { Table, TableContainer, TableBody, TableRow } from '@mui/material';
import ListHead from 'src/shared/TableComponents/ListHead';
import { CustomCell } from 'src/shared/TableComponents/TableStyles';
import Label from './../../../shared/components/label/Label';
import { CustomRow } from 'src/shared/TableComponents/TableStyles';
import Iconify from './../../../shared/components/Iconify';
import { fDateTime } from 'src/shared/utils/formatTime';
import { useNavigate } from 'react-router-dom';

export default function Reports() {
    const [loading, setFetching] = useState(false)
    useEffect(() => {
        getData()
    }, [])

    const [shop] = useLocalStorage('shop-details')
    const [items, setItems] = useState([])
    //https://api.sasapay.me/apps/merchants-portal/api/v1/reports/?account_number=99407
    async function getData() {
        const url = `/reports/?account_number=${shop.account_number}&page=1&page_size=20`
        setFetching(true)

        const response = await call("get", url);

        setItems(response.data.data)
    }
    const headers = [
        { id: "id", label: "SNo#" },
        // { id: "status", label: "status" },
        { id: "initiation_time", label: "Request Time" },
        { id: "task_process", label: "" },
    ]


    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');

    const open = (report_url) => {
        window.open(report_url, '_blank');
    }
    return (
        <TableContainer>
            <Table size='medium'>
                {/* <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={headers}
                    rowCount={items.length || 0}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                /> */}
                <CustomRow>
                    <CustomCell>SNo#</CustomCell>
                    <CustomCell>Initiation Time</CustomCell>
                    <CustomCell>Status</CustomCell>
                </CustomRow>
                <TableBody>
                    {items && items.map((item) => {
                        const color = item.status === 'SUCCESS' ? 'success' : item.status === 'PENDING' ? 'warning' : item.status === 'STARTED' ? 'info' : 'error'
                        return (
                            <CustomRow key={item.id}>
                                <CustomCell>{item.id}</CustomCell>
                                {/* <CustomCell><Label variant='filled' sx={{ color: '#fff' }} color={color}>{item.status}</Label></CustomCell> */}
                                <CustomCell>{fDateTime(item.started_at)}</CustomCell>
                                <CustomCell>
                                    {item.status === "SUCCESS" ?
                                        <Label onClick={() => open(item.report_url)}
                                            startIcon={<Iconify icon={'ri:cloud-fill'} width={20} height={20} />}
                                            variant='filled'
                                            sx={{ color: '#fff', cursor: 'pointer' }} color='success'>
                                            Download</Label>
                                        : <Label variant='filled' sx={{ color: '#fff' }} color={color}>{item.status}</Label>}
                                </CustomCell>
                            </CustomRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
