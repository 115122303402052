import { Box, Typography, Button, Card, CardContent, Tabs, Grid, Stack, InputAdornment, ListItemText, MenuItem, OutlinedInput, InputLabel, FormControl, Select, DialogContent, DialogActions, Tab, CardHeader, TextField, Checkbox, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { withStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { filter, upperCase } from 'lodash';
import UserConstants from 'src/packages/users/store/UserConstants';
import call from 'src/core/services/http';
import { FormProvider, RHFTextField } from "../../../../../shared/components/hook-form";
import TransactionConstants from '../../../store/TransactionConstants';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';
import { CenteredCard } from 'src/shared/components/global';
import Iconify from 'src/shared/components/Iconify';
import MuiPhoneNumber from 'material-ui-phone-number';

const currencies = [
    {
        key: '840',
        label: 'USD',
        image: '$'
    },
    {
        key: '978',
        label: 'EUR',
        image: '€'
    },
    {
        key: '826',
        label: 'GBP',
        image: '£'
    }
]
const defaultValues = {
    recipient_account_number: '',
    sender_account_number: '',
    reason: '',
    channel_key: '840',
    sending_amount: '',
    receiving_amount: '',
    recipient_account_country: 'CN',
    recipient_phone_number: '',
    recipient_email_address: '',
    recipient_id_type: 'National ID',
    recipient_id_number: '',
    recipient_financial_institution: '',
    recipient_city: '',
    recipient_state: '',
    recipient_postalcode: '',
    recipient_nationality: 'Tanzanian',
    recipient_account_name: '',
    recipient_address: '',
}

const ValidationSchema = Yup.object().shape(
    {
        recipient_account_number: Yup.string()
            .when("option", {
                is: (option) => option === "1" || option === "0",
                then: Yup.string().required(`Please enter the account number`),
            }),
        receiving_amount: Yup.number().required('You can`t proceed'),
        recipient_postalcode: Yup.string().required('Postal Code is required'),
        channel_key: Yup.number().required("This is required"),
        recipient_account_country: Yup.string().required('You can`t proceed'),
        recipient_city: Yup.string().required('Please provide the recipient cuty'),
        recipient_account_name: Yup.string().required('Please provide the recipient account holder name'),
        recipient_address: Yup.string().required('Please provide the recipient address'),
        recipient_nationality: Yup.string().required('Please provide the recipient nationality'),
        recipient_state: Yup.string().required('Please provide the recipient state'),
        recipient_id_type: Yup.string().required('Please provide Identity Type'),
        recipient_id_number: Yup.string().required('Please provide Identity/Passport Number'),
        recipient_phone_number: Yup.string().required('Please provide the recipient phone number'),
        recipient_email_address: Yup.string().email().required('Please provide the recipient email address'),
        recipient_financial_institution: Yup.string().required('Please provide the financial institution'),
        sender_account_number: Yup.string(),
        sending_amount: Yup.string().required("Please enter the amount"),
        // reason: Yup.string().when("option", {
        //     is: (option) => option === "3",
        //     then: Yup.string().required("Please enter the account number"),
        // }),
    }
)

const ITEM_HEIGHT = 79;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function InternationalTransfers() {

    const [isLoading, setIsLoading] = useState(true);
    const [shop] = useLocalStorage('shop-details')
    const methods = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues,
    });

    const { handleSubmit, setValue, watch, reset,
        formState: { isSubmitting, errors } } = methods;
    const values = watch()
    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState('')
    const [inputValue, setInputValue] = useState('')



    useEffect(() => {
        combinedFetchAsync()
    }, [])


    const [remit_countries, set_remit_countries] = useState([])
    const [rates, set_rates] = useState([])
    const [banks, set_banks] = useState([])
    const [nationalities, set_nationalities] = useState([])


    const combinedFetchAsync = async () => {
        setIsLoading(true)
        const [countries_resp, rates_resp, banks_resp, nationalities_response] = await Promise.all([
            call('get', '/transfers/remit-countries/').catch((e) => toast.error(e.response.data.message)),
            call('get', '/transfers/exhange-rates/').catch((e) => toast.error(e.response.data.message)),
            call('get', '/transfers/remit-banks/').catch((e) => toast.error(e.response.data.message)),
            fetch('https://restcountries.com/v3.1/all?fields=demonyms,flags')
        ])

        setIsLoading(false)
        let nationalities_ = await nationalities_response.json()
        set_remit_countries(countries_resp.data.remit_countries)
        set_banks(banks_resp.data.data)
        set_rates(rates_resp.data.data)
        set_nationalities(nationalities_)
    }

    const [start, setStart] = useState(false)
    const receivingCurrency = filter(rates, p => p.toCurrencyCode === parseInt(values.channel_key))[0]
    const currency = filter(currencies, p => p.key == (values.channel_key))[0]

    const onKesChange = () => {
        const amount = values.sending_amount

        if (receivingCurrency) {
            const sellRate = receivingCurrency.sellRate

            setValue('receiving_amount', parseFloat(amount / sellRate).toFixed(3))
        }
    }
    const onForeignChange = () => {
        const amount = values.receiving_amount


        if (receivingCurrency) {
            const sellRate = receivingCurrency.sellRate

            setValue('sending_amount', parseInt(amount * sellRate))
        }
    }

    const onSubmit = async () => {
        const data = {
            ...values,
            sender_account_number: shop.account_number,
            sending_account_currency: shop.product_currency_code,
            recipient_account_currency: currency.label,
            receiving_method: "1"
        }

        const req = await call('post', '/transfers/remittance/', data).catch(e => toast.error(e.response.data.message))

        if (req.data.status) {
            reset()
            setStart(!start)
            toast.success(req.data.message)
        } else {
            toast.error(req.data.message)
        }

    }
    return (
        <CenteredCard>
            <CardHeader
                title={
                    <Typography variant="h6">
                        International Transfers
                    </Typography>
                }
                sx={{ mb: 3 }}
            />

            <CardContent>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <CustomDialog onClose={() => setOpen(false)}
                        maxWidth='sm'
                        fullWidth
                        aria-labelledby="dialog"
                        open={open}>
                        <CustomDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                            Confirm
                        </CustomDialogTitle>
                        <DialogContent>
                            {confirmation}
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton onClick={handleSubmit(onSubmit)} variant='contained' size='small' loading={isSubmitting} color='info' >Confirm</LoadingButton>
                            <Button size='small' onClick={() => setOpen(false)} color='error' variant='contained'>Cancel</Button>
                        </DialogActions>
                    </CustomDialog>

                    <Stack spacing={2}>
                        <FormControl>
                            <InputLabel id="countries-label">Remit Money To</InputLabel>
                            <Select
                                label='Remit Money To'
                                input={<OutlinedInput label="Remit Money To" />}
                                onChange={(e) => setValue('recipient_account_country', e.target.value)}
                                value={values.recipient_account_country}
                                renderValue={(e) => {
                                    const selected = filter(remit_countries, p => p.country_code == e)[0]

                                    return (
                                        <Stack direction={'row'}>
                                            <img
                                                loading="lazy"
                                                width="30"
                                                height="20"
                                                src={selected?.flag}
                                                srcSet={selected?.flag}
                                                alt={selected?.name}
                                            />
                                            <Typography variant='button' sx={{ ml: 1, mr: 4 }}>{upperCase(selected?.name)}</Typography>
                                        </Stack>
                                    )
                                }}
                                sx={{
                                    '& fieldset': {
                                        borderWidth: `1px !important`,
                                        borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
                                    },
                                }}
                                labelId="currency-label"
                                name='currency'
                                MenuProps={MenuProps}>
                                {remit_countries.map(({ id, name, country_code, flag }) => (
                                    <MenuItem key={id} value={country_code}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={flag}
                                            srcSet={flag}
                                            alt={name}
                                        />
                                        <Typography variant='caption' sx={{ ml: 1 }}>{upperCase(name)}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Stack spacing={2} direction='row'>
                            <RHFTextField
                                name="sending_amount"
                                size={'medium'}
                                onKeyUp={onKesChange}
                                label='You Send Exactly'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{shop.product_currency_code}</InputAdornment>
                                    ),
                                }}
                            />
                            <Iconify sx={{ m: 0.5, width: 50, height: 50 }} icon={'ci:arrow-left-right'} />

                            <RHFTextField
                                size={'medium'}
                                name="receiving_amount"
                                focused
                                onKeyUp={onForeignChange}
                                label='Recipient Gets'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Select
                                                onChange={(e) => {
                                                    setValue('channel_key', e.target.value);
                                                    onForeignChange(e)
                                                }}
                                                value={values.channel_key}
                                                renderValue={(e) => {
                                                    const selected = filter(currencies, p => p.key == e)[0]

                                                    return (
                                                        <Stack direction={'row'}>
                                                            <Typography variant='button'>{selected.label}</Typography>
                                                        </Stack>
                                                    )
                                                }}
                                                sx={{
                                                    '& fieldset': {
                                                        borderWidth: `0px !important`,
                                                        borderColor: (theme) => `${theme.palette.grey[500_32]} !important`,
                                                    },
                                                }}
                                                labelId="currency-label"
                                                name='currency'
                                                MenuProps={MenuProps}>
                                                {currencies.map(({ key, label, image }) => (
                                                    <MenuItem key={key} value={key}>
                                                        <Typography variant='button' sx={{ ml: 1 }}>{image} {label}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Stack>
                        <Stack spacing={2} direction='row'>
                            <Typography variant='' sx={{ color: 'blue' }}>Exchange rate {shop.product_currency_code}{receivingCurrency?.sellRate} = 1{currency.label}</Typography>
                        </Stack>
                        {start &&
                            <>

                                <Stack spacing={2} direction='row'>
                                    <MuiPhoneNumber
                                        fullWidth
                                        defaultCountry='tz'
                                        onChange={phone => {
                                            setValue("recipient_phone_number", phone)
                                        }}
                                        value={values.recipient_phone_number}
                                        variant="outlined"
                                        label="Recepient Phone Number"
                                    />

                                    <RHFTextField
                                        name="recipient_address"
                                        size={'medium'}
                                        label='Recipient Address'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='ci:house-check' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    <RHFTextField
                                        name="recipient_account_number"
                                        size={'medium'}
                                        label='Recipient Account Number'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='mdi:account-cash' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                    <RHFTextField
                                        name="recipient_account_name"
                                        size={'medium'}
                                        label='Account Holder Name'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='gridicons:nametag' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    <Autocomplete
                                        value={values.recipient_nationality}
                                        onChange={(event, recipient_nationality) => {
                                            setValue('recipient_nationality', recipient_nationality?.demonyms?.eng?.m);
                                        }}
                                        placeholder='Start typing...'
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        fullWidth
                                        options={nationalities}
                                        autoHighlight
                                        getOptionLabel={(option) => option}//?.demonyms?.eng?.m
                                        renderOption={(props, { demonyms: { eng: { f, m } }, flags: { png, alt } }) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="30"
                                                    height="20"
                                                    src={png}
                                                    srcSet={png}
                                                    alt={alt}
                                                />
                                                <Typography variant='button' sx={{ ml: 1, mr: 4 }}>{upperCase(m)}</Typography>
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Recipient Nationality"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />

                                </Stack>
                                <Stack spacing={2} direction='row'>
                                    <RHFTextField
                                        select
                                        name="recipient_id_type"
                                        size={'medium'}
                                        label='Recipient Identity Type'
                                        MenuProps={MenuProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='wpf:name' /></InputAdornment>
                                            ),
                                        }}
                                    >
                                        {["National ID", "Passport"].map((item) => {
                                            return (
                                                <MenuItem key={item} value={item}>
                                                    {item}
                                                </MenuItem>
                                            );
                                        })}
                                    </RHFTextField>

                                    <RHFTextField
                                        name="recipient_id_number"
                                        size={'medium'}
                                        label={`Recipient ${values.recipient_id_type}`}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='icon-park-twotone:passport' /></InputAdornment>
                                            ),
                                        }}
                                    />


                                </Stack>

                                <Stack spacing={2} direction='row'>
                                    <RHFTextField
                                        name="recipient_email_address"
                                        size={'medium'}
                                        label='Recipient Email Address'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='fluent:city-16-filled' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                    <RHFTextField
                                        select
                                        name="recipient_financial_institution"
                                        size={'medium'}
                                        label='Recipient Bank'
                                        MenuProps={MenuProps}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='mdi:bank' /></InputAdornment>
                                            ),
                                        }}
                                    >
                                        {banks.map(({ name, id }) => {
                                            return (
                                                <MenuItem key={id} value={name}>
                                                    {name}
                                                </MenuItem>
                                            );
                                        })}
                                    </RHFTextField>

                                </Stack>

                                <Stack spacing={2} direction='row'>
                                    <RHFTextField
                                        name="recipient_city"
                                        size={'medium'}
                                        label='Recipient City'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='fluent:city-16-filled' /></InputAdornment>
                                            ),
                                        }}
                                    />

                                    <RHFTextField
                                        name="recipient_state"
                                        size={'medium'}
                                        label='Recipient State'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='uil:estate' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                    <RHFTextField
                                        name="recipient_postalcode"
                                        size={'medium'}
                                        label='Recipient Postal Code'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"><Iconify icon='map:postal-code' /></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Stack>

                                <LoadingButton loading={isSubmitting} variant='contained' sx={{ mt: 2, borderRadius: '50px', width: '100' }} type='submit' size='medium' >Initiate Transfer</LoadingButton>
                            </>}

                    </Stack>
                    {!start && <LoadingButton loading={isLoading} onClick={() => setStart(!start)} variant='contained' sx={{ mt: 2, borderRadius: '50px', width: '100' }} size='medium' >Get Started</LoadingButton>}

                </FormProvider>
            </CardContent>
        </CenteredCard>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default InternationalTransfers