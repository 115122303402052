import React, { useState, useEffect } from 'react';


import {
    Table, TableHead, TableBody, CircularProgress, Tooltip, IconButton, TableContainer, TableCell, Button, MenuItem, Backdrop, Dialog, Slide, AppBar, Toolbar, Typography, DialogActions, DialogContent,
} from "@mui/material";
import Badge from '@mui/material/Badge';
import Scrollbar from "src/shared/components/Scrollbar";
import { StyledTableCell, StyledTableRow } from 'src/shared/sections/@dashboard/tableStyle/tableBody';
import { formatDate } from 'src/shared/utils/helpers/helpers';
import { useSelector, useDispatch, } from 'react-redux';
import Paper from '@mui/material/Paper';
import { fCurrency } from 'src/shared/utils/formatNumber';
import ProcessedBulkPaymentPDF from './ProcessedBulkPaymentPDF';
import Iconify from 'src/shared/components/Iconify';
import { closeSingleModal, getSingleBulkPaymentList } from '../store/BulkpaymentActions';
import CloseIcon from '@mui/icons-material/Close';
import MenuPopover from 'src/shared/components/MenuPopOver';
import styles from './BulkStyles';
import { PDFDownloadLink } from '@react-pdf/renderer';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { ExportExcel } from 'src/shared/components/ExportExcel';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const exportShape = [
    { key: 'id', label: 'SNo#' },
    { key: 'mobile_number', label: 'Mobile Number' },
    { key: 'name', label: 'Name' },
    { key: 'channel_code', label: 'Channel' },
    { key: 'amount', label: 'Transaction Amount' },
    { key: 'comment', label: 'Transaction Comment' },
    { key: 'charge', label: 'Transaction Charge', sumColumn: true },
    { key: 'created_on', label: 'Uploaded On', isDate: true },
    {
        key: 'status',
        label: 'Status',
        options: {
            1: 'Staged',
            2: 'Processing',
            3: 'Success',
            4: 'Fail',
        }
    },
   
];

const TABLE_HEAD = [
    { id: 'reason', label: "Reason", alignLeft: true },
    { id: 'make', label: "Maker", alignLeft: true },
    { id: 'checker', label: "Checker", alignLeft: true },
    { id: 'totalRecords', label: "Total Records", alignLeft: true },
    { id: 'totalProcessed', label: "Total Records Processed", alignLeft: true },
    { id: 'totalPayable', label: "Total Payable", alignLeft: true },
    { id: 'totalPaid', label: "Total Paid", alignLeft: true },
    { id: 'totalCharge', label: "Total Charge", alignLeft: true },
    { id: 'createdOn', label: "Created On", alignLeft: true },
    { id: 'action', label: 'Action ', alignLeft: true },
]

const SINGLE_TABLE_HEAD = [
    { id: 'name', label: 'Name', alignLeft: true },
    { id: 'account', label: 'Mobile/Account Number', alignLeft: true },
    { id: 'channel', label: 'Channel', alignLeft: true },
    { id: 'amount', label: 'Amount', alignLeft: true },
    { id: 'charge', label: 'Charge', alignLeft: true },
    { id: 'reference', label: 'Payment Reference', alignLeft: true },
    { id: 'createdOn', label: 'Created On', alignLeft: true },
    { id: 'status', label: 'Status', alignLeft: true },
];


function ProcessedBulkPayments({ handleFetchSingleRecord }) {

    const { error, openSingle, reason, loading, singleBulkPaymentList, bulkPaymentList } = useSelector(
        (state) => state.bulkpayments
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };


    const fetchSingleRecord = (item) => {
        const index = bulkPaymentList.indexOf(item);
        item = bulkPaymentList[index];
        const { id, reason } = item;
        dispatch(getSingleBulkPaymentList({ id, reason }));
    };


    const [shop] = useLocalStorage('shop-details')


    return (
        <><Scrollbar>
            <TableContainer component={Paper} sx={{ position: 'relative' }}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <StyledTableRow>
                            {TABLE_HEAD.map((headCell) => (
                                <StyledTableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>{headCell.label}</StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    {error ?
                        <StyledTableCell>Internal server Error</StyledTableCell>
                        :
                        <TableBody>
                            {(bulkPaymentList && bulkPaymentList.length > 0) ? bulkPaymentList.filter(record => record.status !== 0).map((row, index) => {
                                const { id, reason, created_by, approved_by, total_records, totals_paid, total_records_processed, totals_payable, total_charge, created_on } = row;
                                return (
                                    <StyledTableRow
                                        hover
                                        key={id}
                                        tabIndex={-1}
                                        role="checkbox"
                                    >
                                        <StyledTableCell align="right">{reason}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">{created_by?.operator?.display_name}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">{approved_by?.operator?.display_name}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">{total_records}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">{total_records_processed}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{fCurrency(totals_payable, shop.currency_code)}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{fCurrency(totals_paid, shop.currency_code)}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{fCurrency(total_charge, shop.currency_code)}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">{formatDate(created_on)}</StyledTableCell>
                                        <TableCell align="right">
                                            <MoreMenuButton
                                                handleFetchSingleRecord={() => fetchSingleRecord(row)} />
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            }) : <></>}
                        </TableBody>}
                </Table>
            </TableContainer>
        </Scrollbar><Backdrop sx={{ backgroundColor: '#FFFFFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openSingle}>
                <Dialog
                    fullScreen
                    TransitionComponent={Transition}
                    open={openSingle}
                    onClose={() => dispatch(closeSingleModal({}))}
                    sx={{ m: 5, px: 5, backgroundColor: '#FFFFFF' }}
                >
                    <DialogContent>
                        <AppBar sx={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={() => dispatch(closeSingleModal({}))}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <Typography component="h3">{reason}</Typography>

                            </Toolbar>

                        </AppBar>
                        <TableContainer>
                            <Table size={'small'}>
                                <TableHead>
                                    <StyledTableRow>
                                        {SINGLE_TABLE_HEAD.map((headCell) => (
                                            <StyledTableCell size="small" key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                                                {headCell.label}
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                </TableHead>
                                {error ? (
                                    <StyledTableCell>Internal server Error</StyledTableCell>
                                ) : (
                                    <TableBody>
                                        {singleBulkPaymentList && singleBulkPaymentList.length > 0 ? (
                                            singleBulkPaymentList.map((row) => {
                                                const { id, mobile_number, name, channel_code, amount, comment, charge, created_on, status, status_message } = row;
                                                return (
                                                    <StyledTableRow hover key={id}>
                                                        <StyledTableCell component="th" scope="row" align="left">
                                                            {name}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" align="left">
                                                            {mobile_number}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" align="left">
                                                            {channel_code}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row" align="left">
                                                            {fCurrency(amount, shop.currency_code)}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">
                                                            {fCurrency(charge, shop.currency_code)}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">
                                                            {comment}
                                                        </StyledTableCell>
                                                        <StyledTableCell component="th" scope="row">
                                                            {formatDate(created_on)}
                                                        </StyledTableCell>
                                                        <StyledTableCell >
                                                            <Badge
                                                                color={
                                                                    status === 1 ? 'secondary' :
                                                                        status === 2 ? 'info' :
                                                                            status === 3 ? 'success' :
                                                                                status === 4 ? 'error' :
                                                                                    'default' // set default color for other statuses
                                                                }
                                                                badgeContent={
                                                                    status === 1 ? 'staged' :
                                                                        status === 2 ? 'processing' :
                                                                            status === 3 ? 'success' :
                                                                                status === 4 ? 'fail' :
                                                                                    status_message ? status_message : 'NA'
                                                                }
                                                            >
                                                            </Badge>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                );
                                            })
                                        ) : (
                                            <></>
                                        )}
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <ExportExcel
                            shape={exportShape }
                            // clickComponent={
                            //     <Button variant='contained' color='info'>
                            //         <Iconify icon={'eva:download-fill'} />
                            //         Download
                            //     </Button>
                            // }
                            data={singleBulkPaymentList}
                            fileName='bulk-records'
                            sheetName='bulk-records'
                            // showTable={true}
                        />

                    </DialogActions>
                </Dialog>
            </Backdrop></>
    )
}

export default ProcessedBulkPayments

function MoreMenuButton({ handleOpenProcessModal, processedBulkPaymentRecord, handleFetchSingleRecord }) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const ICON = {
        mr: 2,
        width: 20,
        height: 20,
    };


    return (
        <>
            <IconButton size="large" onClick={handleOpen}>
                <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={{
                    mt: -0.5,
                    width: 160,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <MenuItem
                    sx={{ color: 'primary.main' }}
                    onClick={() => {handleFetchSingleRecord();}}>
                    <Iconify icon={'eva:eye-fill'} sx={{ ...ICON }} />
                    View 
                </MenuItem>
            </MenuPopover>
        </>
    );
}


