import React, { useState } from 'react';
import { toast } from "react-toastify";
import {
    Table,
    TableHead,
    TableBody,
    TableCell,
    Backdrop,
    TableContainer,
    Slide,
    IconButton,
    MenuItem,
    Stack,
    DialogTitle,
    Dialog,
    DialogContent,
    AppBar,
    Toolbar,
    Typography,
    Card,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import Scrollbar from 'src/shared/components/Scrollbar';
import { StyledTableCell, StyledTableRow } from 'src/shared/sections/@dashboard/tableStyle/tableBody';
import { formatDate } from 'src/shared/utils/helpers/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { fCurrency } from 'src/shared/utils/formatNumber';
import Iconify from 'src/shared/components/Iconify';
import MenuPopover from 'src/shared/components/MenuPopOver';
import { closeSingleModal, getBulkPaymentList, getSingleBulkPaymentList } from '../store/BulkpaymentActions';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/system';
import { RHFTextField, FormProvider } from 'src/shared/components/hook-form';
import Badge from '@mui/material/Badge';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import call from 'src/core/services/http';
import BulkpaymentConstants from '../store/BulkpaymentConstants';
import SpinnerLoader from 'src/shared/plugin/loader/SpinnerLoader';
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
const notifySuccess = msg => {
    toast.success(msg)
}
const notifyError = msg => {
    toast.error(msg)
}
const TABLE_HEAD = [
    { id: 'reason', label: 'Reason', alignLeft: true },
    { id: 'make', label: 'Maker', alignLeft: true },
    { id: 'checker', label: 'Checker', alignLeft: true },
    { id: 'totalRecords', label: 'Total Records', alignLeft: true },
    { id: 'totalPayable', label: 'Total Payable', alignLeft: true },
    { id: 'totalCharge', label: 'Total Charge', alignLeft: true },
    { id: 'createdOn', label: 'Created On', alignLeft: true },
    { id: 'action', label: ' ', alignLeft: true },
];

const SINGLE_TABLE_HEAD = [
    { id: 'name', label: 'Name', alignLeft: true },
    { id: 'account', label: 'Mobile/Account Number', alignLeft: true },
    { id: 'channel', label: 'Channel', alignLeft: true },
    { id: 'amount', label: 'Amount', alignLeft: true },
    { id: 'charge', label: 'Charge', alignLeft: true },
    { id: 'reference', label: 'Payment Reference', alignLeft: true },
    { id: 'createdOn', label: 'Created On', alignLeft: true },
    { id: 'status', label: 'Status', alignLeft: true },
];

function UnprocessedBulkPayments() {
    const dispatch = useDispatch();
    const [shop] = useLocalStorage('shop-details');
    const { error, openSingle, reason, singleBulkPaymentList, bulkPaymentList } = useSelector(
        (state) => state.bulkpayments
    );

    const fetchSingleRecord = (item) => {
        const index = bulkPaymentList.indexOf(item);
        item = bulkPaymentList[index];
        const { id, reason } = item;
        dispatch(getSingleBulkPaymentList({ id, reason }));
    };

    // process bulkpayment
    const [recordId, setRecordId] = useState("")
    const openModalWithItemId = (item) => {
        const index = bulkPaymentList.indexOf(item);
        item = bulkPaymentList[index];
        const { id } = item;
        setRecordId(id)
        //console.log("The bulk Id", id)
        setOpenProcessModal(true)

    }
    const [openProcessModal, setOpenProcessModal] = useState(false);

    const bulkProcessSchema = Yup.object().shape({
        confirmation_code: Yup.string().required('please enter the confirmation code'),
    });

    const methods = useForm({
        resolver: yupResolver(bulkProcessSchema),
        defaultValues: {
            confirmation_code: '',
        },
    });

    const {
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting },
    } = methods;

    const [processing, setProcessing] = useState(false)
    const onSubmit = async () => {
        setProcessing(true)

        const { confirmation_code } = getValues();

        const payload = {
            bulk_payment_id: recordId,
            account_number: shop.account_number,
            confirmation_code,
        };

        const response = await call('post', BulkpaymentConstants.SINGLEBULKPAYMENT({ id: payload.bulk_payment_id }), payload)
        setProcessing(false)

        if (response.data.status) {
            notifySuccess(response.data.message)
            reset()
            setOpenProcessModal(false)
        } else {
            notifyError(response.data.message)
        }

    };
    const [loading, setLoading] = useState(false)


    return (
        <>
            <Scrollbar>
                <TableContainer sx={{ position: 'relative' }}>
                    <Table size={'small'}>
                        <TableHead>
                            <StyledTableRow>
                                {TABLE_HEAD.map((headCell) => (
                                    <StyledTableCell size="small" key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                                        {headCell.label}
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        </TableHead>
                        {error ? (
                            <StyledTableCell>Internal server Error</StyledTableCell>
                        ) : (
                            <TableBody>
                                {bulkPaymentList && bulkPaymentList.length > 0 ? (
                                    bulkPaymentList
                                        .filter((record) => record.status === 0)
                                        .map((row) => {
                                            const {
                                                id,
                                                reason,
                                                created_by,
                                                approved_by,
                                                total_records,
                                                totals_payable,
                                                total_charge,
                                                created_on,
                                            } = row;
                                            return (
                                                <StyledTableRow hover key={id}>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {reason}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {created_by?.operator?.display_name}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {approved_by?.operator?.display_name}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {total_records}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell component="th" scope="row" align="left">{total_records_processed}</StyledTableCell> */}
                                                    <StyledTableCell component="th" scope="row">
                                                        {fCurrency(totals_payable)}
                                                    </StyledTableCell>
                                                    {/* <StyledTableCell component="th" scope="row" >{fCurrency(totals_paid)}</StyledTableCell> */}
                                                    <StyledTableCell component="th" scope="row">
                                                        {fCurrency(total_charge)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {formatDate(created_on)}
                                                    </StyledTableCell>
                                                    <TableCell align="right">
                                                        <MoreMenuButton
                                                            handleFetchSingleRecord={() => fetchSingleRecord(row)}
                                                            handleOpenProcessModal={() => openModalWithItemId(row)}
                                                            row={row}
                                                            refresh={() => dispatch(getBulkPaymentList({
                                                                savings_id: shop.id
                                                            }))}
                                                            loading={loading}
                                                            setLoading={setLoading}
                                                        />
                                                    </TableCell>
                                                </StyledTableRow>
                                            );
                                        })
                                ) : (
                                    <></>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
            </Scrollbar>

            {/* View single bulk record */}
            <Backdrop sx={{ backgroundColor: '#FFFFFF', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openSingle}>
                <Dialog
                    fullScreen
                    TransitionComponent={Transition}
                    open={openSingle}
                    onClose={() => dispatch(closeSingleModal({}))}
                    sx={{ m: 5, px: 5, backgroundColor: '#FFFFFF' }}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => dispatch(closeSingleModal({}))}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography component="h3">{reason}</Typography>
                        </Toolbar>
                    </AppBar>
                    <TableContainer>
                        <Table size={'small'}>
                            <TableHead>
                                <StyledTableRow>
                                    {SINGLE_TABLE_HEAD.map((headCell) => (
                                        <StyledTableCell size="small" key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>
                                            {headCell.label}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableHead>
                            {error ? (
                                <StyledTableCell>Internal server Error</StyledTableCell>
                            ) : (
                                <TableBody>
                                    {singleBulkPaymentList && singleBulkPaymentList.length > 0 ? (
                                        singleBulkPaymentList.map((row) => {
                                            const { id, mobile_number, name, channel_code, amount, comment, charge, created_on, status, status_message } =
                                                row;
                                            return (
                                                <StyledTableRow hover key={id}>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {name}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {mobile_number}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {channel_code}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row" align="left">
                                                        {fCurrency(amount)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {fCurrency(charge)}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {comment}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {formatDate(created_on)}
                                                    </StyledTableCell>
                                                    <StyledTableCell >
                                                        <Badge
                                                            color={
                                                                status === 1 ? 'secondary' :
                                                                    status === 2 ? 'info' :
                                                                        status === 3 ? 'success' :
                                                                            status === 4 ? 'error' :
                                                                                'default' // set default color for other statuses
                                                            }
                                                            badgeContent={
                                                                status === 1 ? 'staged' :
                                                                    status === 2 ? 'processing' :
                                                                        status === 3 ? 'success' :
                                                                            status === 4 ? 'fail' :
                                                                                status_message ? status_message : 'NA'
                                                            }
                                                        >
                                                        </Badge>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Dialog>
            </Backdrop>

            {/* Process bulk record */}
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openProcessModal}>
                <Dialog
                    TransitionComponent={Transition}
                    open={openProcessModal}
                    onClose={() => setOpenProcessModal(false)}
                >
                    <DialogTitle>Enter the code received to process the bulkpayment</DialogTitle>
                    <DialogContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Card sx={{ p: 2 }}>
                                <Box
                                    sx={{
                                        display: 'grid',
                                        rowGap: 3,
                                        columnGap: 2,
                                        gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
                                    }}
                                >
                                    <RHFTextField name="confirmation_code" label="Confirmation Code" />
                                </Box>
                                <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                                    <LoadingButton type="submit" variant="contained" loading={processing}>
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Card>
                        </FormProvider>
                    </DialogContent>
                </Dialog>
            </Backdrop>

        </>
    );
}

export default UnprocessedBulkPayments;

function MoreMenuButton({ handleOpenProcessModal, handleFetchSingleRecord, row, refresh, setLoading, loading }) {
    const [open, setOpen] = useState(null);
    const [shop] = useLocalStorage('shop-details')

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const deleteRecord = async (e) => {
        const data = {
            "bulk_payment_id": row.id,
            "account_number": shop.account_number
        }
        setLoading(true)

        const request = await call('post', BulkpaymentConstants.DELETE_BULK_RECORD, data);

        setLoading(false)
        let data_response = request.data
        if (data_response.status) {
            toast.success(data_response.message)
            refresh()
        } else {
            toast.error(data_response.message)
        }
    }
    const ICON = {
        mr: 2,
        width: 20,
        height: 20,
    };

    return (
        <>

            <IconButton size="large" onClick={handleOpen}>
                <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={{
                    mt: -0.5,
                    width: 160,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <MenuItem
                    sx={{ color: 'primary.main' }}
                    onClick={() => { handleFetchSingleRecord(); }}>
                    <Iconify icon={'mdi:eye-outline'} sx={{ ...ICON }} />
                    View
                </MenuItem>
                <MenuItem sx={{ color: 'success.main' }}
                    onClick={() => handleOpenProcessModal()}>
                    <Iconify icon={'codicon:server-process'} sx={{ ...ICON }} />
                    Process
                </MenuItem>
                <MenuItem disabled={loading} onClick={deleteRecord} sx={{ color: 'error.main' }} >
                    <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
                    {loading ? 'Deleting...' : 'Delete'}
                </MenuItem>
            </MenuPopover>

        </>
    );
}
