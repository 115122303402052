import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Button,
  Typography,
  Stack,
} from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import Iconify from 'src/shared/components/Iconify';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

const ExcelTemplatePage = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      console.error('No file selected for upload');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch(
        // 'http://127.0.0.1:8000/apps/merchants-portal/api/v1/87/fintech/2169/bulk-invite-beneficiaries/',
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        console.log('File uploaded successfully');
      } else {
        console.error('File upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred during file upload:', error);
    }
  };

  return (
    <Card sx={{ maxWidth: 600, margin: 'auto', marginTop: 4, padding: 2 }}>
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button
            color="primary"
            variant="outlined"
            component={Link}
            to="/shop-dashboard/savings-account/beneficiary/registered"
          >
            <Iconify icon="uil:angle-left" />
            <strong>Back</strong>
          </Button>
          <Typography variant="h5" mb={2}>
            Bulk Invite
          </Typography>
        </Stack>

        <Typography>
          <strong>
            Download the Excel template, complete the required information, and then upload the filled template to invite beneficiaries in bulk.
          </strong>
        </Typography>

        <Button variant="contained" color="primary" style={{ marginTop: 16 }}>
          <strong>View Bulk Invite List</strong>
        </Button>

        <Button
          sx={{ my: 1 }}
          onClick={() => {
            window.location.href =
              'https://sasapay-merchants.s3.ap-southeast-1.amazonaws.com/sp-bulk-payment-template-v2.xlsx';
          }}
          size="small"
          color="info"
          variant="outlined"
          style={{ marginRight: 16, marginTop: 16 }}
        >
          <Iconify icon="eva:download-fill" width={24} height={24} />
          <strong>Download the Excel Bulk Invite Template and fill it.</strong>
        </Button>

        <Button variant="outlined" component="label" startIcon={<CloudUpload />}>
          <strong>Upload the filled Excel Bulk Invite Template file</strong>
          <input type="file" hidden onChange={handleFileChange} />
        </Button>

        <Button variant="contained" onClick={handleUpload} style={{ marginLeft: 16 }}>
          <strong>Submit</strong>
        </Button>
      </CardContent>
    </Card>
  );
};

export default ExcelTemplatePage;





