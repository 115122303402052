// component
import { DashboardLinks } from "../../../../packages/dashboard/routes/DashboardLinks";
import { ShopsLinks } from "../../../../packages/shops/routes/ShopsLinks";
import { MyBankLinks } from "../../../../packages/myBanks/routes/MyBankLinks";
import { PaymentTypeLinks } from "../../../../packages/paymentTypes/routes/PaymentTypeLinks";
import { AccountGroupingLinks } from "../../../../packages/account-grouping/routes/AccountGroupingLinks";
import { ScheduleSettlementLinks } from "../../../../packages/scheduleSettlement/routes/ScheduleSettlementLinks";
import { AutoSettlementLinks } from "../../../../packages/AutoSettlement/routes/AutoSettlementLinks";
 

const mainNavConfig = [...DashboardLinks, ...ShopsLinks, ...MyBankLinks, ...PaymentTypeLinks, ...AccountGroupingLinks,...ScheduleSettlementLinks,...AutoSettlementLinks];

export default mainNavConfig;
