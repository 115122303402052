import { BANK_API_FAILED, BANK_API_REQUEST, BANK_API_SUCCESS } from "./MyBankActionTypes";

const initialState = {
    loading: false,
    message: "",
    bank_accounts: [],
    error: {},
    pagination: {},
}

export default function MyBankReducer(state = initialState, action) {
    const { type, payload, message, loading, error } = action;
    switch (type) {
        /**
         * Shops
         */
        case BANK_API_REQUEST:
            return {
                loading,
            };
        case BANK_API_SUCCESS:
            return {
                loading,
                bank_accounts: payload?.bank_accounts,
                message,
                pagination: payload
            }
        case BANK_API_FAILED:
            return {
                loading,
                error
            };
        default:
            return state
    }
}