import { Typography } from "@mui/material";
import { Bars } from "react-loader-spinner";

export default function ({ sx, text }) {
    return (
        <div className="d-flex justify-content-center">
            <Bars
                height="50"
                width="50"
                color="#00AEEF"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                sx={{ ...sx }}
            />
            <Typography variant="caption">{text}</Typography>
        </div>
    )
}
