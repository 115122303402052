 

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  Box,
  Menu,
  Button,
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthService from 'src/core/access-control/AuthService';

import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';



const AccountPopover = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const Logout = (e) => {
    AuthService.logout(() => {
      navigate('/login', { replace: true })
    })
  }
  const navigate = useNavigate()

  return (
    <Box>
      <IconButton
        size="large"

        color="inherit"
      
        aria-haspopup="true"
        sx={{
          ...(typeof anchorEl2 === 'object' && {
            color: 'primary.main',
          }),
        }}
        onClick={handleClick2}
      >
        <Avatar
          src="https://cdn.iconscout.com/icon/free/png-512/free-avatar-370-456322.png?f=webp&w=256"
         
          sx={{
            width: 35,
            height: 35,
          }}
        />
      </IconButton>
    
      <Menu
     
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        sx={{
          '& .MuiMenu-paper': {
            width: '220px',
          },
        }}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon width={20} />
          </ListItemIcon>
          <Link to="/savings-account/settings">
          <ListItemText>My Profile</ListItemText>
          </Link>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <SettingsIcon width={20} />
          </ListItemIcon>
          <Link to="/savings-account/settings">
          <ListItemText> Account Settings</ListItemText>
          </Link>
        </MenuItem> */}
      
       
        <Box mt={2} py={2} px={2}>
          <Button onClick={Logout} variant="outlined" color="error" component={Link} fullWidth>
            Logout
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};

export default AccountPopover;
























