import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Grid, List, Stack, Popover, ListItem, ListSubheader, CardActionArea } from '@mui/material';
// components
import Iconify from '../../../shared/components/Iconify';

// ----------------------------------------------------------------------

const LinkStyle = styled(Link)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(5),
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': {
        opacity: 0.48,
        textDecoration: 'none',
    },
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
    ...theme.typography.body2,
    padding: 0,
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create('color'),
    '&:hover': {
        color: theme.palette.text.primary,
    },
}));

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);

    useEffect(() => {
        if (open) {
            handleClose();
        }
        if (open1) {
            handleClose1();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleOpen1 = () => {
        setOpen1(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClose1 = () => {
        setOpen1(false);
    };
    return (
        <Stack direction="row">
            {navConfig.map((link) => (
                <MenuDesktopItem
                    key={link.title}
                    item={link}
                    isOpen={open}
                    isOpen1={open1}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    onOpen1={handleOpen1}
                    onClose1={handleClose1}
                    isOffset={isOffset}
                    isHome={isHome}
                />
            ))}
        </Stack>
    );
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
    type: PropTypes.oneOf(['item', 'subheader']),
};

function IconBullet({ type = 'item' }) {
    return (
        <Box sx={{ width: 24, height: 24, display: 'flex', alignItems: 'center' }}>
            <Box
                component="span"
                sx={{
                    ml: '2px',
                    width: 4,
                    height: 4,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                    ...(type !== 'item' && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
                }}
            />
        </Box>
    );
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    isOpen1: PropTypes.bool,
    onClose1: PropTypes.func,
    onOpen1: PropTypes.func,
    item: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        children: PropTypes.array,
        children1: PropTypes.array,
    }),
};

function MenuDesktopItem({ item, isHome, isOpen, isOpen1, isOffset, onOpen, onClose, onOpen1, onClose1 }) {
    const { title, path, children, children1 } = item;
   
    if (title === 'Use Cases') {
        return (
            <>
                <LinkStyle
                    onClick={onOpen}
                    sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        ...(isHome && { color: 'cornflowerblue' }),
                        ...(isOffset && { color: 'cornflowerblue' }),
                        ...(isOpen && { opacity: 0.48 }),
                    }}
                >
                    {title}
                    <Iconify
                        icon={isOpen ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpen}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 80, left: 0 }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={onClose}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 4,
                            m: 'auto',
                            borderRadius: 2,
                            maxWidth: (theme) => theme.breakpoints.values.sm,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children?.map((list) => {
                            const { subheader, items } = list;

                            return (
                                <Grid key={subheader} item xs={12} md={subheader === 'Dashboard' ? 12 : 12}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                lineHeight: 'unset',
                                                alignItems: 'center',
                                                color: 'text.primary',
                                                typography: 'overline',
                                            }}
                                        >
                                            <IconBullet type="subheader" /> {subheader}
                                        </ListSubheader>

                                        {items?.map((item) => (
                                            <ListItemStyle
                                                key={item?.title}
                                                to={item?.path}
                                                component={RouterLink}
                                                underline="none"
                                                sx={{
                                                    '&.active': {
                                                        color: 'text.primary',
                                                        typography: 'subtitle2',
                                                    },
                                                }}
                                            >
                                                {item?.title === 'Dashboard' ? (
                                                    <CardActionArea
                                                        sx={{
                                                            py: 5,
                                                            px: 10,
                                                            borderRadius: 2,
                                                            color: 'primary.main',
                                                            bgcolor: 'background.neutral',
                                                        }}
                                                    >
                                                        <Box
                                                            component={m.img}
                                                            whileTap="tap"
                                                            whileHover="hover"
                                                            variants={{
                                                                hover: { scale: 1.02 },
                                                                tap: { scale: 0.98 },
                                                            }}
                                                            src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png"
                                                        />
                                                    </CardActionArea>
                                                ) : (
                                                    <>
                                                        <IconBullet />
                                                        {item?.title}
                                                    </>
                                                )}
                                            </ListItemStyle>
                                        ))}
                                    </List>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Popover>
            </>
        );
    }

    if (title === 'Solutions') {
        return (
            <>
                <LinkStyle
                    onClick={onOpen1}
                    sx={{
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        ...(isHome && { color: 'cornflowerblue' }),
                        ...(isOffset && { color: 'cornflowerblue' }),
                        ...(isOpen1 && { opacity: 0.48 }),
                    }}
                >
                    {title}
                    <Iconify
                        icon={isOpen1 ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpen1}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 80, left: 0 }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    onClose={onClose1}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 4,
                            m: 'auto',
                            borderRadius: 2,
                            maxWidth: (theme) => theme.breakpoints.values.sm,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children1?.map((list) => {
                            const { subheader, items } = list;

                            return (
                                <Grid key={subheader} item xs={12} md={subheader === 'Dashboard' ? 6 : 6}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: 'flex',
                                                lineHeight: 'unset',
                                                alignItems: 'center',
                                                color: 'text.primary',
                                                typography: 'overline',
                                            }}
                                        >
                                            <IconBullet type="subheader" /> {subheader}
                                        </ListSubheader>
                                        {items?.map((item) => (
                                            <ListItemStyle
                                                key={item.title}
                                                to={item.path}
                                                component={RouterLink}
                                                underline="none"
                                                sx={{
                                                    '&.active': {
                                                        color: 'text.primary',
                                                        typography: 'subtitle2',
                                                    },
                                                }}
                                            >
                                                {item?.title === 'Dashboard' ? (
                                                    <CardActionArea
                                                        sx={{
                                                            py: 5,
                                                            px: 10,
                                                            borderRadius: 2,
                                                            color: 'primary.main',
                                                            bgcolor: 'background.neutral',
                                                        }}
                                                    >
                                                        <Box
                                                            component={m.img}
                                                            whileTap="tap"
                                                            whileHover="hover"
                                                            variants={{
                                                                hover: { scale: 1.02 },
                                                                tap: { scale: 0.98 },
                                                            }}
                                                            src="https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png"
                                                        />
                                                    </CardActionArea>
                                                ) : (
                                                    <>
                                                        <IconBullet />
                                                        {item?.title}
                                                    </>
                                                )}
                                            </ListItemStyle>
                                        ))}
                                    </List>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Popover>
            </>
        );
    }

    return (
        <LinkStyle
            to={path}
            component={RouterLink}
            end={path === '/'}
            sx={{
                ...(isHome && { color: 'text.primary' }),
                ...(isOffset && { color: 'text.primary' }),
                '&.active': {
                    color: 'primary.main',
                },
            }}
        >
            {title}

        </LinkStyle>
    );
}
