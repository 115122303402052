import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";


import LoadingScreen from "src/shared/components/LoadingScreen";
import AuthGuard from "src/shared/guards/AuthGuard";
import ShopLayout from "src/shared/layouts/shopLayout";


const Loadable = (Component) => (props) => {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    )
}



//const Commission = Loadable(lazy(() => import('../view/Commission')));
const DealerTransactions = Loadable(lazy(() => import('../components/DealerTransactions')));
const ReferredAccounts = Loadable(lazy(() => import('../view/ReferredAccounts')));


export const CommissionRouter = [
    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
                <ShopLayout />
            </AuthGuard>
        ),
        children: [
          //  { path: 'savings-account/commission', element: <Commission /> },
            { path: 'savings-account/referred-accounts', element: <ReferredAccounts /> },
            { path: 'savings-account/dealer-transactions/:dealers_id', element: <DealerTransactions /> }

        ]
    },

]

 