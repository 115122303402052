import * as T from 'prop-types'
import { Container } from '@mui/material';
import HeaderBreadcrumbs from './../components/HeaderBreadcrumbs';


ContainerWrapper.propTypes = {
    links: T.array,
    heading: T.string,
}


export default function ContainerWrapper({ heading, links, children }) {
    return (
        <Container maxWidth={false}>
            <HeaderBreadcrumbs heading={heading} links={links} />
            {children}
        </Container>);
}
