import {
    GET_BULKPAYMENT_LIST,
    GET_BULKPAYMENT_LIST_FAILED,
    GET_BULKPAYMENT_LIST_SUCCESS,
    GET_SINGLE_BULKPAYMENT_LIST,
    GET_SINGLE_BULKPAYMENT_LIST_FAILED,
    GET_SINGLE_BULKPAYMENT_LIST_SUCCESS,
    GET_CHECKERS_LIST,
    GET_CHECKERS_LIST_SUCCESS,
    GET_CHECKERS_LIST_FAILED,
    CLOSE_SINGLE_BULK_MODAL,
} from './BulkpaymentActionTypes';

const initialState = {
    loading: false,
    bulkPaymentList: [],
    singleBulkPaymentList: [],
    message: '',
    openSingle: false,
    error: {},
    reason: "",
    response: {},
    bulkpaymentCheckers: [],
    data: {},
};

export default function BulkPaymentReducer(state = initialState, action) {
    const { type, loading, reason, bulkPaymentList, openSingle, singleBulkPaymentList, bulkpaymentCheckers, data, message, error, response } = action;
    switch (type) {
        case GET_BULKPAYMENT_LIST:
            return {
                ...state,
                loading,
            };
        case GET_BULKPAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading,
                response,
                bulkPaymentList,
                message,
                error,
                data,
            };
        case GET_BULKPAYMENT_LIST_FAILED:
            return {
                ...state,
                loading,
                error,
            };
        case GET_SINGLE_BULKPAYMENT_LIST:
            return {
                ...state,
                loading,
            };
        case GET_SINGLE_BULKPAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading,
                response,
                singleBulkPaymentList,
                openSingle,
                message,
                reason,
                error,
                data,
            };
        case CLOSE_SINGLE_BULK_MODAL:
            return {
                ...state,
                singleBulkPaymentList,
                openSingle,

            };
        case GET_SINGLE_BULKPAYMENT_LIST_FAILED:
            return {
                ...state,
                loading,
                error,
            };
        case GET_CHECKERS_LIST:
            return {
                ...state,
                loading,
            };
        case GET_CHECKERS_LIST_SUCCESS:
            return {
                ...state,
                loading,
                response,
                bulkpaymentCheckers,
                message,
                error,
                data,
            };
        case GET_CHECKERS_LIST_FAILED:
            return {
                ...state,
                loading,
                error,
            };
        default:
            return state;
    }
}
