import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const JaziaLinks = [
    {

        title: 'Jazia Provider',
        path: '/provider-dashboard/statistics/',
        icon: getIcon("healthicons:provider-fst-negative"),
    },


]


