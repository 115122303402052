import React from 'react'
import { Dialog, DialogTitle, IconButton, Skeleton, TablePagination } from '@mui/material'
import { styled } from '@mui/material/styles';
import Iconify from 'src/shared/components/Iconify'
import PropTypes from 'prop-types'

export const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export function CustomDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Iconify
            icon="eva:close-fill"
            sx={{ color: 'text.disabled', width: 20, height: 20 }}
          />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

Paginator.propTypes = {
  loading: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}

export function Paginator({ loading, count, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) {
  return (
    <>
      {loading && <>
        <Skeleton animation='pulse' variant="rectangular" height={10} />
        <Skeleton animation='pulse' variant="rectangular" height={10} />
        <Skeleton animation='pulse' variant="rectangular" height={10} />
        <Skeleton animation='pulse' variant="rectangular" height={10} />
        <Skeleton animation='pulse' variant="rectangular" height={10} />
        <Skeleton animation='pulse' variant="rectangular" height={10} />
      </>}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}