export const GET_SHOP_TRANSACTIONS = "SHOP/GET_SHOP_TRANSACTIONS";
export const GET_SHOP_TRANSACTIONS_API_SUCCESS = "SHOP/GET_SHOP_TRANSACTIONS_API_SUCCESS";
export const GET_SHOP_TRANSACTIONS_API_FAILED = "SHOP/GET_SHOP_TRANSACTIONS_API_FAILED";
export const DOWNLOAD_SHOP_TRANSACTIONS = "SHOP/DOWNLOAD_SHOP_TRANSACTIONS";
export const DOWNLOAD_SHOP_TRANSACTIONS_API_SUCCESS = "SHOP/DOWNLOAD_SHOP_TRANSACTIONS_API_SUCCESS";
export const DOWNLOAD_SHOP_TRANSACTIONS_API_FAILED = "SHOP/DOWNLOAD_SHOP_TRANSACTIONS_API_FAILED";
export const DOWNLOAD_SHOP_TRANSACTIONS_CLEAR = "SHOP/DOWNLOAD_SHOP_TRANSACTIONS_CLEAR";
export const PUSH_TRANSACTIONS = "SHOP/PUSH_TRANSACTIONS";
//control account

export const API_REQUEST = "SHOP/TRANSACTION_CONTROL_ACCOUNT_REQUEST"
export const API_SUCCESS = "SHOP/TRANSACTION_CONTROL_ACCOUNT_SUCCESS"
export const API_FAILED = "SHOP/TRANSACTION_CONTROL_ACCOUNT_FAILED"