
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

import sasapaylogo from '../assets/images/sasapay.jpg'
import logolight from '../assets/images/sasapay.jpg'
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, isLight = false, isOperator = false, isMain = false, sx }) {


  const logo = (
    <Box component="img" alt="sasapay" src={sasapaylogo} sx={{ width: 200, height: 50, ml: 1, mb: 0, ...sx }} />
  );

  const lightLogo = (
    <Box component="img" alt="sasapay" src={logolight} sx={{ width: 200, height: 50, ml: 1, mb: 0, ...sx }} />
  );

  if (disabledLink) {
    if (isLight) {
      return <>{lightLogo}</>
    } else {
      return <>{logo}</>;
    }
  }
  if (isMain) {
    return <RouterLink to="/">{logo}</RouterLink>;
  }

  if (isOperator) {
    return <RouterLink to="/shop-dashboard/savings-account">{logo}</RouterLink>;
  }
  return <RouterLink to="/dashboard">{logo}</RouterLink>;
}
