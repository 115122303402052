import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import DashboardLayout from "src/layouts/dashboard/DashboardLayout";
import providerConfiguration from "src/layouts/dashboard/nav/forexConfiguration";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import ForexDeposit from "../views/deposit";
import Dollar from "src/packages/shop/view/Dollar";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};



export const ForexRoutes = [
    {
        path: 'forex-dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout navConfig={providerConfiguration} />
            </AuthGuard>
        ),
        children: [
            { element: <Navigate to="/forex-dashboard/deposit" />, index: true },
            { path: 'app', element: <ForexDeposit /> },
            { path: 'deposit', element: <Dollar /> },
            // { path: 'transfer', element: <ForexTransfer /> },
        ],
    },
];
