import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const JaziaCoLinks = [
    {

        title: 'Jazia',
        path: '/shop-dashboard/savings-account/jazia-consumer',
        icon: getIcon("bi:wallet-fill"),
    },


]


