import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import DashboardLayout from "../../../shared/layouts/dashboard";
import ShopLayout from "../../../shared/layouts/shopLayout";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/shop-dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const ScheduleSettlement = Loadable(lazy(() => import('../view/ScheduleSettlement')));
const ScheduleSettlementCard = Loadable(lazy(() => import('../components/AutoSettlementCard')));

export const ScheduleSettlementRoutes = [
    // Dashboard Routes
    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
            <ShopLayout/>
            </AuthGuard>
        ),
        children: [
            { path: 'auto-settlement', element: <ScheduleSettlement/> },
            { path: 'auto-settlement-card', element: <ScheduleSettlementCard/> },

        ],
    },
];
