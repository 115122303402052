
import {
    Box,
    Stack,
    Checkbox
} from "@mui/material";
import * as PropTypes from 'prop-types'
import * as React from "react";
import Iconify from "src/shared/components/Iconify";
import { LoadingButton } from "@mui/lab";

IconColor.propTypes = {
    sx: PropTypes.object,
};

function IconColor({ sx, icon, ...other }) {
    return (
        <Box
            sx={{
                width: 20,
                height: 20,
                display: 'flex',
                borderRadius: '50%',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'currentColor',
                transition: (theme) =>
                    theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest,
                    }),
                ...sx,
            }}
            {...other}
        >
            <Iconify icon={icon} />
        </Box>
    );
}



export default function CheckboxComponent({ loading, handleChange, checked, negateText, acceptText }) {
    
    return (
        <Stack direction='row' spacing='2'>
            <Checkbox
                key='checkbox'
                size="small"
                checked={checked}
                onChange={handleChange}
                icon={
                    <IconColor
                        icon='ph:x-light'
                        sx={{
                            transform: 'scale(0.9)',
                            '&:before': {
                                opacity: 0.48,
                                width: '100%',
                                content: "''",
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                boxShadow: '4px 4px 8px 0 currentColor',
                            },
                            '& svg': { width: 12, height: 12, color: 'common.white' },
                            color: (theme) => theme.palette.error.dark,
                        }}
                    />
                }
                checkedIcon={
                    <IconColor
                        icon='eva:checkmark-fill'
                        sx={{
                            transform: 'scale(1.4)',
                            '&:before': {
                                opacity: 0.48,
                                width: '100%',
                                content: "''",
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                boxShadow: '4px 4px 8px 0 currentColor',
                            },
                            '& svg': { width: 12, height: 12, color: 'common.white' },
                            color: (theme) => theme.palette.primary.success,
                        }}
                    />
                }
                sx={{
                    color: (theme) => theme.palette.error.dark,
                    '&:hover': { opacity: 0.72 },
                }}
            />
            <LoadingButton loading={loading} onClick={handleChange} color={checked ? 'info' : 'error'} variant='text' size='small'>
                {checked ? negateText : acceptText}
            </LoadingButton>
        </Stack>
    )
}