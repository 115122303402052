import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import {
  TableContainer, TableHead, Table, TableBody, TableRow, TableCell, Paper, Grid
} from '@mui/material';
import AuthService from 'src/core/access-control/AuthService';
import call from 'src/core/services/http';
import { useNavigate } from "react-router-dom";
import { CustomRow } from 'src/shared/TableComponents/TableStyles';
import ListHead from 'src/shared/TableComponents/ListHead';
import { toast } from 'react-toastify';
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";

const TABLE_HEAD = [
  { id: 'account_number', label: "Account Number", alignLeft: true },
  { id: 'total_amount', label: "Amount", alignLeft: true },
  { id: 'description', label: " Description", alignLeft: true },
  { id: 'action', label: "Status", alignLeft: true },
];

const History = () => {
  const [shop] = useLocalStorage('shop-details');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Object.keys(data).map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected({});
  };

  const getHistory = async () => {
    try {
      const response = await call('get', `/escrow/?merchant_code=${shop.account_number}`);
      if (response.data.status) {
        setData(response.data);
        if (response.data.escrow_entries_history.length === 0) {
          setIsNotFound(true);
        }
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Card sx={{ overflow: "auto" }}>
      <CardContent>
        <Typography sx={{ fontSize: 26 }} color="text.error" gutterBottom>
          Escrow History
        </Typography>
      </CardContent>

      {isLoading ? (
        <Grid container sx={{ justifyContent: 'center' }}> <SpinnerLoader /></Grid>
      ) : (
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              numSelected={Object.keys(selected).length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            {isNotFound ? (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>
                      <Typography variant="body2">
                        No results found for <strong>{shop.account_number}</strong>
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {data.escrow_entries_history.map(({ id, escrow, escrow: { sending_escrow_acc,status, total_amount, description } }, index) => {
                  return (
                    <CustomRow hover key={index}>
                      <TableCell >{sending_escrow_acc.account_number}</TableCell>
                      <TableCell >{total_amount}</TableCell>
                      <TableCell >{description}</TableCell>
                      <TableCell  color='primary'>{status}</TableCell>
                      
                    </CustomRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      )}
    </Card>
  );
}

export default History;
