import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const MyBankLinks=[
    {
        title: 'My Banks',
        path: '/dashboard/my-banks',
        icon: getIcon('ri:bank-fill')
        ,
    },
]
