import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const AccountGroupingLinks=[
    {
        title: 'Account Groupings',
        path: '/dashboard/account-groupings',
        icon: getIcon('material-symbols:group-work-outline'),
    },
]
