import {lazy, Suspense} from "react";
import {Navigate, useLocation} from "react-router-dom";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import DashboardLayout from "../../../shared/layouts/dashboard";
import {PATH_AFTER_LOGIN} from "../../../config";
import LogoOnlyLayout from "../../../shared/layouts/LogoOnlyLayout";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const GeneralApp = Loadable(lazy(() => import('../view/DashboardApp')));
const NotFound = Loadable(lazy(() => import('../../../shared/pages/Page404')));

export const DashboardRoutes =[
    // Dashboard Routes
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'app', element: <GeneralApp /> },

        ],
    },
    {
        path: '/dashboard',
        element: <LogoOnlyLayout />,
        children: [
            { path: '/dashboard', element: <Navigate to="/dashboard/app" /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> },
        ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
];
