import { SEARCH_USER_API_FAILED, SEARCH_USER_API_REQUEST, SEARCH_USER_API_SUCCESS } from "./UserActionTypes";
import UserConstants from "./UserConstants";
import call from "../../../core/services/http";
import { toast } from "react-toastify";
//
const notifySuccess = msg => {
    toast.success(msg)
}
const notifyError = msg => {
    toast.error(msg)
}
export const searchUser = payload => async (dispatch) => {
    try {
        dispatch({
            type: SEARCH_USER_API_REQUEST,
            searching: true,
        });
        const res = await call("post", UserConstants.SEARCH_USER, payload);
        if (res.data.status) {
            dispatch({
                type: SEARCH_USER_API_SUCCESS,
                payload: res.data.results.clients.shift(),
                searching: false,
                idDelegate: true,
                queryMessage: 'User found',
                resultFound: true
            });
        } else {
            dispatch({
                type: SEARCH_USER_API_FAILED,
                payload: res.data,
                searching: false,
                queryMessage: `Sorry, we did not any find delegate with phone number ${payload.mobile_number}`
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: SEARCH_USER_API_FAILED,
            error: err.response.data,
            searching: false,
            queryMessage: `Sorry, we did not find any delegate with phone number ${payload.mobile_number}`
        });
        notifyError(err.message)
    }
}