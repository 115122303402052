import { lazy, Suspense } from "react";
import { useLocation, Navigate } from "react-router-dom";

import LoadingScreen from "../../../shared/components/LoadingScreen";
import MainLayout from "../../../shared/layouts/main/MainLayout";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const Home = Loadable(lazy(() => import('../view/Home')));
const B2C = Loadable(lazy(() => import('../../../shared/pages/B2C')))
const B2B = Loadable(lazy(() => import('../../../shared/pages/B2B')))
const C2B = Loadable(lazy(() => import('../../../shared/pages/C2B')))
const P2P = Loadable(lazy(() => import('../../../shared/pages/P2P')))
const Invoicing = Loadable(lazy(() => import('../../../shared/pages/Invoicing')))
const LoyaltyProgram = Loadable(lazy(() => import('../../../shared/pages/LoyaltyProgram')))
const API = Loadable(lazy(() => import('../../../shared/pages/API')))
const Escrow = Loadable(lazy(() => import('../../../shared/pages/Escrow')))
const CustomerDirectory = Loadable(lazy(() => import('../../../shared/pages/CustomerDirectory')))
const MarketPlace = Loadable(lazy(() => import('../../../shared/pages/MarketPlace')))

const Bulkpayment = Loadable(lazy(() => import('../../../shared/pages/Bulkpayment')))
const Linkpayment = Loadable(lazy(() => import('../../../shared/pages/Linkpayment')))
const SMSPayment = Loadable(lazy(() => import('../../../shared/pages/SMSPayment')))
const Checkout = Loadable(lazy(() => import('../../../shared/pages/Checkout')))
const UtilityPayment = Loadable(lazy(() => import('../../../shared/pages/UtilityPayment')))
const BillPresentment = Loadable(lazy(() => import('../../../shared/pages/BillPresentment')))
const MandatePayment = Loadable(lazy(() => import('../../../shared/pages/MandatePayment')))

const LogoOnlyLayout = Loadable(lazy(() => import('../../../shared/layouts/LogoOnlyLayout')))
const Page500 = Loadable(lazy(() => import('../../../shared/pages/Page500')));
const NotFound = Loadable(lazy(() => import('../../../shared/pages/Page404')))

export const LandingRoutes = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { element: <Home />, index: true },
            { path: 'peer-to-peer', element: <P2P /> },
            { path: 'customer-to-business', element: <C2B /> },
            { path: 'business-to-customer', element: <B2C /> },
            { path: 'business-to-business', element: <B2B /> },

            { path: 'invoicing', element: <Invoicing /> },
            { path: 'loyalty', element: <LoyaltyProgram /> },
            { path: 'api', element: <API /> },
            { path: 'escrow', element: <Escrow /> },
            { path: 'customer-directory', element: <CustomerDirectory /> },
            { path: 'market-place', element: <MarketPlace /> },
            { path: 'bulk-payment', element: <Bulkpayment /> },
            { path: 'link-payment', element: <Linkpayment /> },
            { path: 'sms-payment', element: <SMSPayment /> },
            { path: 'checkout', element: <Checkout /> },
            { path: 'utility-payment', element: <UtilityPayment /> },
            { path: 'bill-presentment', element: <BillPresentment /> },
            { path: 'mandate-payment', element: <MandatePayment /> },
        ]

    },
    // Main Routes
    {
        path: '*',
        element: <LogoOnlyLayout />,
        children: [

            { path: '500', element: <Page500 /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> },
        ],
    },
]

