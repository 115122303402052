import { BILLNUMBER_API_FAILED, BILLNUMBER_API_REQUEST, BILLNUMBER_API_SUCCESS } from "./BillNumberActionTypes";

const initialState = {
    loading: false,
    message: "",
    billNumber: "",
    error: {},
}
export default function BillNumberReducer(state = initialState, action) {

    const { type, payload, message, loading, error } = action;
    switch (type) {
        case BILLNUMBER_API_REQUEST:
            return {
                loading,
            }
        case BILLNUMBER_API_SUCCESS:
            return {
                loading,
                billNumber: payload.bill_number,
                message
            }
        case BILLNUMBER_API_FAILED:
            return {
                loading,
                message,
                error
            }
        default:
            return state
    }
}