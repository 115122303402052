import call from "../../../core/services/http";
import { toast } from "react-toastify";
import { USERS_API_FAILED, USERS_API_REQUEST, USERS_API_SUCCESS, CUSTOMERS_API_FAILED, CUSTOMERS_API_REQUEST, CUSTOMERS_API_SUCCESS } from "./SharedUsersActionTypes";
import SharedUsersConstants from "./SharedUsersConstants";
//
const notifyError = msg => {
    toast.error(msg)
}
/**
 * GET Operators
 * @returns {(function(*): Promise<void>)|*}
 */
export const getOperators = payload => async (dispatch) => {
    try {
        dispatch({
            type: USERS_API_REQUEST,
            loading: true,
        });
        const res = await call("get", SharedUsersConstants.OPERATORS(payload));
        if (res.data.status) {
            dispatch({
                type: USERS_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: USERS_API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: USERS_API_FAILED,
            error: err.response.data,
            loading: false
        });
        notifyError(err.message)
    }
}

/**
 * GET customers
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCustomers = payload => async (dispatch) => {
    try {
        dispatch({
            type: CUSTOMERS_API_REQUEST,
            loading: true,
        });
        const res = await call("get", SharedUsersConstants.CUSTOMERS(payload));
        if (res.data.status) {
            dispatch({
                type: CUSTOMERS_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: CUSTOMERS_API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: CUSTOMERS_API_FAILED,
            error: err.response.data,
            loading: false
        });
        notifyError(err.message)
    }
}


/**
 * GET industries
 * @returns {(function(*): Promise<void>)|*}
 */
export const saveOperators = payload => async (dispatch) => {
    try {
        dispatch({
            type: USERS_API_REQUEST,
            loading: true,
        });
        const res = await call("post", SharedUsersConstants.OPERATORS(payload), payload);
        if (res.data.status) {
            dispatch({
                type: USERS_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
            });
            // dispatch(getOperators())
        } else {
            dispatch({
                type: USERS_API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: USERS_API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}