// component

import { ShopLinks } from "src/packages/shop/routes/ShopLinks";
// import { BillPresentMentLinks } from "../../../../packages/bill-presentment/routes/links";
import { CommissionLinks } from '../../../../packages/dealer/routes/CommissionLink';
import { JaziaSponsorLinks, JaziaSponsornProvider, JaziaCoLinks, JaziaLinks } from '../../../../packages/jazia/routes/';

const subNavConfig = [...ShopLinks]

export const paybillLinks = [...ShopLinks, 
    // ...BillPresentMentLinks
]

export const dealerLinks = [...ShopLinks, ...CommissionLinks]

export const jaziaProviderLinks = [...ShopLinks, 
    // ...BillPresentMentLinks,
     ...JaziaLinks]

export const jaziaCoTills = [...ShopLinks, ...JaziaCoLinks]

export const jaziCoLinks = [...ShopLinks, 
    // ...BillPresentMentLinks, 
    ...JaziaCoLinks]

export const jaziaSponsors = [...ShopLinks, 
    // ...BillPresentMentLinks,
     ...JaziaSponsorLinks]

export const jaziaSponsornProvider = [...ShopLinks,
    //  ...BillPresentMentLinks,
      ...JaziaSponsornProvider]
export default subNavConfig;
