import { Typography } from '@mui/material';
import T from 'prop-types'

Title.protoTypes = {
    text: T.string,
    upperCase: T.bool,
    variant:T.string
}

Title.defaultProps = {
    upperCase: true,
    text:'',
    variant:'button'
}


export default function Title({ text, upperCase,variant, sx }) {
    return (
        <Typography variant={variant} sx={{
            mt: 2,
            color: (theme) => theme.palette.primary.light,
            ...sx
        }}>
            {upperCase ? text.toUpperCase() : text}
        </Typography>);
};