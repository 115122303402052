import Iconify from "src/shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Go Back',
    path: '/shop-dashboard/savings-account/',
    icon: getIcon('pajamas:go-back'),
  },
  {
    title: 'Provider Statistics',
    path: '/provider-dashboard/statistics/',
    icon: getIcon('akar-icons:statistic-up'),
  },
  {
    title: 'Manage Subscribers',
    path: '/provider-dashboard/subscribers/',
    icon: getIcon('majesticons:users'),
  },

  {
    title: 'Manage Loans',
    path: '/provider-dashboard/manage-loans/',
    icon: getIcon('arcticons:debtcalc'),
  },
  {
    title: 'Jazia Products',
    path: '/provider-dashboard/configurations/',
    icon: getIcon('icon-park:setting-config'),
  },
]

export default navConfig;
