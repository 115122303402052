import { Button, InputAdornment, RadioGroup, TextField, Radio, Grid, Stack, Typography, FormControlLabel } from '@mui/material'
import { Box } from '@mui/system';
import MuiPhoneNumber from 'material-ui-phone-number'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, RHFTextField, RHFSelect } from 'src/shared/components/hook-form'
import * as Yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { ToastContainer, toast } from "react-toastify";
import UserConstants from 'src/packages/users/store/UserConstants'
import { searchUser } from 'src/packages/users/store/UserActions';
import call from 'src/core/services/http'
import useLocalStorage from 'src/shared/hooks/useLocalStorage'
import { LoadingButton } from '@mui/lab'
import Iconify from 'src/shared/components/Iconify'
import TransactionConstants from '../../store/TransactionConstants';
import { fCurrency } from 'src/shared/utils/formatNumber';
import { stubTrue } from 'lodash';

const InvoiceSchema = Yup.object().shape(
    {
        mobile_number: Yup.string().required("Please enter the phone number"),
        amount: Yup.string().required("Please enter the amount"),
        reason: Yup.string(),
        channel_code: Yup.string()
    }
)

const B2C = () => {
    const [paymentOption, setPaymentOption] = useState("0")
    const [paymentOpton1, setPaymentOption1] = useState("0")
    const [shop] = useLocalStorage('shop-details')
    const dispatch = useDispatch()
    const { countryCodes, countries } = useSelector(state => state.countries);
    const { searching, searchResult, queryMessage, resultFound } = useSelector(state => state.user)
    const [phone_number, setPhoneNumber] = useState("")
    const [searched, setSearched] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState([])
    const [completedMessage, setCompletedMessage] = useState("")

    //FORMIK
    const methods = useForm({
        resolver: yupResolver(InvoiceSchema),
        defaultValues: { mobile_number: "", amount: "", reason: "" },
        mode: 'onChange'
    });
    //
    const {
        handleSubmit, getValues, reset, watch, setValue,
        formState: { errors, isSubmitting },
    } = methods;

    const [errorMessage, setErrorMessage] = useState("");
    const [inputErr, setInputErr] = useState("");
    const [aliasNumber, setAliasNumber] = useState("");
    const [searchingClient, setSearchingClient] = useState(false)
    const [showNext, setShowNext] = useState(false)
    const [theFoundClient, setTheFoundClient] = useState("")

    const nextSearchUSer = async () => {
        //console.log("payment Option", paymentOption)
        if (paymentOption === "0") {

            if (phone_number.length === 13) {
                setSearchingClient(true)
                const res = await call("post", UserConstants.SEARCH_USER, { mobile_number: phone_number.slice(1), country_code: phone_number.slice(1, 4) });
                setSearchingClient(false)

                if (res.data.status) {
                    //console.log(res);
                    setTheFoundClient(res.data.results.clients[0])
                    setValue("mobile_number", res.data.results.clients[0].mobile_number)
                    setShowNext(true)
                } else {
                    toast.error(res.data.message)
                    //console.log(res);
                }
            } else {
                setInputErr("Enter a valid Mobile Number")
            }
        } else if (paymentOption === "1") {
            if (aliasNumber.length > 1) {
                setSearchingClient(true)
                const res = await call("post", UserConstants.SEARCH_ALIAS, { alias_number: aliasNumber });
                setSearchingClient(false)
                if (res.data.status) {
                    //console.log(res);
                    setValue("mobile_number", res.data.results.clients.mobile_number)
                    setTheFoundClient(res.data.results.clients)
                    setShowNext(true)
                } else {
                    toast.error(res.data.message)
                    //console.log(res);
                }
            } else {
                setInputErr("Alias Number is required")
            }
        }
    }

    const [confirmedLoading, setConfirmedLoading] = useState(false)
    const [transactionDetails, setTransactionDetails] = useState({
        sender_account_number: "",
        recipient_account_number: "",
        amount: "",
        reason: ""
    })

    // send data to the server
    const onSubmit = async (name) => {
        const { amount, mobile_number, reason } = getValues()

        const payload = {
            sender_account_number: shop.account_number,
            recipient_account_number: parseInt(mobile_number),
            amount: parseInt(amount),
            reason,
        }

        if (name === "confirm") {
            setConfirmedLoading(true)
            const res = await call("post", TransactionConstants.SENDMONEYB2C, payload)

            if (res.data.status) {
                setCompletedMessage(res.data.message)
                toast.success(res.data.message)
                setTransactionDetails(res.data.transaction)
                setShowForm(false)
                setShowConfirm(false)
                setShowCompleted(true)
                setConfirmedLoading(false)
            } 
            else{

                toast.error(res.data.message)
                    }
          

        } else {
            const res = await call("post", `${TransactionConstants.SENDMONEYB2C}confirm/`, payload)
            if (res.data.status) {
                setConfirmMessage(res.data.message.split("."))
                setShowForm(false)
                setShowConfirm(true)

            }
            else{
                toast.error(res.data.message)
            }
        }
    };

    // track stage
    const [showForm, setShowForm] = useState(true)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showCompleted, setShowCompleted] = useState(false);
    const onDoneClicked = () => {
        setShowCompleted(false);
        setShowForm(true);
        setShowNext(false)
        reset()
    }



    return (
        <>
            {showForm && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
                <Grid container>
                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: "block" } }} ></Grid>
                    <Grid item sm={4} >
                        <Stack direction='column' spacing={2} my={2}>
                            {!showNext && <>

                                <Stack direction="row" spacing={3}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="payment_option"
                                        name="payment_option"
                                        onChange={(e) => {
                                            setPaymentOption(e.target.value)
                                            setInputErr("")
                                        }}
                                        defaultValue='0'
                                    >
                                        <Stack direction={'row'}>
                                            {
                                                ["To Mobile Number", "By Alias Number"].map((value, index) => <FormControlLabel key={index} value={index.toString()} control={<Radio />} label={value} />)
                                            }
                                        </Stack>
                                    </RadioGroup>
                                </Stack>

                                {paymentOption === '0' && <MuiPhoneNumber
                                    error={inputErr}
                                    fullWidth={true}
                                    regions="africa"
                                    defaultCountry={'tz'}
                                    variant="outlined"
                                    size='medium'
                                    helperText={inputErr}
                                    onlyCountries={countryCodes}
                                    onChange={phone => { setPhoneNumber(phone); setValue("mobile_number", phone.slice(1)); setInputErr("") }} />}
                                {paymentOption === '1' && <RHFTextField
                                    size={'medium'}
                                    name="alias"
                                    label='Alias Number'
                                    value={aliasNumber}
                                    onChange={e => { setAliasNumber(e.target.value); setInputErr(""); }}
                                    error={inputErr}
                                    helperText={inputErr}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start"><Iconify icon="bxs:coupon" color="gray" /></InputAdornment>
                                        ),
                                    }}
                                />}

                                <LoadingButton variant="contained" size='medium' loading={searchingClient} onClick={nextSearchUSer} >Next</LoadingButton>
                            </>
                            }

                            {showNext && <>

                                <TextField
                                    size={'medium'}
                                    label="Name"
                                    value={theFoundClient?.display_name}
                                    disabled
                                />
                                <TextField
                                    size={'medium'}
                                    label="Mobile Number"
                                    value={theFoundClient?.mobile_number}
                                    disabled
                                />


                                <RHFTextField
                                    name="amount"
                                    size={'medium'}
                                    label='Amount'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">Tsh</InputAdornment>
                                        ),
                                    }}
                                />
                                <RHFTextField
                                    size={'medium'}
                                    name="reason"
                                    label='Reason(Optional)'
                                />

                                <Button type="submit" variant="contained" loading={isSubmitting} size='medium' >Submit</Button>
                            </>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </FormProvider>
            }

            {showConfirm && <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                <Stack direction='column' spacing={2} my={2}>
                    {confirmMessage.map(chunck => <Typography key={chunck}>
                        {chunck}
                    </Typography>
                    )}
                    <LoadingButton name='confirm' onClick={(e) => onSubmit(e.target.name)} variant="contained" loading={confirmedLoading} size='medium' >Confirm</LoadingButton>
                </Stack>
            </Box>
            }

            {showCompleted &&
                <Grid container>
                    <Grid item sm={4} sx={{ display: { xs: 'none', sm: "block" } }} ></Grid>
                    <Grid item sm={4} >
                        <Stack direction='column' spacing={2} my={2}>
                            <Typography >
                                {completedMessage}
                            </Typography>
                            <TextField
                                size={'medium'}
                                label="Recipient Account"
                                value={transactionDetails.recipient_account_number}
                                disabled
                            />
                            <TextField
                                size={'medium'}
                                label="Amount"
                                value={fCurrency(transactionDetails.amount)}
                                disabled
                            />
                            <TextField
                                size={'medium'}
                                label="Reason"
                                value={transactionDetails.reason === "" ? "No reason provided" : transactionDetails.reason}
                                disabled
                            />

                            <Button onClick={() => { onDoneClicked() }} variant="contained" size='medium' >Done</Button>
                        </Stack>
                    </Grid>
                </Grid>
            }

        </>
    )
}

export default B2C

