// routes
import Router from './routes';
// theme
import ThemeProvider from './shared/theme';
// components
import ScrollToTop from './shared/components/scroll-to-top';
import { StyledChart } from './shared/components/chart';
import { ToastContainer, toast } from 'react-toastify';
import AuthService from './core/access-control/AuthService';

// window.onbeforeunload = function() {
//   // Clear the user's session here
//   sessionStorage.clear(); // This clears the session storage
//   localStorage.clear();
//   AuthService.logout() // This clears the local storage
// };

export default function App() {


  
  return (
    <ThemeProvider>
      <ToastContainer theme="colored" />
      <ScrollToTop />
      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
