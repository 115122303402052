/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const flattenObject = (ob, separator = '_') => {
    // The object which contains the
    // final result
    const result = {};
    // loop through the object "ob"
    for (const i in ob) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObject(ob[i]);
            for (const j in temp) {
                // Store temp in result
                result[i + separator + j] = temp[j];
            }
        }
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};