import { COUNTRIES_API_FAILED, COUNTRIES_API_REQUEST, COUNTRIES_API_SUCCESS } from "./CountriesActionTypes";

const initialState = {
    loadingCountries: false,
    countryMessage: "",
    countries: [],
    countryCodes: [],
    error: {},
}
export default function CountriesReducer(state = initialState, action) {
    //
    const { type, payload, countryMessage, loadingCountries, error } = action;
    switch (type) {
        case COUNTRIES_API_REQUEST:
            return {
                ...state,
                loadingCountries,
            }
        case COUNTRIES_API_SUCCESS:
            return {
                ...state,
                loadingCountries,
                countries: payload?.countries,
                countryCodes: payload?.countries.map(country => (country.country_code).toLowerCase()),
                countryMessage
            }
        case COUNTRIES_API_FAILED:
            return {
                ...state,
                loadingCountries,
                countryMessage,
                error
            }
        default:
            return state
    }
}