
import call from "../../../core/services/http";
import AuthConstants from "./AuthConstants";
import {
    AUTH_API_FAILED,
    AUTH_API_REQUEST,
    AUTH_API_SUCCESS,
    VERIFY_AUTH_API_SUCCESS,
    VERIFY_AUTH_API_FAILED,

    OPERATOR_AUTH_API_FAILED,
    OPERATOR_AUTH_API_SUCCESS,
    AUTH_LOGOUT_REQUEST,

    REGISTER_MERCHANT_API_FAILED,
    REGISTER_MERCHANT_API_REQUEST,
    REGISTER_MERCHANT_API_SUCCESS,

} from "./AuthActionTypes";
import AuthService from "../../../core/access-control/AuthService";
import { toast } from "react-toastify";
import { appName } from "src/core/environment/environment";
import { flattenObject } from "src/shared/utils/flattenObject";
//


const notifyError = msg => {
    toast.error(msg)
}

const notifysuccess = msg => {
    toast.success(msg)
}


export const login = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
        });
        const isOperator = payload.isOperator

        let url = isOperator ? AuthConstants.OPERATOR_LOGIN : AuthConstants.LOGIN
        const res = await call("post", url, payload);
        if (res.data.status) {
            dispatch({
                type: AUTH_API_SUCCESS,
                payload: res.data,
                loading: false,
                isOperator,
                showSignup: false,
                showOTP: true,
            });
            //notify
            notifysuccess(res.data.message)
        } else {
            dispatch({
                type: AUTH_API_FAILED,
                payload: res.data,
                loading: false,
                showSignup: true,
                showOTP: false,
            });
            
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: AUTH_API_FAILED,
            error: err.message,
            loading: false,
            showSignup: true,
            showOTP: false
        });
        notifyError(err.response.data.message)
    }
}

//
export const verifyOTP = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
        });
        const isOperator = payload.isOperator
        let url = isOperator ? AuthConstants.VERIFY_OTP_OPERATORS : AuthConstants.VERIFY_OTP

        const res = await call("post", url, payload);
    
        if (res.data.status) {
            //notify
            notifysuccess(res.data.message)
            //redirect

            const redir = isOperator ? "/shop-dashboard/savings-account" : "/dashboard/my-shops/"

            if (isOperator) {

                dispatch({
                    type: OPERATOR_AUTH_API_SUCCESS,
                    payload: res.data,
                    isOperator: true,
                    loading: false,
                    message: res.data.detail,
                    showOTP: true,
                    showSignup: true,
                });



                const accountDetails = flattenObject(res.data?.account)
                accountDetails.isOperator = true
                AuthService.login(res.data?.auth_details["access_token"], {})

                window.localStorage.setItem(appName + "_user", JSON.stringify(res.data?.merchant));
                window.localStorage.setItem("shop-details", JSON.stringify(accountDetails));
                //notify
            } else {

                //login
                AuthService.login(res.data["access"], {})
                dispatch({
                    type: VERIFY_AUTH_API_SUCCESS,
                    payload: res.data,
                    // loading: false,
                    isOperator,
                    // showSignup: true,
                    // showOTP: false,
                });
            }


            window.location.href = redir
            // notifysuccess(res.data.message)


        } else {
            dispatch({
                type: VERIFY_AUTH_API_FAILED,
                payload: res.data,
                loading: false,
                showOTP: true,
                showSignup: false
            });
            // notifyError(res.data.message)
        }
    } catch (err) {
        console.log("HAS ERROR", err.message);
        dispatch({
            type: VERIFY_AUTH_API_FAILED,
            error: err.message,
            loading: false,
            showOTP: true,
            showSignup: false
        });
        // notifyError(err.response.data.detail)
    }
}

export const operatorLogin = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
            submitting: true,
            isOperator: true,
        });
        const res = await call("post", AuthConstants.OPERATOR_LOGIN, payload);
        if (res.data.status) {
            const accountDetails = flattenObject(res.data?.account)
            accountDetails.isOperator = true
            // dispatch({
            //     type: OPERATOR_AUTH_API_SUCCESS,
            //     payload: res.data,
            //     isOperator: true,
            //     loading: false,
            //     message: res.data.detail,
            //     showOTP: true,
            //     showSignup: false,
            // });

            dispatch({
                type: AUTH_API_SUCCESS,
                payload: res.data,
                loading: false,
                showSignup: false,
                isOperator: true,
                showOTP: true,
            });
            //login
            // AuthService.login(res.data?.auth_details["access_token"], {})
            // window.localStorage.setItem(appName + "_user", JSON.stringify(res.data?.merchant));
            // window.localStorage.setItem("shop-details", JSON.stringify(accountDetails));
            //notify
            notifysuccess(res.data.message)
            //redirect
            // We need this
            // window.location.href = "/shop-dashboard/savings-account"
        } else {

            dispatch({
                type: OPERATOR_AUTH_API_FAILED,
                payload: res.data,
                loading: false,
                showOTP: true,

            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: AUTH_API_FAILED,
            error: err.message,
            loading: false,
            showOTP: false,
            showSignup: true,

        });
        notifyError(err.message)
    }
}


// Logout
export const logOut = payload => (dispatch) => {
    //console.log("Logging out")
    dispatch({
        type: AUTH_LOGOUT_REQUEST,
        payload: {},
        showSignup: true,
        showOTP: false,
    })
    AuthService.logout()
    window.location.href = "/"
}



//reset password
export const resetPassword = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
            submitting: true
        });
        const res = await call("post", AuthConstants.RESET_PASSWORD, payload);
        if (res.data.status) {
            dispatch({
                type: AUTH_API_SUCCESS,
                payload: res.data,
                loading: false,
                hasSentOTP: true
            });
            //notify
            notifysuccess(res.data.message)
        } else {
            dispatch({
                type: AUTH_API_FAILED,
                payload: res.data,
                loading: false
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: AUTH_API_FAILED,
            error: err.response.data,
            loading: false
        });
        notifyError(err.message)
    }
}
//reset password
export const setNewPassword = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
            submitting: true,
            set_password_page: true
        });
        const res = await call("post", AuthConstants.SET_PASSWORD, payload);
        if (res.data.status) {
            dispatch({
                type: AUTH_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
                hasSentOTP: false,
                set_password_page: true,
                submitting: false
            });
            //notify
            notifysuccess(res.data.message)
            //redirect to login
            setTimeout(() => {
                window.location.href = "/"
            }, 1500)
        } else {
            dispatch({
                type: AUTH_API_FAILED,
                payload: res.data,
                loading: false
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: AUTH_API_FAILED,
            error: err.response.data,
            loading: false
        });
        notifyError(err.message)
    }
}
//verify otp
export const verifyResetOTP = payload => async (dispatch) => {
    try {
        dispatch({
            type: AUTH_API_REQUEST,
            loading: true,
            submitting: true,
            verifyingOTP: true
        });
        const res = await call("post", AuthConstants.VERIFY_RESET_OTP, payload);
        if (res.data.status) {
            dispatch({
                type: AUTH_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
                verifyingOTP: false,
                verified: true
            });
            //notify
            notifysuccess(res.data.message)
            //redirect
        } else {
            dispatch({
                type: AUTH_API_FAILED,
                payload: res.data,
                loading: false,
                verifyingOTP: false
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: AUTH_API_FAILED,
            error: err.response.data,
            loading: false,
            verifyingOTP: false,
        });
        notifyError(err.message)
    }
}




export const registerMerchants = (payload, stay, navigate) => async (dispatch) => {
    try {
        dispatch({
            type: REGISTER_MERCHANT_API_REQUEST,
            loading: true,
        });
        const res = await call("post", 'https://api.sasapay.me/apps/merchants-portal/api/v1/merchants/merchant-onboarding-staged/', payload);


        if (res.data.status) {
            dispatch({
                type: REGISTER_MERCHANT_API_SUCCESS,
                payload: res.data,
                loading: false,
                message: res.data.message,
            });
            notifysuccess(res.data.message)
            if (!stay) {
                navigate(-1)
            }

        } else {
            dispatch({
                type: REGISTER_MERCHANT_API_FAILED,
                payload: res.data,
                loading: false
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        //console.log('---------------->noma', err);
        dispatch({
            type: REGISTER_MERCHANT_API_FAILED,
            error: err?.response.data,
            loading: false
        });
        notifyError(err.message)
    }
}