import React, { useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Tab,
  Stack,
  Tabs,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import useTabs from "../../../shared/hooks/useTabs";
import { capitalCase } from "change-case";
import Iconify from "src/shared/components/Iconify";
import MySplitBills from "../components/MySplitBills";
import CreatedSplitBills from "../components/CreatedSplitBills";
import { toast } from "react-toastify";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import { FormProvider, RHFTextField } from "../../../shared/components/hook-form";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Title from "src/shared/Text/Title";
import call from 'src/core/services/http';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import BillNumberConstants from "src/shared/reducers/BillNumber/BillNumberConstants";



const SplitBill = () => {
  const [open, setOpen] = useState(false);
  const { currentTab, onChangeTab } = useTabs('CreatedSplitBills');
  const [shop] = useLocalStorage('shop-details');
  const dispatch = useDispatch();
  const [payerFields, setPayerFields] = useState([]);
  const [state, setState] = useState({
    account_number: "",
    account: { account_number: "", account_name: "" }
})



  const handleAddPayerClick = () => {
    const newPayer = { account_number: "", amount: "" };
    setPayerFields([...payerFields, newPayer]);
  };

  const SplitBillSchema = Yup.object().shape({
    merchant_code: Yup.string().required('Please provide recipient account number'),
    amount: Yup.string().required('Fill in the amount'),
    description: Yup.string().required('Fill in the description'),
    bill_payers: Yup.array().of(
      Yup.object().shape({
        account_number: Yup.string().required('Fill in the payer Account Number'),
        amount: Yup.string().required('Fill in the payer Amount'),
      })
    ),
  });

const defaultValues = {
  name: "",
  merchant_code: "",
  amount: "",
  description: "",
};


  const methods = useForm({
    resolver: yupResolver(SplitBillSchema),
    defaultValues,
  });

  


  const { handleSubmit, setValue, formState: { isSubmitting }, watch } = methods;

  const onSubmit = async (data) => {
    const { bill_payers, amount } = data;
  
    const totalContributions = bill_payers.reduce(
      (total, payer) => total + parseFloat(payer.amount || 0),
      0
    );
  
    if (totalContributions !== parseFloat(amount)) {
      toast.error("Total contributions must be equal to the specified amount.");
      return;
    }
  
    const payload = {
      bill_presentment: {
        name: data.description,
        amount: data.amount,
        merchant_code: data.merchant_code,
        description: data.description,
      },
      bill_payers: watch("bill_payers"),
    };
  
    try {
      const response = await call("post", `/bill-presentment/split-bill/`, payload);
  
      if (response.data.status) {
        toast.success(response.data.message);
        setOpen(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error sending the payload:", error);
    }
  };
    

  const SPLITBILL_TABS = [
    {
      value: 'CreatedSplitBills',
      icon: <Iconify icon={"uil:bill"} width={20} height={20} />,
      component: <CreatedSplitBills />,
    },
    {
      value: 'MySplitBills',
      icon: <Iconify icon={"uil:bill"} width={20} height={20} />,
      component: <MySplitBills />,
    },
  ];


  const searchAccount = async (accountNumber) => {
    try {
      const response = await call('post', BillNumberConstants.ACCOUNT_SEARCH, { account_number: accountNumber });
  
      if (response.data.status) {
        const { account_name } = response.data.account;
        setState((prevState) => ({
          ...prevState,
          account: { account_number: accountNumber, account_name },
        }));
        setValue("merchant_code", accountNumber); 
      } else {
        setState((prevState) => ({
          ...prevState,
          account: { account_number: accountNumber, account_name: '' },
        }));
      }
    } catch (error) {
      console.error( error);
    }
  };
  
  


  return (
    <>
      <Box sx={{ m: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 2 }} >
          <Title text="Split Bills" sx={{ color: 'black' }} />
          <Stack spacing={2} direction="row">
            <Button onClick={() => setOpen(!open)} variant="contained" color='primary' startIcon={<Iconify icon="eva:plus-fill" />}>Create A Split Bill</Button>
          </Stack>
        </Box>
      </Box>
      <Grid item xs={12} md={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            centered
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {SPLITBILL_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Box sx={{ mb: 5 }} />
        {SPLITBILL_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Grid>

      <Dialog
        maxWidth='md'
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>Create A SplitBill</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} mt={2}>
            <RHFTextField
              size={'medium'}
              name="merchant_code"
              label='Receiver Account'
              onBlur={(e) => searchAccount(e.target.value)}
              helperText={state.account.account_name} 
            />
              <RHFTextField size={'medium'} name="amount" label='Amount' />
              <RHFTextField size={'medium'} name="description" label='Description' />

              {watch("description") && (
                <input type="hidden" {...methods.register("name")} value={watch("description")} />
              )}

              <Box sx={{ m: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between" }} >
                  <Title text="" sx={{ color: 'black' }} />
                  <Stack spacing={2} direction="row">
                    <Button variant="contained" onClick={handleAddPayerClick} color='primary' startIcon={<Iconify icon="eva:plus-fill" />}>Add A Payer</Button>
                  </Stack>
                </Box>
              </Box>
              {payerFields.map((payer, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <RHFTextField
                      size={'medium'}
                      name={`bill_payers[${index}].account_number`}
                      label='Account Number'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RHFTextField
                      size={'medium'}
                      name={`bill_payers[${index}].amount`}
                      label='Amount'
                    />
                  </Grid>
                </Grid>
              ))}

              <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                <LoadingButton fullWidth size="medium" onClick={() => setOpen(!open)} variant='outlined' color='error'>
                  Cancel
                </LoadingButton>
                <LoadingButton loading={isSubmitting} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SplitBill;
