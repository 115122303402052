import { withStyles } from "@material-ui/core";
import { Card, Tabs } from "@mui/material";

export const CenteredTabs = withStyles({
    flexContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
})(Tabs);

export const CenteredCard = withStyles({
    flexContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
})(Card);