
import { useEffect, useReducer } from 'react';
// @mui
import { capitalCase } from 'change-case';

import {
    Box,
    Grid,
    Button,
    Card,
    Tab,
    Tabs,
    Typography,
    CardContent,
    Stack
} from '@mui/material';

import { ToastContainer } from "react-toastify";
import Page from 'src/shared/components/Page'
import Iconify from 'src/shared/components/Iconify';
import UnprocessedBulkPayments from '../components/UnprocessedBulkPayments';
import useTabs from 'src/shared/hooks/useTabs';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { getBulkPaymentList, getCheckers } from '../store/BulkpaymentActions';
import ProcessedBulkPayments from '../components/ProcessedBulkPayments';
import BulkPaymentPreparation from '../components/BulkPaymentPreparation';
import SpinnerLoader from 'src/shared/plugin/loader/SpinnerLoader';
import { useNavigate } from 'react-router-dom';


function BulkPayment() {

    const { currentTab, onChangeTab } = useTabs('staged Bulkpayments')
    const { error, loading, bulkPaymentList } = useSelector(state => state.bulkpayments)


    const BULKPAYMENT_TABS = [
        {
            value: 'staged Bulkpayments',
            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
            component: <UnprocessedBulkPayments error={error} bulkbulkPaymentList={bulkPaymentList} />,
        },
        {
            value: 'Processed Bulkpayments',
            icon: <Iconify icon={'ic:round-receipt'} width={20} height={20} />,
            component: <ProcessedBulkPayments />,
        }
    ]

    const dispatch = useDispatch()

    const [shop] = useLocalStorage('shop-details')
    // Toggle initiation and list
    const reducer = (state, action) => {
        switch (action.type) {
            case "showInitiateForm":
                return {
                    ...state,
                    showForm: true,
                    showList: false
                }
            case "showTheLists":
                return {
                    ...state,
                    showForm: false,
                    showList: true
                }

            default:
                return state
        }

    }
    const [view, toggleView] = useReducer(reducer, { showList: false, showForm: true })


    useEffect(() => {
        //set payload
        let payload = {
            savings_id: shop.id
        }
        //dispatch
        dispatch(getBulkPaymentList(payload))
        dispatch(getCheckers(payload))
    }, [dispatch, view]);

    const navigate = useNavigate()

    return (
        <Page title="BulkPayment">
            <ToastContainer theme="colored" />
            <Box sx={{ m: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 2 }} >
                    <Typography color="text.primary"></Typography>
                    <Stack direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }} >
                        <Button onClick={() => toggleView({ type: "showInitiateForm" })} disabled={view.showForm} color="primary" variant="contained">Initiate a BulkPayment</Button>
                        <Button onClick={() => toggleView({ type: "showTheLists" })} disabled={view.showList} color="primary" variant="contained">View BulkPayment Records</Button>
                        {/* <Button onClick={() => navigate(PATH_SHOP.shop.main)} variant="outlined" color='primary' startIcon={<Iconify icon="uil:angle-left" />}>Back</Button> */}
                    </Stack>

                </Box>
            </Box>

            <Card>

                <CardContent>

                    {
                        view.showForm && <BulkPaymentPreparation switchView={toggleView} />
                    }
                    {
                        view.showList && <>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={currentTab}
                                onChange={onChangeTab}
                            >
                                {BULKPAYMENT_TABS.map((tab) => (
                                    <Tab disableRipple key={tab.value} iconPosition={'start'} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
                                )
                                )}

                            </Tabs>

                            <Box sx={{ mb: 5 }} />
                            {
                                loading ?
                                    <Grid container sx={{ justifyContent: 'center' }}> <SpinnerLoader /></Grid>
                                    :
                                    <>

                                        {BULKPAYMENT_TABS.map((tab) => {
                                            const isMatched = tab.value === currentTab;
                                            return isMatched && <Box key={tab.value}>{tab.component} </Box>;
                                        })}
                                    </>
                            }


                        </>
                    }
                </CardContent>
            </Card>
        </Page >
    )
}

export default BulkPayment