import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const ShopsLinks = [
    {
        title: 'My Shops',
        path: '/dashboard/my-shops',
        icon: getIcon('ic:outline-business'),
    },
]
