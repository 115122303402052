import Iconify from "src/shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Go Back',
    path: '/dashboard/my-shops/',
    icon: getIcon('pajamas:go-back'),
  },
  {
    title: 'Transfer Money',
    path: '/forex-dashboard/transfer/',
    icon: getIcon('bi:send-fill'),
  },
  {
    title: 'Forex Deposit',
    path: '/forex-dashboard/deposit/',
    icon: getIcon('akar-icons:statistic-up'),
  },

  
  
]

export default navConfig;
