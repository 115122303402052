import {
    TableCell,
    tableCellClasses,
    TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#BEE9F8",
        color: theme.palette.common.black,
        fontWeight: 600,
        padding: 'none',
        alignItems: 'start'

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        padding: 'none',


    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.focus,
    },
    "&:hover": {
        backgroundColor: `${theme.palette.primary.light} !important`,
        cursor: 'pointer'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));