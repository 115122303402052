import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from 'src/shared/hooks/useResponsive';
import useOffSetTop from 'src/shared/hooks/useOffSetTop';
import { HEADER } from 'src/config';
import navConfig from './MenuConfig';
import MenuDesktop from './MenuDesktop';
import Logo from '../../components/Logo';


const drawerWidth = 240;
const navItems = [{ id: 0, name: 'Products', link: '' }, { id: 1, name: 'Solutions', link: '' }, { id: 2, name: 'About US', link: '' }];

function MainHeader(props) {
    const { pathname } = useLocation();
    const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
    const isHome = pathname === '/';
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const isDesktop = useResponsive('up', 'md');


    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            {/* <Box component="img" alt="sasapay" src={sasapaylogo} sx={{ width: 160, height: 80 }} /> */}
            <Box sx={{ px: 2.5, py: 1.5, display: 'inline-flex' }}>
               <a href="https://sasapay.co.ke/" target="_blank" rel="noreferrer">
               <img src={require("../../assets/images/sasapay.png")} alt="logo" />
                </a>  
            </Box>
            
            <Divider />
            <List>
                {navConfig.map((item) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText primary={item.title} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Container maxWidth="xl"  >
            <Box sx={{ display: 'flex', }}>
                <CssBaseline />
                <AppBar sx={{ backgroundColor: 'white', }} component="nav" elevation={2}>
                    <Toolbar >
                        {/* <IconButton
                            color="info"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton> */}
                        <Logo isMain={true} />

                        <Box sx={{
                            flexGrow: 2
                        }} />

                        {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
                        }

                        < Button
                            variant="contained"
                            onClick={() => navigate('/auth/login', { replace: true })}
                        >
                            Login
                        </Button>
                    <Box>
                        </Box> 
                        <Box sx={{ xs: { mr: 0 }, sm: { mr: 8 }, md: { mr: 8 }, lg: { mr: 8 } }} />
                    </Toolbar>
                </AppBar>

                <Box component="nav">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Box>

            </Box>
        </Container>
    );
}

MainHeader.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default MainHeader;
