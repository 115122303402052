import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import {
  TableContainer, DialogContent, DialogActions, TableRow, TableCell, Paper, Button, Table, TableBody, Grid
} from '@mui/material';
import call from 'src/core/services/http';
import { CustomCell, CustomRow } from 'src/shared/TableComponents/TableStyles';
import ListHead from 'src/shared/TableComponents/ListHead';
import { toast } from 'react-toastify';
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import { LoadingButton } from '@mui/lab';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';


const TABLE_HEAD = [
  { id: 'id', label: " Id", alignLeft: true },
  { id: 'display_name', label: "Name", alignLeft: true },
  { id: 'mobile_number', label: "Account", alignLeft: true },
  { id: 'total_amount', label: " Amount", alignLeft: true },
  { id: 'description', label: " Description", alignLeft: true },
  { id: 'target_date', label: "Due Date", alignLeft: true },
  { id: 'status', label: "status", alignLeft: true },
  { id: 'action', label: "Action", alignLeft: true },
];



function ToReceive() {
  const [shop] = useLocalStorage('shop-details');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [row, setrow] = useState({})
  const [isNotFound, setIsNotFound] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [selected, setSelected] = useState([]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedData = [...data.me_as_beneficiary_escrow_entries].sort((a, b) => {
      const result = isAsc
        ? a[property] < b[property]
          ? -1
          : 1
        : b[property] < a[property]
          ? -1
          : 1;
      return result;
    });

    setData({ ...data, me_as_beneficiary_escrow_entries: sortedData });
  };





  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Object.keys(data).map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected({});
  };


  const getToReceive = async () => {
    try {
      const response = await call('get', `/escrow/?merchant_code=${shop.account_number}`);
      if (response.data.status) {
        setData(response.data);
        setIsLoading(false);
        if (response.data.me_as_beneficiary_escrow_entries.length === 0) {
          setIsNotFound(true);
        }
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getToReceive();
  }, []);




  const claimEscrow = async () => {
    const { id } = row;
    const merchant_code = shop.account_number;
    const payload = {
      status: 1,
      merchant_code,
    };
    setIsLoading(true);
    try {
      const response = await call('post', `/escrow/beneficiary/${id}/status-update/`, payload);
      if (response.data.status) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <CustomDialog maxWidth='md' open={open} onClose={() => setOpen(!open)}>
        <CustomDialogTitle>
          Claim Escrow
        </CustomDialogTitle>
        <DialogContent>
          <Typography variant='subtitle2'>
            Would you like to Claim this escrow?
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={claimEscrow}>
            Yes
          </LoadingButton>

          <Button onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </CustomDialog>

      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 18 }} color="text.error" gutterBottom>
            Money To Be Received
          </Typography>
        </CardContent>

        {isLoading ? (
          <Grid container sx={{ justifyContent: 'center' }}> <SpinnerLoader /></Grid>
        ) : (
          <TableContainer sx={{ overflow: "auto" }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                numSelected={Object.keys(selected).length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
              />


              {isNotFound ? (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: 'center',
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                        <Typography variant="body2">
                          No results found for <strong>{shop.account_number}</strong>
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {data.me_as_beneficiary_escrow_entries.map((row, index) => {
                    const { id, description, escrow: { total_amount, target_date, status}, escrow: { sending_escrow_acc: { client: { display_name, mobile_number } } } } = row;
                    const rowNumber = index + 1;

                    return (
                      <CustomRow hover key={id}>
                        <CustomCell >{rowNumber}</CustomCell>
                        <CustomCell >{display_name}</CustomCell>
                        <CustomCell >{mobile_number}</CustomCell>
                        <CustomCell >{total_amount}</CustomCell>
                        <CustomCell >{description}</CustomCell>
                        <CustomCell >{target_date}</CustomCell>
                        <CustomCell>
                          {status === 0 ? "Pending" : status === 1 ? "Processed" : ""}
                        </CustomCell>
                        <Button size='small' variant='text' onClick={() => {
                          setrow(row);
                          setOpen(!open);
                        }}>
                          Claim
                        </Button>
                      </CustomRow>
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Card>
    </>
  );
}

export default ToReceive;
