import moment from "moment";

import { appAlgorithmKey } from "../../../core/environment/environment";
require("crypto-js");
//
export function formatDate(date) {
    return moment(date).format('MMMM Do YYYY, h:mm:ss a')
}
export function encrypt(value) {
    return btoa(appAlgorithmKey + value)
}
export function decrypt(value) {
    const code = atob(value);

    return code.split("/").pop();
}
export function formatCash(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}

export function getTheAccounts(identifier, controlAccounts) {
    let accountFrom;
    let accountTo;
    switch (identifier) {
        case 1:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "WACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "UTACC")[0]
            return { accountFrom, accountTo }
        case 2:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "WACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "BULKACC")[0]
            return { accountFrom, accountTo }
        case 3:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "UTACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "WACC")[0]
            return { accountFrom, accountTo }
        case 4:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "UTACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "BULKACC")[0]
            return { accountFrom, accountTo }
        case 5:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "BULKACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "WACC")[0]
            return { accountFrom, accountTo }
        case 6:
            accountFrom = controlAccounts.filter(account => account.account_control_type.short_code === "BULKACC")[0]
            accountTo = controlAccounts.filter(account => account.account_control_type.short_code === "UTACC")[0]
            return { accountFrom, accountTo }

        default:
            break;
    }
}

