import PropTypes from 'prop-types';
import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
// utils
import { fCurrency } from '../../../shared/utils/formatNumber';
import { fDate, fDateTime } from '../../../shared/utils/formatTime';
//
import styles from './TransactionStyle';
import Logo from '../../../shared/assets/images/sasapay.png';

// ----------------------------------------------------------------------

TransactionPDF.propTypes = {
  transactionRecord: PropTypes.object.isRequired,
};

export default function TransactionPDF({ transactionRecord }) {
  const {
    savings_account_account_number,
    savings_account_description,
    transaction_type_enum,
    is_reversed,
    created_date,
    amount,
    charge_amount,
    description,
    transaction_code,
    party_B_display_name,
    party_B_account_number,
    account_operator_operator_display_name
  } = transactionRecord;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb8]}>
          <Image source="/logo/sasapay.png" style={{ height: 32 }} />
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h3}>{is_reversed === 0 ? "Success" : is_reversed === 0 ? "Reversed" : ""}</Text>
            <Text> {transaction_code} </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb8]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Transaction from</Text>
            <Text style={styles.body1}>{savings_account_description}</Text>
            <Text style={styles.body1}>{savings_account_account_number}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Transaction to</Text>
            <Text style={styles.body1}>{party_B_display_name}</Text>
            <Text style={styles.body1}>{party_B_account_number}</Text>
          </View>
        </View>



        <Text style={[styles.overline, styles.mb8]}>Transaction Details</Text>

        <View style={styles.table}>

          <View style={styles.tableBody}>
            <View style={styles.tableRow} >
              <View style={styles.tableCell_3}>
                <Text>Amount</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>{fCurrency(amount)}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text>Charge Amount</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text>{fCurrency(charge_amount)}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text>Transaction Date</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text>{fDateTime(created_date)}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text>Transaction Type</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text>{transaction_type_enum === 1 ? "Transaction In" : transaction_type_enum === 2 ? "Transaction Out" : ""}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text>Transaction Description</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text>{description}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text>Operator</Text>
              </View>
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3}>
                <Text>{account_operator_operator_display_name}</Text>
              </View>
            </View>

          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>Thank You</Text>
            <Text>We appreciate your business.</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>Have a Question?</Text>
            <Text>care@sasapay.co.ke</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
