import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  Tab,
  Stack,
  Slide,
  Tabs,
  Dialog,
  TextField,
  DialogTitle,
  ListItemText,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  Select,
  DialogContent,
} from "@mui/material";
import useTabs from "../../../shared/hooks/useTabs";
import { capitalCase } from "change-case";
import Page from "../../../shared/components/Page";
import Iconify from "src/shared/components/Iconify";
import History from "../components/History";
import Topay from "../components/ToPay";
import ToReceive from "../components/ToReceive";
import { toast } from "react-toastify";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import AuthService from "../../../core/access-control/AuthService";
import { FormProvider, RHFTextField } from "../../../shared/components/hook-form";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import call from "src/core/services/http";
import Title from "src/shared/Text/Title";
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { fDateDMY } from "src/shared/utils/formatTime";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import MuiPhoneNumber from 'material-ui-phone-number';
import TransactionConstants from '../../../packages/transactions/store/TransactionConstants';
import UserConstants from 'src/packages/users/store/UserConstants';
import { filter } from 'lodash';




export default function Escrow() {
  const [open, setOpen] = useState(false);
  const { currentTab, onChangeTab } = useTabs('Topay');
  const [shop] = useLocalStorage('shop-details');
  const [start_date, set_start_date] = useState(new Date());
  const dispatch = useDispatch();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('');
  const [showInputField, setShowInputField] = useState(false);
  const { countryCodes, countries } = useSelector(state => state.countries);

  const options = [
    {
      key: '0',
      name: 'recipient_account_number',
      label: 'SasaPay Paybill/Till Number',
    },
    {
      key: '1',
      name: 'recipient_account_number',
      label: 'SasaPay Mobile Number',
    }
  ];




  const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

  const EscrowSchema = Yup.object().shape({
    recipient_account_number: Yup.string().required('Please provide recipient account_number'),
    sender_account_number: Yup.string().required('Provide sender account number'),
    amount: Yup.string().required('Fill in the amount'),
    description: Yup.string().required('Fill in the description'),
    target_date: Yup.date()
      .required('Due Date is required')
  });

  const defaultValues = {
    sender_account_number: shop.account_number,
    recipient_account_number: "",
    amount: "",
    description: "",
    target_date: "",
  };

  const methods = useForm({
    resolver: yupResolver(EscrowSchema),
    defaultValues,
  });

  const { handleSubmit, option, setValue, watch, formState: { isSubmitting } } = methods;
  const payementOptionInputContol = filter(option, p => p.key === option)[0]


  const onSubmit = async () => {
    const payload = {
      ...watch(),
    target_date: dayjs(watch('target_date')).format('YYYY-MM-DD'),
    };
    const res = await call("post", `/escrow/`, payload);
    if (res.data.status) {
      toast.success(res.data.message)
      setOpen(false);
    } else {
      toast.error(res.data.message)
    }
  };


  // const [display, setDisplay] = useState('')

  // const searchInput = async () => {
  //     if (payementOptionInputContol.key === '0') {
  //         const response = await call('post', TransactionConstants.MERCHANTCODE, { bill_number: recipient_account_number })
  //         if (response.status) {
  //             setDisplay(response.data.merchant.description)
  //         }
  //     } else if (payementOptionInputContol.key === '1') {
  //         const response = await call('post', UserConstants.SEARCH_USER, { mobile_number: recipient_account_number })
  //         let data = response.data
  //         if (data.status) {
  //             data = data.results.clients[0]
  //             setDisplay(data.display_name)
  //             setValue('recipient_account_number', data.mobile_number)
  //         } else {
  //             setValue('recipient_account_number', '')
  //         }
      
  //     }
  // }

  const ESCROW_TABS = [
    {
      value: 'Topay',
      icon: <Iconify icon={"material-symbols:install-mobile"} width={20} height={20} />,
      component: <Topay />,
    },
    {
      value: 'ToReceive',
      icon: <Iconify icon={"mdi:bank-transfer-in"} width={20} height={20} />,
      component: <ToReceive />,
    },
    {
      value: 'History',
      icon: <Iconify icon={"mdi:bank-transfer-out"} width={20} height={20} />,
      component: <History />,
    },
  ];


  return (
    <Page title="Escrow">
      <Box sx={{ m: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 2 }} >
          <Title text="Escrow" sx={{ color: 'black' }} />
          <Stack spacing={2} direction="row">
            <Button onClick={() => setOpen(!open)} variant="contained" color='primary' startIcon={<Iconify icon="eva:plus-fill" />}>Add New Escrow Entry</Button>
          </Stack>
        </Box>
      </Box>
      <Grid item xs={12} md={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {ESCROW_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Box sx={{ mb: 5 }} />
        {ESCROW_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Grid>

      <Dialog
        maxWidth='md'
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <DialogTitle>Add New A Escrow Entry</DialogTitle>
        <DialogContent>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} mt={2}>
              <FormControl>
                <InputLabel id="paymentoption">Payment Option</InputLabel>
                <Select
                  labelId="optionlabelId"
                  id="optionId"
                  onChange={(e) => {
                    setSelectedPaymentOption(e.target.value);
                    setShowInputField(true);
                  }}
                  name='option'
                  input={<OutlinedInput label="Payment Option" />}
                >
                  <MenuItem value="0" disabled>---SELECT---</MenuItem>
                  {options.map(({ key, label }) => (
                    <MenuItem key={key} value={key}>
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {showInputField && selectedPaymentOption === '1' && (
                <MuiPhoneNumber
                  defaultCountry={'tz'}
                  onChange={(phone) => setValue("recipient_account_number", phone.slice(1))}
                  variant="outlined"
                  label="SasaPay Mobile Number"
                />
              )}
              {showInputField && selectedPaymentOption === '0' && (
                <RHFTextField size={'medium'} name="recipient_account_number" label='SasaPay Paybill/Till Number' />
              )}
              <RHFTextField size={'medium'} name="amount" label='Amount' />
              <RHFTextField size={'medium'} name="description" label='Description' />

              <Controller
                name="target_date"
                control={methods.control}
                defaultValue={start_date}
                render={({ field }) => (
                  <DatePicker
                    label="Due Date"
                    value={start_date}
                    minDate={start_date}
                    onChange={(target_date) => {
                      set_start_date(target_date);
                      field.onChange(target_date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                )}
              />

              <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                <LoadingButton fullWidth size="medium" onClick={() => setOpen(!open)} variant='outlined' color='error'>
                  Cancel
                </LoadingButton>
                <LoadingButton loading={isSubmitting} onClick={onSubmit} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                  Submit
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
