import {
    SHOP_API_FAILED,
    SHOP_API_REQUEST,
    SHOP_API_SUCCESS,
} from "./ShopActionTypes";

const initialState = {
    loading: false,
    message: "",
    shops: [],
    error: {},
    pagination: {},
    merchant: {},
    count: ""
}

export default function ShopReducer(state = initialState, action) {
    const { type, payload, message, loading, error, } = action;
    switch (type) {
        /**
         * Shops
         */
        case SHOP_API_REQUEST:
            return {
                ...state,
                loading,
            };
        case SHOP_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                shops: payload?.results?.accounts,
                merchant: payload?.results?.merchant,
                pagination: payload?.pages,
                count: payload?.count
            }
        case SHOP_API_FAILED:
            return {
                ...state,
                loading,
                error
            };
        default:
            return state
    }
}