import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import {
    TableContainer, Table, TableBody, Grid, DialogContent, TableRow, TableCell, Paper, DialogActions, Button
} from '@mui/material';
// import AuthService from 'src/core/access-control/AuthService';
import call from 'src/core/services/http';
import { CustomCell, CustomRow } from 'src/shared/TableComponents/TableStyles';
import ListHead from 'src/shared/TableComponents/ListHead';
import { toast } from 'react-toastify';
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import { LoadingButton } from '@mui/lab';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';


const TABLE_HEAD = [
    { id: 'id', label: " Id ", alignLeft: true },
    { id: 'created_by', label: "Created By", alignLeft: true },
    { id: 'account_number', label: "Account Number", alignLeft: true },
    { id: 'bill_name', label: " Description", alignLeft: true },
    { id: 'amount', label: "Amount", alignLeft: true },
    { id: 'created_at', label: "Date", alignLeft: true },
    { id: 'status', label: "status", alignLeft: true },
    { id: 'Action', label: "Action", alignLeft: true },


];



const MySplitBills = () => {
    const [shop] = useLocalStorage('shop-details');
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState({});
    const [open, setOpen] = useState(false)
    const [row, setrow] = useState({})
    const [isNotFound, setIsNotFound] = useState(false);





    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = Object.keys(data).map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected({});
    };

    const getMyBills = async () => {
        try {
            const response = await call('get', `/bill-presentment/split-bill/?account_number=${shop.account_number}`);
            if (response.data.status) {
                setData(response.data);
                console.log(response.data)
                setIsLoading(false);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };



    useEffect(() => {
        getMyBills();
    }, []);




    const processBill = async (e) => {
        const { id } = row;

        setIsLoading(true);
        try {
            const response = await call('post', `/bill-presentment/process-split-bill/${id}/`);
            if (response.status === 200) {
                toast.success("Payment successful!");
            } else {
                toast.error("Payment failed!");
            }
        } catch (error) {
            toast.error("Payment error!");
        } finally {
            setIsLoading(false);
            setOpen(false);
        }
    };



    return (
        <>
            <CustomDialog maxWidth='md' open={open} onClose={() => setOpen(!open)}>
                <CustomDialogTitle>
                    Process Bill
                </CustomDialogTitle>
                <DialogContent>
                    <Typography variant='subtitle2'>
                        Would you like to process this Bill?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <LoadingButton onClick={processBill}>
                        Yes
                    </LoadingButton>
                    <Button onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </CustomDialog>

            <Card sx={{ overflow: "auto" }}>
                <CardContent>
                    <Typography sx={{ fontSize: 18 }} color="text.error" gutterBottom>
                        My Split Bills
                    </Typography>
                </CardContent>

                {isLoading ? (
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <SpinnerLoader />
                    </Grid>
                ) : (
                    <TableContainer sx={{ minWidth: 800, position: 'relative'}}>
                        <Table  size={'medium'}>
                            <ListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />

                            {isNotFound ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                                            <Paper
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography variant="h6" paragraph>
                                                    Not found
                                                </Typography>
                                                <Typography variant="body2">
                                                    No results found for <strong>{shop.account_number}</strong>
                                                </Typography>
                                            </Paper>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {data.my_split_bills.map((row, index) => {
                                        const { id, created_by: { created_by, account_number }, bill_name, amount, status, created_at } = row;
                                        const rowNumber = index + 1;
                                        return (
                                            <CustomRow hover key={id}>
                                                <CustomCell >{rowNumber}</CustomCell>
                                                <CustomCell >{created_by}</CustomCell>
                                                <CustomCell >{account_number}</CustomCell>
                                                <CustomCell >{bill_name}</CustomCell>
                                                <CustomCell >{amount}</CustomCell>
                                                <CustomCell >{created_at}</CustomCell>
                                                <CustomCell color='primary'>
                                                    {status === 0 ? "Pending" : status === 1 ? "Processed" : ""}
                                                </CustomCell>
                                                <CustomCell align="left">
                                                    <Button size='small' variant='text' onClick={() => {
                                                        setrow(row);
                                                        setOpen(!open);
                                                    }}>
                                                        pay
                                                    </Button>
                                                </CustomCell>

                                            </CustomRow>
                                        )
                                    })}
                                </TableBody>

                            )}
                        </Table>
                    </TableContainer>
                )}
            </Card>
        </>
    )
}

export default MySplitBills;