import { useEffect, useState, forwardRef } from 'react';
import * as Yup from 'yup';
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  DialogContent,
  Backdrop,
  Slide,
  Dialog,
  Container,
  CssBaseline, Grid, Typography, Avatar, Link, Box, RadioGroup, FormControlLabel, Radio
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Iconify from '../../../components/Iconify';
import OTPInput from "otp-input-react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, RHFCheckbox, RHFTextField } from '../../../components/hook-form';
import { operatorLogin, login, verifyOTP } from "../../../../packages/auth/store/AuthActions";
import LoopIcon from '@mui/icons-material/Loop';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import { useNavigate } from 'react-router-dom';
import RegisterSelect from 'src/packages/auth/components/RegisterMerchant';
import SpinnerLoader from 'src/shared/plugin/loader/SpinnerLoader';
import { QRCode } from 'react-qrcode-logo';
import MuiPhoneNumber from 'material-ui-phone-number';
// ----------------------------------------------------------------------
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export default function LoginForm() {
  // const { login } = useAuth();
  const dispatch = useDispatch();
  const { loading, showSignup, showOTP, isOperator } = useSelector(state => state.auth)
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOTP] = useState("");
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  const defaultValues = {
    username: '',
    password: '',
    otp
  };
  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });
  const {
    reset,
    getValues,
    setValue,
    watch,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;
  const onSubmit = async () => {
    dispatch(login({ ...getValues(), isOperator: loginOption === '1' }))
  };
  //verify
  useEffect(() => {
    setValue('otp', otp)
    //
    if (otp.length === 6) {
      const data = { ...getValues(), isOperator: loginOption === '1' }
      dispatch(verifyOTP(data))
      setOTP("")
      setValue('otp', "")
    }
  }, [otp]);
  // Handle forgot Password
  const forgotPassword = (e) => {
    e.preventDefault();
    navigate('/auth/forgot-password')
  }
  // Handle login as operator
  const [loginOption, setLoginOption] = useState('0')
  const navigate = useNavigate()
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const values = watch()
  return (
    <div>
      {showOTP && <Box>

        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <div>
            <Grid
              container
              // style={{ backgroundColor: "white" }}
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item container justify="center">
                <Grid item container alignItems="center" direction="column">
                  <Grid item>
                    <Avatar>
                      <LockPersonIcon color="error" />
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Typography component="h1" variant="h5">
                      Verification Code
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6">
                  Please enter the verification code
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justify="center"
                alignItems="center"
                direction="column"
              >
                <Grid item justify="center">
                  {loading && <SpinnerLoader />}
                  {!loading && <OTPInput
                    autoFocus
                    OTPLength={6}
                    value={otp}
                    onChange={setOTP}
                    otpType="number"
                    disabled={false}
                    secure
                    separator={
                      <span>-</span>
                    }
                    inputStyle={{
                      width: "4rem",
                      height: "5rem",
                      margin: "0 1rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)"
                    }}
                  />}
                  {/* <Box sx={{ mt: 5 }}>
                    <LoadingButton fullWidth size="medium" type="submit" variant="contained" loading={loading}>
                      <LoopIcon /> Resend Verification Code
                    </LoadingButton>
                  </Box> */}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Box>
      }
      {
        showSignup &&
        <>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', mb: 1 }} >
            {/* <Logo isMain={true} /> */}
            <Typography variant='h4' >Sign in to your Account</Typography>
          </Box>
          <Box component='div' sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', mb: 1 }} >
            <RadioGroup
              row
              aria-labelledby="login_option"
              name="login_option"
              onChange={(e) => {
                setLoginOption(e.target.value)
              }}
              defaultValue={"0"}
            >
              <Stack direction={'row'}>
                {
                  ["Main Merchant", "Operator"].map((value, index) => <FormControlLabel key={index} value={index.toLocaleString()} control={<Radio />} label={value} />)
                }
              </Stack>
            </RadioGroup>
          </Box>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>

            {/* inputProps={{
                autocomplete: 'off',
                form: {
                  autocomplete: 'off',
                },
              }} */}
              <RHFTextField name="username"  label="Telephone Number" />
             
              {loginOption === "1" && <RHFTextField name="biller_number" label="Paybil/Till Number" />}
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}></Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
              Login
            </LoadingButton>
            {/* <Box sx={{ my: 2, display: "flex", justifyContent: "center" , cursor: "pointer"}}>
              <Link onClick={() => setShowRegisterForm(true)}>
             Register as a Merchant!
              </Link>
            </Box> */}
            <Box sx={{ my: 2, display: "flex", justifyContent: "center", cursor: "pointer" }}>
              <Link onClick={forgotPassword}>
                Forgot Password? Click to Reset
              </Link>
            </Box>
          </FormProvider>
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={showRegisterForm}>
            <Dialog
              maxWidth="sm"
              fullWidth
              TransitionComponent={Transition}
              open={showRegisterForm}
              onClose={() => setShowRegisterForm(false)}
            >
              <DialogContent>
                <RegisterSelect closeRegistrationModal={setShowRegisterForm} />
              </DialogContent>
            </Dialog>
          </Backdrop>
        </>
      }
    </div>
  );
}