import {PAYMENT_TYPE_API_FAILED, PAYMENT_TYPE_API_REQUEST, PAYMENT_TYPE_API_SUCCESS} from "./PaymentTypeActionTypes";

const initialState={
    loading: false,
    message: "",
    paymentTypes: [],
    error: {},
    pagination: {},
}
export default function PaymentTypeReducer(state=initialState, action){
    const {type,payload,message,loading,error} = action;
    switch (type) {
        /**
         * Shops
         */
        case PAYMENT_TYPE_API_REQUEST:
            return {
                loading,
            };
        case PAYMENT_TYPE_API_SUCCESS:
            //console.log(payload)
            return {
                loading,
                paymentTypes: payload?.payment_types,
                message,
                pagination: payload
            }
        case PAYMENT_TYPE_API_FAILED:
            return {
                loading,
                error
            };
        default:
            return state
    }
}