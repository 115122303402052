import { Grid, Backdrop, Dialog, DialogContent, Slide, DialogTitle, Typography, Button, Switch, Box, IconButton, MenuItem, Card, Stack, CardHeader, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Divider } from '@mui/material'
import { toast } from "react-toastify";
import * as Yup from 'yup';
import * as XLSX from 'xlsx';
import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from 'src/shared/components/hook-form';
import Iconify from 'src/shared/components/Iconify';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountBalancesSummary from './AccountBalancesSummary'
import BulkPaymentCheckersList from './BulkPaymentCheckers'
import Scrollbar from "src/shared/components/Scrollbar";
import MenuPopover from 'src/shared/components/MenuPopOver';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import call from 'src/core/services/http';
import BulkpaymentConstants from '../store/BulkpaymentConstants';

const notifySuccess = msg => {
    toast.success(msg)
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);



function BulkPaymentPreparation({ switchView }) {

    const [isChecked, setIsChecked] = useState("show")
    const [bulkVisibility, setBulkVisibility] = useState("show")
    const [shop] = useLocalStorage('shop-details')

    const fileInputRef = useRef(null);


    const [file, setFile] = useState(null)

    const handleAttach = () => {
        fileInputRef.current?.click();
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case "hideInstructions":
                return {
                    ...state,
                    showInstructions: false,
                    showBulkList: false,
                    showInputForm: true
                }
            case "hideInputForm":
                return {
                    ...state,
                    showInstructions: false,
                    showBulkList: true,
                    showInputForm: false
                }
            case "hideBulkList":
                return {
                    ...state,
                    showInstructions: true,
                    showBulkList: false,
                    showInputForm: false
                }
            default:
                return state
        }
    }

    const [view, toggleView] = useReducer(reducer, { showInstructions: true, showInputForm: false, showBulkList: false })

    const fileChosen = (e) => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            toggleView({ type: "hideInstructions" })
        }
    }

    const { error, bulkpaymentCheckers } = useSelector(state => state.bulkpayments)

    // Handle form input
    const bulkFormSchema = Yup.object().shape({
        checker_operator_id: Yup.string().required("please select a checker"),
        reason: Yup.string().required("Please enter a description")
    })

    const methods = useForm({
        resolver: yupResolver(bulkFormSchema),
        defaultValues: {
            checker_operator_id: "",
            reason: ""
        }
    })

    const {
        handleSubmit,
        getValues,
        reset,
        formState: { isSubmitting },
    } = methods;


    const notifyError = msg => {
        toast.error(msg)
    }

    // read the uploaded file and transform it to json
    const [bulklistJSON, setBulklistJSON] = useState([])

    const readFileData = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            var dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            dataParse = dataParse.splice(1, dataParse.length);

            if (dataParse.length > 100) {
                notifyError("Please upload in batches of 100 records");
                return;
            }

            dataParse.map((item) => {
                let name = item[0];
                let mobile = item[1];
                let channel_code = item[2];
                let amount = item[3];
                let comment = item[4];
                if (item.length > 5) {
                    notifyError(`${name} has invalid data.`);
                } else if (!Number.isInteger(amount)) {
                    notifyError(`${name} has an invalid amount (${amount})`);
                } else if (comment === undefined) {
                    comment = item[4] = "No reference";
                }
            });

            setBulklistJSON(dataParse);
        };
        reader.readAsBinaryString(file);
        setFile(null);
    }


    const onSubmit = () => {
        readFileData(file)
        toggleView({ type: "hideInputForm" })
    }

    const remove = (items, index) => {
        return [...items.slice(0, index), ...items.slice(index + 1, items.length)];
    };


    const [removeMessage, setRemoveMessage] = useState("")
    const [index, setIndex] = useState("");

    function deleteItem(item) {
        var index = bulklistJSON.indexOf(item);

        item = bulklistJSON[index];

        setRemoveMessage(
            `Please confirm to remove ${item[0]} amount ${shop.currency_code}${item[3]} from the list.`
        );

        setIndex(index);

    }

    const [openConfirm, setOpenConfirm] = useState(false)

    const [totalAmount, setTotalAmount] = useState("")
    const [errorOccurred, setErrorOccurred] = useState("")


    function __validate() {
        setTotalAmount(0);
        setErrorOccurred(false);
        let amount_data = 0;
        bulklistJSON.map((item) => {
            let name = item[0];
            let mobile = item[1];
            let channel_code = item[2];
            let amount = item[3];
            let comment = item[4];
            let fintech = item[5];
            if (item.length > 5) {
                setErrorOccurred(true);
                notifyError(`${name} has invalid data.`);
            } else if (!Number.isInteger(amount)) {
                setErrorOccurred(true);
                notifyError(`${name} has an invalid amount ( ${amount})`);
            }

            amount_data += parseInt(amount);
        });

        setTotalAmount(amount_data);
    }
    const [stagingBulk, setStagingBulk] = useState(false)

    const onStageBulkPaymentClicked = async () => {
        setStagingBulk(true)

        const { checker_operator_id, reason } = getValues()
        const payload = {
            data: bulklistJSON,
            account_number: shop.account_number, 
            reason,
            checker_operator_id,
        };

        const response = await call('post', `${BulkpaymentConstants.STAGEBULKPAYMENT}${shop.id}/`, payload)

        setStagingBulk(false)
        if (response.data.status) {
            notifySuccess(response.data.message)
            setOpenConfirm(false)
            switchView({ type: "showTheLists" })

        }
    }

    return (
        <>

            <Grid container spacing={4}>
                <Grid
                    item
                    md={2}
                    sx={{
                        display: { xs: "none", md: "block" }
                    }}
                > 
                </Grid>
                {view.showInstructions && <Grid item xs={12} md={12} sx={{ mx: 2 }} >
                    <Typography variant='subtitle1'> Move the necessary Funds to your Bulkpayment Account.</Typography>
                    {isChecked !== 'hide' && <AccountBalancesSummary />}

                    <Typography variant='subtitle1' sx={{ my: 3 }}></Typography>
                    <Button sx={{ my: 1 }} onClick={() => {window.location.href =
                        "https://sasapay-merchants.s3.ap-southeast-1.amazonaws.com/bulk-upload-template.xlsx" }} size="small" color="info" variant="outlined">
                              <Iconify icon={'eva:download-fill'} width={24} height={24} />
                              Download the Excel BulkPayment Template and fill it.
                        </Button> 
                    <Typography variant='subtitle1' sx={{ my: 3 }}> </Typography>
                    <Typography variant='subtitle1' sx={{ my: 3 }}> </Typography>
                    <Button sx={{ my: 1 }} size="small" color="info" variant='outlined' onClick={handleAttach}>
                        <Iconify icon={'eva:attach-2-fill'} width={24} height={24} />
                        Upload the filled Excel BulkPayment Template file
                    </Button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        onChange={e => fileChosen(e)}
                    />
                </Grid>}
                {view.showInputForm && <Grid item xs={12} md={12} sx={{ mx: 2 }} > <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>

                        <Box
                            sx={{
                                display: 'grid',
                                rowGap: 3,
                                columnGap: 2,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', },
                            }}
                        >
                             <Typography variant='subtitle1' sx={{ my: 1 }}> 
                             Choose a Checker for the BulkPayment and the Description of the BulkPayment Upload
                             </Typography> 

                            <RHFSelect name="checker_operator_id">
                                {bulkpaymentCheckers?.map((option) => (
                                    <option key={option.id} value={option.id}> 
                                     {option?.operator?.display_name} - {option?.operator_type?.name} 
                                    </option>
                                ))}
                            </RHFSelect>

                            <RHFTextField name="reason" label="Bulk Payment Description" />

                        </Box>
                        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                Submit
                            </LoadingButton>
                        </Stack>
                </FormProvider>
                </Grid>
                }

            </Grid>
            <Grid container>
                {view.showBulkList && <Grid item xs={12} md={10} sx={{ mx: 2 }} >
                    <Card>
                        <CardHeader title="Uploaded Records" sx={{ mb: 3 }} />
                        <Scrollbar >
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Account/Phone Number</TableCell>
                                            <TableCell>Channel</TableCell>
                                            <TableCell>Amount</TableCell>
                                            <TableCell>Payment Reference</TableCell>
                                            <TableCell>Fintech Account</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {bulklistJSON?.map((row) => (
                                            <TableRow key={row[1]}>
                                                <TableCell>{row[0]}</TableCell>
                                                <TableCell>{row[1]}</TableCell>
                                                <TableCell>{row[2]}</TableCell>
                                                <TableCell>{row[3]}</TableCell>
                                                <TableCell>{row[4]}</TableCell>
                                                <TableCell>{row[5]}</TableCell>
                                                <TableCell align="right">
                                                    <MoreMenuButton removeMessage={removeMessage} deleteItem={() => deleteItem(row)} setNewJSON={setBulklistJSON} remove={remove} bulklistJSON={bulklistJSON} index={index} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                        <Button sx={{ m: 3 }} size='medium' variant='contained' onClick={() => { setOpenConfirm(true); __validate() }} color='info'>Confirm </Button>
                    </Card>
                </Grid>}
            </Grid>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openConfirm}
            >
                <Dialog
                    maxWidth='md'
                    TransitionComponent={Transition}
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}>

                    <DialogTitle>Confirmation</DialogTitle>
                    <DialogContent>
                        <Stack spacing={2} mt={2}>
                            {`Confirm to make payment of ${shop.currency_code} ${totalAmount}. Total records is ${bulklistJSON.length}`}

                            <Stack direction={{ sm: 'column', md: 'row', mt: 3 }} spacing={5} alignContent='space-between'>
                                <LoadingButton loading={stagingBulk} size="medium" onClick={() => onStageBulkPaymentClicked()} variant='contained' color='primary'>
                                    Submit
                                </LoadingButton>
                                <LoadingButton size="medium" onClick={() => setOpenConfirm(!openConfirm)} variant='contained' color='error'>
                                    Cancel
                                </LoadingButton>
                            </Stack>
                        </Stack> 
                    </DialogContent>
                </Dialog>
            </Backdrop >
        </>
    )
}

export default BulkPaymentPreparation

function MoreMenuButton({ removeMessage, deleteItem, setNewJSON, remove, bulklistJSON, index }) {
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        deleteItem()
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const ICON = {
        mr: 2,
        width: 20,
        height: 20,
    };

    return (
        <>
            <IconButton size="large" onClick={handleOpen}>
                <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            </IconButton>
            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={{
                    mt: -0.5,
                    width: 160,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                {removeMessage}

                <MenuItem sx={{ color: 'error.main' }} onClick={() => setNewJSON(remove(bulklistJSON, index))}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
                    Delete
                </MenuItem>
            </MenuPopover>
        </>
    );
}


