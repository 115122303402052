
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Typography, Stack, Box } from '@mui/material';
// routes

// components
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialButtons';
import Image from '../../components/Image'

// ----------------------------------------------------------------------
const socials = {
    facebook: "https://www.facebook.com/SasaPayKenya",
    twitter: "https://twitter.com/SasaPayKenya",
    instagram: "https://www.instagram.com/sasapaykenya/",
    linkedin: "https://www.linkedin.com/company/sasapay"
}
const LINKS = [
    {
        headline: 'Contact us On Email:',
        children: [
            { name: 'business@viewtech.co.ke' },
        
        ],
    },
    
    {
        headline: 'Call Us On:',
        children: [
            { name: '+255 111 045 400' },
        
        ],
    },
];


const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',

}));

const currentYear = new Date().getFullYear();




export default function MainFooter() {
    return (
        <RootStyle>
            <Grid
                container
                justifyContent={{ xs: 'center', md: 'space-between' }}
                sx={{ textAlign: { xs: 'center', md: 'left' } }}
            >
                <Grid item xs={12} sx={{ my: 3 }}>
                    <Logo disabledLink={true} isLight={true} sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
                </Grid>
                <Grid item xs={8} md={4}>
                    <Typography variant="body1" sx={{ pr: { md: 5 } }}>
                    SasaPay is a mobile money platform which is a product of Paylon Limited, 
                    authorised and regulated by the Bank of Tanzania.
                    </Typography>

                    <Typography variant='h5' sx={{ mt: 5, pr: { md: 5 } }}>Address</Typography>
                    <Typography variant="body1" sx={{ pr: { md: 5 } }}>
                    Alfa House 3rd Floor, Old Bagamoyo Road ,Dar Es Salaam, 
                    Tel: +255 756 790 699 
                    <a href='https://google.com'></a>
                    </Typography>
                    <Stack 
                        direction="row"
                        justifyContent={{ xs: 'center', md: 'flex-start' }}
                        sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
                    >
                        <SocialsButton links={socials} sx={{ mx: 0.5 }} />
                    </Stack>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent="space-evenly">
                        {LINKS.map((list) => (
                            <Stack key={list.headline} spacing={2}>
                                <Typography component="p" variant="body1">
                                    {list.headline}
                                </Typography>
                                {list.children.map((link) => (
                                    <Typography
                                        key={link.name}
                                    >
                                        {link.name}
                                    </Typography>
                                ))}
                            </Stack>
                        ))}
                    </Stack>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Image 
                    src={"https://www.sasapay.co.ke/assets/images/pci-dss.png"} 
                    disabledEffect 
                    sx={{ 
                        borderRadius: 1, 
                        mb: 1, 
                        height: 90,
                        width: 300 
                    }} 
                />
            </Grid>

            </Grid>
            <Box sx={{ display: 'flex', justifyContent: "center" }} >
                <Typography
                    component="p"
                    variant="body2"
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: 'center', md: 'left' },
                    }}
                >
                    © {currentYear}. SasaPay. All rights reserved
                </Typography>
            </Box>
        </RootStyle>
    );
}
