import { combineReducers } from "@reduxjs/toolkit";
import AuthReducer from "../../packages/auth/store/AuthReducer";
import ShopReducer from "../../packages/shops/store/ShopReducer";
import UserReducer from "../../packages/users/store/UserReducer";
import MyBankReducer from "../../packages/myBanks/store/MyBankReducer";
import PaymentTypeReducer from "../../packages/paymentTypes/store/PaymentTypeReducer";
import AccountGroupingReducer from "../../packages/account-grouping/store/AccountGroupingReducer";
import CountriesReducer from "../../shared/reducers/Countries/CountriesReducer";
import ShopTypesReducer from "../../shared/reducers/ShopTypes/ShopTypesReducer";
import BusinessTypesReducer from "../../shared/reducers/BusinessTypes/BusinessTypesReducer";
import IndustriesReducer from "../../shared/reducers/Industries/IndustriesReducer";
import TransactionsStatementReducer from "../../packages/transactionsStatement/store/TransactionsStatementReducer";
import InvoiceReducer from "../../packages/Invoice/store/InvoiceReducer";
import TransferFundReducer from "../../packages/transferFund/store/TransferFundReducer";
import BulkPaymentReducer from "../../packages/bulkPayment/store/BulkpaymentReducer";
import SharedUserReducer from "../../shared/reducers/users/SharedUsersReducer";
import BillNumberReducer from "../../shared/reducers/BillNumber/BillNumberReducer";
import JaziaReducer from "../../packages/jazia/store/JaziaReducer";

export default combineReducers({
    //module reducers
    auth: AuthReducer,
    shop: ShopReducer,
    user: UserReducer,
    banks: MyBankReducer,
    paymentTypes: PaymentTypeReducer,
    accountGroupings: AccountGroupingReducer,
    transactions: TransactionsStatementReducer,
    //shared reducers
    countries: CountriesReducer,
    shopTypes: ShopTypesReducer,
    businessTypes: BusinessTypesReducer,
    industries: IndustriesReducer,
    invoice: InvoiceReducer,
    transferFund: TransferFundReducer,
    bulkpayments: BulkPaymentReducer,
    sharedUser: SharedUserReducer,
    billNumber: BillNumberReducer,
    jazia: JaziaReducer

});
