import {
    API_FAILED,
    API_REQUEST, API_SUCCESS,
    GET_SHOP_TRANSACTIONS,
    GET_SHOP_TRANSACTIONS_API_FAILED,
    GET_SHOP_TRANSACTIONS_API_SUCCESS,
    PUSH_TRANSACTIONS,
    DOWNLOAD_SHOP_TRANSACTIONS,
    DOWNLOAD_SHOP_TRANSACTIONS_API_FAILED,
    DOWNLOAD_SHOP_TRANSACTIONS_API_SUCCESS,
    DOWNLOAD_SHOP_TRANSACTIONS_CLEAR
} from "./TransactionsStatementActionTypes";

const initialState = {
    loading: false,
    message: "",
    error: {},
    pagination: {},
    merchant: {},
    transactions: [],
    controlAccounts: [],
    response: {},
    document_key: "",
    document_url: "",
    download_message: "",
    download_data: ""
}

export default function TransactionsReducer(state = initialState, action) {
    const { type, payload, download_data, download_message, message, loading, error, data } = action;
    switch (type) {
        /**
         * Shops
         */
        case GET_SHOP_TRANSACTIONS:
            return {
                ...state,
                loading,
            };

        case GET_SHOP_TRANSACTIONS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                transactions: payload.transactions,
                data: payload,
                response: data
            }

        case GET_SHOP_TRANSACTIONS_API_FAILED:
            return {
                ...state,
                loading,
                error
            };
        case DOWNLOAD_SHOP_TRANSACTIONS:
            return {
                ...state,
                loading,
            };

        case DOWNLOAD_SHOP_TRANSACTIONS_API_SUCCESS:
            return {
                ...state,
                loading,
                download_message,
                download_data,
                document_url: download_data.document_url,
                document_key: download_data.document_key

            }

        case DOWNLOAD_SHOP_TRANSACTIONS_API_FAILED:
            return {
                ...state,
                download_message,
                download_data,
                loading,
                error
            };


        case PUSH_TRANSACTIONS:
            return {
                ...state,
                loading,
                error
            };
        case API_REQUEST:
            return {
                ...state,
                loading
            }
        case API_SUCCESS:
            return {
                ...state,
                loading,
                controlAccounts: payload?.results?.account_controls
            }
        case API_FAILED:
            return {
                ...state,
                loading,
                error
            }
        case DOWNLOAD_SHOP_TRANSACTIONS_CLEAR:
            return {
                ...state,
                download_message,
                download_data: {
                    document_key: "",
                    document_url: "",
                    download_message: "",
                },
                document_url: "",
                document_key: ""
            }
        default:
            return state
    }
}