import { toast } from "react-toastify";
import {
    API_FAILED,
    API_REQUEST, API_SUCCESS,
    GET_SHOP_TRANSACTIONS,
    GET_SHOP_TRANSACTIONS_API_FAILED,
    GET_SHOP_TRANSACTIONS_API_SUCCESS
} from "./TransactionActionTypes";
import call from "../../../core/services/http";
import TransactionConstants from "./TransactionConstants";
//
const notifyError = msg => {
    toast.error(msg)
}


export const getTransactions = payload => async (dispatch) => {
    try {
        dispatch({
            type: GET_SHOP_TRANSACTIONS,
            loading: true,
        });
        const URL = TransactionConstants.TRANSACTIONS(payload)
        const res = await call("get", URL);
        if (res.data.status) {
            dispatch({
                type: GET_SHOP_TRANSACTIONS_API_SUCCESS,
                payload: res.data.data,
                loading: false,
                data: res.data,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: GET_SHOP_TRANSACTIONS_API_FAILED,
                payload: res.data,
                transactions: [],
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: GET_SHOP_TRANSACTIONS_API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}

export const getControlAccounts = payload => async (dispatch) => {
    try {
        dispatch({
            type: API_REQUEST,
            loading: true,
        });
        const res = await call("get", TransactionConstants.CONTROL_ACCOUNTS(payload));
        if (res.data.status) {
            dispatch({
                type: API_SUCCESS,
                payload: res.data,
                loading: false,
                data: res.data,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}

/**
 * Transfer
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const transferFunds = payload => async (dispatch) => {
    try {
        dispatch({
            type: API_REQUEST,
            loading: true,
        });
        const res = await call("post", TransactionConstants.CONTROL_ACCOUNTS(payload), payload);
        if (res.data.status) {
            dispatch({
                type: API_SUCCESS,
                payload: res.data,
                loading: false,
                data: res.data,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}
/**
 * Send Money
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const sendMoney = payload => async (dispatch) => {
    try {
        dispatch({
            type: API_REQUEST,
            loading: true,
        });

        const res = await call("post", TransactionConstants.SEND_MONEY, payload);
        if (res.data.status) {
            dispatch({
                type: API_SUCCESS,
                payload: res.data,
                loading: false,
                data: res.data,
                message: res.data.message,
            });
        } else {
            dispatch({
                type: API_FAILED,
                payload: res.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}

export const lazyLoadTransactions = (payload, list_) => async (dispatch) => {
    try {
        dispatch({
            type: GET_SHOP_TRANSACTIONS,
            loading: true,
        });
        const URL = TransactionConstants.TRANSACTIONS(payload)
        const res = await call("get", URL);

        if (res.data.status) {
            let transactions = list_
            transactions.push(...res.data.data.transactions)
            res.data.data.transactions = transactions

            dispatch({
                type: GET_SHOP_TRANSACTIONS_API_SUCCESS,
                payload: res.data.data,
                data: res.data,
                loading: false,
                message: res.data.message,
            });
        } else {
            res.data.data.transactions = list_
            dispatch({
                type: GET_SHOP_TRANSACTIONS_API_FAILED,
                payload: res.data.data,
                loading: false,
            });
            notifyError(res.data.message)
        }
    } catch (err) {
        dispatch({
            type: GET_SHOP_TRANSACTIONS_API_FAILED,
            error: err.message,
            loading: false
        });
        notifyError(err.message)
    }
}