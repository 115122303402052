export default {
    TRANSACTIONS: ({ page, page_size, account_number }) => `/transactions/transactions-all/?page=${page}&page_size=${page_size}&account_number=${account_number}`,
    CONTROL_ACCOUNTS: ({ merchantId, savingsAccountId }) => `/merchants/${merchantId}/savings-accounts/${savingsAccountId}/control-account`,
    MERCHANTCODE: 'payments/lipa-bill/search-bill-number/v2/',
    SENDMONEYB2C: `transfers/send-money/`,
    SENDMONEYMB2C: `/transfers/third-party-bill-payments/`,
    SENDMONEYB2B: '/transfers/send-money-b-to-b/',
    PROCESS_JAZIA:(jr_id)=>`/payments/process-jazia-request/${jr_id}/`,
    SENDMONEYWAPIX: '/transfers/send-from-wapix/',
    WAPIXACCOUNT: '/transfers/search-account-number/',
    WAPIXEXCHNGE : '/transfers/exchange-currency/'
}