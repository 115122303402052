import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dollar from "src/packages/shop/view/Dollar";
class ForexDeposit extends Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        return (
     
        <Dollar />
        
        );
    }
}

export default ForexDeposit;