import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import { AuthRoutes } from '../packages/auth/routes/AuthRoutes';
import { DashboardRoutes } from '../packages/dashboard/routes/DashboardRoutes';
import { ShopsRoutes } from '../packages/shops/routes/ShopsRoutes';
import { MyBankRoutes } from '../packages/myBanks/routes/MyBankRoutes';
import { PaymentTypeRoutes } from '../packages/paymentTypes/routes/PaymentTypeRoutes';
import { AccountGroupingRoutes } from '../packages/account-grouping/routes/AccountGroupingRoutes';
import { ShopTransactionRoutes } from '../packages/transactions/routes/ShopTransactionRoutes';
import { ShopRoutes } from '../packages/shop/routes/ShopRoutes';
import { InvoiceRoutes } from '../packages/Invoice/routes/InvoiceRoutes';
import { TransferFundRoutes } from '../packages/transferFund/routes/TransferFundRoutes';
import { BulkPaymentRoutes } from '../packages/bulkPayment/routes/BulkPaymentRoutes';
import { LandingRoutes } from '../packages/landing/routes/LandingRoutes';
import { CommissionRouter } from '../packages/dealer/routes/CommisionRoutes';
import { CashBackRoutes } from '../packages/cashBack/routes/CashBackRoutes';
import { ScheduleSettlementRoutes } from '../packages/scheduleSettlement/routes/ScheduleSettlementRoutes';
import { AutoSettlementRoutes } from '../packages/AutoSettlement/routes/AutoSettlementRoutes';
import { ConfigureRoutes } from '../packages/jaziaProviderConfigure/routes/ConfigureRoutes';
import { ChargesRoutes } from '../packages/chargesStatement/routes/ChargesRoutes';
import { ProviderRoutes } from 'src/packages/jazia/routes/providerRoutes';
import { ForexRoutes } from 'src/packages/forex/routes';


// ----------------------------------------------------------------------
const systemRoutes = [
  ...LandingRoutes,
  ...AuthRoutes,
  ...DashboardRoutes,
  ...ShopsRoutes,
  ...MyBankRoutes,
  ...PaymentTypeRoutes,
  ...AccountGroupingRoutes,
  ...ShopTransactionRoutes,
  ...ShopRoutes,
  ...InvoiceRoutes,
  ...TransferFundRoutes,
  ...BulkPaymentRoutes,
  ...CommissionRouter,
  ...CashBackRoutes,
  ...ScheduleSettlementRoutes,
  ...AutoSettlementRoutes,
  ...ConfigureRoutes,
  ...ChargesRoutes,
  ...ProviderRoutes,
  ...ForexRoutes,  
];

export default function Router() {
  return useRoutes(systemRoutes);
}
