export default {
    LOGIN: "auth/login/",
    VERIFY_OTP: "auth/login/otp/",
    VERIFY_OTP_OPERATORS: "/auth/operators/login/otp/",
    OPERATOR_LOGIN: "/auth/operators/login/",
   // RESET_PASSWORD: "auth/reset/send/",
    RESET_PASSWORD: "auth/password-reset-otp/",
    VERIFY_RESET_OTP: "auth/reset/verify/",
    SET_PASSWORD: "auth/reset/new/",
}
 