import {SEARCH_USER_API_FAILED, SEARCH_USER_API_REQUEST, SEARCH_USER_API_SUCCESS} from "./UserActionTypes";

const initialState={
    loading: false,
    searching: false,
    message: "",
    error: {},
    searchResult: {},
    pagination: {},
    queryMessage:'',
    resultFound: false,
}
export default function UserReducer(state=initialState, action){
    const {type,payload,message,searching,error,resultFound,queryMessage} = action;
    switch (type) {
        /**
         * Shops
         */
        case SEARCH_USER_API_REQUEST:
            return {
                searching,
                resultFound,
                queryMessage
            };
        case SEARCH_USER_API_SUCCESS:
            return {
                searching,
                message,
                searchResult: payload,
                resultFound,
                queryMessage
            }
        case SEARCH_USER_API_FAILED:
            return {
                searching,
                error,
                resultFound,queryMessage

            };
        default:
            return state
    }
}