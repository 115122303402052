// routes
import { PATH_PAGE } from '../../../routes/paths';
// components

// components
import Iconify from '../../../shared/components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
};

const menuConfig = [
    {
        id: 1,
        title: 'Home',
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/',
    },
    {
        id: 2,
        title: 'Register as a Merchant',
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        path: '/auth/register',
    },
    {
        id: 3,
        title: 'Use Cases',
        path: '/use-cases',
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children: [
            {
                subheader: 'SasaPay Use Cases',
                items: [
                    { title: 'Bulk Payment', path: PATH_PAGE.bulkpayment },
                    { title: 'Busines To Customer (B2C)', path: PATH_PAGE.b2c },
                    { title: 'Busines To Business (B2B)', path: PATH_PAGE.b2b },
                    { title: 'Customer To Business (C2B)', path: PATH_PAGE.c2b },

                ],
            },
        ],
    },
    {
        id: 4,
        title: 'Solutions',
        path: '/solutions',
        icon: <Iconify icon={'eva:file-fill'} {...ICON_SIZE} />,
        children1: [
            {
                subheader: 'SasaPay Solutions',
                items: [
                    { title: 'Bulk Payment', path: PATH_PAGE.bulkpayment },
                    { title: 'Link Payment', path: PATH_PAGE.linkpayment },
                    { title: 'SMS Payment', path: PATH_PAGE.smspayment },
                    { title: 'Checkout', path: PATH_PAGE.checkout },
                    // { title: 'Utility Payment', path: PATH_PAGE.utilitypayment },
                    // { title: 'Bill Presentment', path: PATH_PAGE.billpresentment },
                    // { title: 'Mandate Payment', path: PATH_PAGE.mandatepayment },
                    // { title: 'Invoicing', path: PATH_PAGE.invoicing },
                    // { title: 'Escrow', path: PATH_PAGE.escrow },
                    // { title: 'MarketPlace', path: PATH_PAGE.marketplace },
                ],
            },

        ],
    },

];

export default menuConfig;
