/* eslint-disable */
import { Button } from '@mui/material'
import T from 'prop-types'
import { useRef } from 'react'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { fDateTime } from '../utils/formatTime'
import Iconify from './Iconify'


/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const flattenObject = (ob, separator = '_') => {
    // The object which contains the
    // final result
    const result = {};
    // loop through the object "ob"
    for (const i in ob) {
        // We check the type of the i using
        // typeof() function and recursively
        // call the function again
        if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
            const temp = flattenObject(ob[i]);
            for (const j in temp) {
                // Store temp in result
                result[i + separator + j] = temp[j];
            }
        }
        // Else store ob[i] in result directly
        else {
            result[i] = ob[i];
        }
    }
    return result;
};

ExportExcel.propTypes = {
    shape: T.array.isRequired,
    data: T.array.isRequired,
    clickComponent: T.any,
    fileName: T.string.isRequired,
    sheetName: T.string.isRequired,
    showTable: T.bool
}

ExportExcel.defaultProps = {
    shape: [],
    data: [],
    clickComponent: <Button variant='contained' size='small' color='info'><Iconify sx={{ mr: 1 }} icon='mdi:database-export-outline' />Export</Button>,
    fileName: 'ExportExcel',
    sheetName: 'Excel Export Sheet',
    showTable: false
}


/*
SAMPLE SHAPE USED IN TRANSACTIONS TABLE

const exportShape = [
 
    {
        key: 'savings_account',
        label: 'Account Name',
        isObject: true,
        objectName:'description'
    },
    {
        key: 'savings_account',
        label: 'Account Number',
        isObject: true,
        objectName:'account_number'
    },
    {
        key: 'amount',
        label: 'Amount',
        sumColumn: true
    },
    {
        key: 'charge_amount',
        label: 'Charge Amount',
        sumColumn: true
    },
    {
        key: 'commission_amount',
        label: 'Commission_amount Amount',
        sumColumn: true
    },
    {
        key: 'created_date',
        label: 'Created Date',
    },
    {
        key: 'result_code',
        label: 'Health Status Code',
        options: {
            '': 'N/A',
            'SP00000': 'Successful Transaction',
            'SP01001': 'Processing Transaction',
        }
    },
    {
        key: 'result_description',
        label: 'Result Description',
    },
    {
        key: 'description',
        label: 'Description',
    },
    {
        key: 'external_reference',
        label: 'External Reference',
    },

    {
        key: 'is_reversed',
        label: 'Reversed',
        options: {
            0: 'No',
            1: 'Yes',
        }
    },
    {
        key: 'transaction_code',
        label: 'Amount',
    },
    {
        key: 'transaction_date',
        label: 'Date of Transaction',
    },
    // key object in shape with other objects as shape objects
    {
        key: 'party_B',
        label: 'Account Number',
        isObject: true,
        objectName: 'account_number'
    },
    {
        key: 'party_B',
        label: 'Display Name',
        isObject: true,
        objectName: 'display_name',
        // options tested on level 2 and they are working properly
    },

    {
        key: 'payment_detail',
        label: 'Payment Detail',
        isObject: true,
        objectName:'payment_type_value',
        flatten: true,
    },
    {
        key: 'payment_detail',
        label: '3rd Party Transaction Code',
        isObject: true,
        objectName:'third_party_transaction_code',
        flatten: true,
    },
    {
        key: 'external_reference',
        label: 'Reference'
    },
]


DATA SAMPLE

 {
            "id":575321,
            "savings_account":{
               "id":20283,
               "account_number":"20283",
               "description":"KCF624F OMA SERVICES LTD",
               "account_balance_derived":"410.00",
               "status_enum":300,
               "date_created":"2022-11-02 11:54:56.662447+00:00"
            },
            "payment_detail":{
               "id":486848,
               "payment_type":{
                  "id":1,
                  "value":"M-pesa",
                  "description":"Safaricom",
                  "is_cash_payment":1,
                  "order_position":1
               },
               "waas_payment_request":null,
               "deposit_staging":null,
               "account_number":"2547 ***** 474",
               "account_name":null,
               "third_party_transaction_code":null,
               "third_party_provider_name":"",
               "check_number":null,
               "receipt_number":null,
               "bank_number":null,
               "routing_code":null
            },
            "transaction_type_enum":1,
            "is_reversed":0,
            "transaction_date":"2023-04-06",
            "amount":"50.00",
            "charge_amount":"0.00",
            "commission_amount":"0.00",
            "created_date":"2023-04-06T11:03:17+03:00",
            "description":"MPESA-REF-RD60ILNGIY-20283-YOUNG None",
            "transaction_code":"SPCRVTJYFDXG",
            "external_reference":"DEP/MPESA-REF-RD60ILNGIY-20283-YOUNG None",
            "party_B":{
               "display_name":"Transaction In - from M-PESA",
               "account_number":"2547 ***** 474"
            },
            "account_operator":null,
            "result_code":"SP00000",
            "result_description":"Transaction completed successfully"
         },
*/


export function ExportExcel({ clickComponent, shape, data, fileName, sheetName, showTable }) {


    const tableRef = useRef(null);

    return (
        <>
            <DownloadTableExcel
                filename={fileName}
                sheet={sheetName}
                currentTableRef={tableRef.current}>
                {clickComponent}
            </DownloadTableExcel>
            {/* style={{ display: 'none' }} */}
            <table style={{ display: showTable ? '' : 'none' }} ref={tableRef}>
                <tbody>
                    <tr>
                        {shape.map((column, index) => (
                            <th key={index}>{column.label}</th>
                        ))}
                    </tr>
                    {data.map((row, index) => (
                        <tr key={index}>
                            {shape.map((column) => {
                                let options = column.options
                                let isObject = column.isObject || false
                                let flatten = column.flatten || false
                                let optionDisplay = row[column.key]
                                let isDate = column.isDate

                                if (isObject) {
                                    if (flatten) {
                                        const object = flattenObject(row[column.key])
                                        optionDisplay = object[column.objectName]
                                    } else {
                                        optionDisplay = row[column.key] && row[column.key][column.objectName] || 'N/A'
                                    }

                                }

                                if (options) {
                                    Object.keys(options).map((optionKey) => {
                                        if (isObject) {
                                            if (optionKey.toString() === row[column.key][column.objectName].toString()) {
                                                optionDisplay = options[optionKey]
                                            }
                                        } else {
                                            if (optionKey.toString() === row[column.key].toString()) {
                                                optionDisplay = options[optionKey]
                                            }
                                        }
                                    })

                                }

                                return (<td key={index}>{optionDisplay && (isDate && fDateTime(optionDisplay) || (optionDisplay.toString())) || ''}</td>)
                            })}
                        </tr>
                    ))}

                    <tr>
                        {shape.map((column, index) => {
                            let sumColumn = column.sumColumn || false

                            if (sumColumn) {
                                return (<td key={index}>{data.reduce((initial, item) => {
                                    return parseFloat(item[column.key]) + initial
                                }, 0)}</td>)
                            }
                            return (<td key={index}></td>)
                        })}
                    </tr>

                </tbody>
            </table>
        </>

    )
}