export const GET_JAZIA_SUBSCRIBERS = "SHOP/GET_JAZIA_SUBSCRIBERS";
export const GET_JAZIA_SUBSCRIBERS_API_SUCCESS = "SHOP/GET_JAZIA_SUBSCRIBERS_API_SUCCESS";
export const GET_JAZIA_SUBSCRIBERS_API_FAILED = "SHOP/GET_JAZIA_SUBSCRIBERS_API_FAILED";
export const GET_SUBSCRIPTIONS = "SHOP/GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_API_SUCCESS = "SHOP/GET_SUBSCRIPTIONS_API_SUCCESS";
export const GET_SUBSCRIPTIONS_API_FAILED = "SHOP/GET_SUBSCRIPTIONS_API_FAILED";
export const GET_SPONSORS = "SHOP/GET_SPONSORS";
export const GET_SPONSORS_API_SUCCESS = "SHOP/GET_SPONSORS_API_SUCCESS";
export const GET_SPONSORS_API_FAILED = "SHOP/GET_SPONSORS_API_FAILED";
export const GET_CONFIGURATIONS = "SHOP/GET_CONFIGURATIONS";
export const GET_CONFIGURATIONS_API_SUCCESS = "SHOP/GET_CONFIGURATIONS_API_SUCCESS";
export const GET_CONFIGURATIONS_API_FAILED = "SHOP/GET_CONFIGURATIONS_API_FAILED";
export const GET_PROVIDERS = "SHOP/GET_PROVIDERS";
export const GET_PROVIDERS_API_SUCCESS = "SHOP/GET_PROVIDERS_API_SUCCESS";
export const GET_PROVIDERS_API_FAILED = "SHOP/GET_PROVIDERS_API_FAILED";
export const GET_LOANS = "SHOP/GET_LOANS";
export const GET_LOANS_API_SUCCESS = "SHOP/GET_LOANS_API_SUCCESS";
export const GET_LOANS_API_FAILED = "SHOP/GET_LOANS_API_FAILED";
export const GET_JAZIA_CONSUMER_LOANS = "SHOP/GET_JAZIA_CONSUMER_LOANS";
export const GET_JAZIA_CONSUMER_LOANS_API_SUCCESS = "SHOP/GET_JAZIA_CONSUMER_LOANS_API_SUCCESS";
export const GET_JAZIA_CONSUMER_LOANS_API_FAILED = "SHOP/GET_JAZIA_CONSUMER_LOANS_API_FAILED";
export const GET_REPAYMENT_SCHEDULE = "SHOP/GET_REPAYMENT_SCHEDULE";
export const GET_REPAYMENT_SCHEDULE_API_SUCCESS = "SHOP/GET_REPAYMENT_SCHEDULE_API_SUCCESS";
export const GET_REPAYMENT_SCHEDULE_API_FAILED = "SHOP/GET_REPAYMENT_SCHEDULE_API_FAILED";
export const GET_JAZIA_TYPES = "SHOP/GET_JAZIA_TYPES";
export const GET_JAZIA_TYPES_API_SUCCESS = "SHOP/GET_JAZIA_TYPES_API_SUCCESS";
export const GET_JAZIA_TYPES_API_FAILED = "SHOP/GET_JAZIA_TYPES_API_FAILED";
export const GET_DASHBOARD_API_SUCCESS = "SHOP/GET_DASHBOARD_API_SUCCESS";
export const GET_DASHBOARD_API_FAILED = "SHOP/GET_DASHBOARD_API_FAILED";
export const GET_STATS = "SHOP/GET_STATS";
export const GET_STATS_API_SUCCESS = "SHOP/GET_STATS_API_SUCCESS";
export const GET_STATS_API_FAILED = "SHOP/GET_STATS_API_FAILED";
export const GET_SPONSOR_DASHBOARD_API_SUCCESS = "SHOP/GET_SPONSOR_DASHBOARD_API_SUCCESS";
export const GET_SPONSOR_DASHBOARD_API_FAILED = "SHOP/GET_SPONSOR_DASHBOARD_API_FAILED";
export const GET_SUBSCRIBED_API_SUCCESS = "SHOP/GET_SUBSCRIBED_API_SUCCESS";
export const GET_SUBSCRIBED_API_FAILED = "SHOP/GET_SUBSCRIBED_API_FAILED";



