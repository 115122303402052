import { Box, Typography,  Grid,  Tab } from '@mui/material';
import React from 'react'

import PropTypes from 'prop-types';

import LocalTransfers from './LocalTransfers';
import { CenteredTabs } from 'src/shared/components/global';
import InternationalTransfers from './InternationalTransfers';
import WapiX from './WapiX';

function SendMoney() {
    const [value, setTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
            <Grid container>
                <Grid item sm={2} md={2} />
                <Grid item sm={8} md={8} xs={12} >
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <CenteredTabs value={value} sx={{ margin: "auto" }} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Local Transfers" {...a11yProps(0)} />
                            {/* <Tab label="International Transfers" {...a11yProps(1)} />
                            <Tab label="WAPI X" {...a11yProps(2)} /> */}
                        </CenteredTabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <LocalTransfers />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <InternationalTransfers />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <WapiX />
                    </TabPanel>
                </Grid>
                <Grid item sm={2} md={2} />
            </Grid>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default SendMoney