import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const JaziaSponsornProvider = [
    {

        title: 'Jazia Sponsor',
        path: '/shop-dashboard/savings-account/jazia-sponsored-loans',
        icon: getIcon("bi:wallet-fill"),
    },
    {

        title: 'Jazia Provider',
        path: 'savings-account/jazia/JaziaProviderNew/JaziaProviders',
        icon: getIcon("healthicons:provider-fst-negative"),
    },
]


