import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Button,
    CardContent,
    CardHeader,
    Grid,
    TablePagination,
    Typography,
    Card,
    Stack,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    Snackbar,
    Skeleton,
    Alert,
    Container,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
    Backdrop,
    Tabs,
    Tab,
    InputLabel
} from '@mui/material';
import call from 'src/core/services/http';
import { useCallback } from 'react';
import { fDateDMY } from 'src/shared/utils/formatTime';
import dayjs from 'dayjs';
// import { DatePicker } from '@mui/x-date-pickers';
import IconButton from '@mui/material/IconButton';
import randomColor from 'randomcolor'
import PropTypes from 'prop-types'

import { useEffect, useState } from 'react';
import Iconify from '../../../shared/components/Iconify';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import TransactionsStatementTable from '../components/TransactionsStatementTable';
import { LoadingButton } from '@mui/lab';
import Page from 'src/shared/components/Page';
import useIsMountedRef from 'use-is-mounted-ref';
import { fNumber } from 'src/shared/utils/formatNumber';
import AuthService from 'src/core/access-control/AuthService';
import SpinnerLoader from 'src/shared/plugin/loader/SpinnerLoader';
import { toast } from 'react-toastify';
import { capitalCase } from 'change-case';
import { styled } from '@mui/material/styles';
import useTabs from './../../../shared/hooks/useTabs';
import Reports from './Reports';
import { setHours, setMinutes } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import "../../../style.css"




const TabsWrapperStyle = styled('div')(({ theme }) => ({
    zIndex: 9,
    bottom: 0,
    width: '100%',
    // display: 'flex',
    // position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-end',
        paddingRight: theme.spacing(3),
    },
}));


const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function CustomDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Iconify
                        icon="eva:close-fill"
                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

CustomDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const transactionsHealth = [
    {
        key: "All Transactions",
        value: "0"
    },
    {
        key: "Successful Transactions",
        value: "SP00000"
    },
    {
        key: "Processing Transactions",
        value: "SP01001"
    },
    {
        key: "Failed Transactions",
        value: "SP01002"
    },
    {
        key: 'Reversed Transactions',
        value: 'SP01003'
    },
    {
        key: 'Under Review Transactions',
        value: 'SP01004'
    }
]



const exportShape = [
    {
        key: 'id',
        label: 'SNo#',
    },
    {
        key: 'savings_account',
        label: 'Account Number',
        isObject: true,
        objectName: 'account_number'
    },
    {
        key: 'savings_account',
        label: 'Account Name',
        isObject: true,
        objectName: 'description'
    },
    {
        key: 'savings_account',
        label: 'Account Balance Derived',
        isObject: true,
        objectName: 'account_balance_derived'
    },
    {
        key: 'is_reversed',
        label: 'Reversal Status',
        options: {
            0: 'Not Reversed',
            1: 'Reversed'
        }
    },
    {
        key: 'transaction_type_enum',
        label: 'Transaction Type',
        options: {
            2: 'Out',
            1: 'In'
        }
    },
    {
        key: 'transaction_date',
        label: 'Transaction Date',
    },
    {
        key: 'amount',
        label: 'Transaction Amount',
    },
    {
        key: 'running_balance_derived',
        label: 'Transaction Running Balance',
    },
    {
        key: 'description',
        label: 'Transaction Description',
    },
    {
        key: 'external_reference',
        label: 'Transaction External Reference',
    },
    {
        key: 'transaction_code',
        label: 'Transaction Code',
    },
    {
        key: 'party_B',
        label: 'Account Number',
        isObject: true,
        objectName: 'account_number'
    },
    {
        key: 'party_B',
        label: 'Display Name',
        isObject: true,
        objectName: 'display_name',
        // options tested on level 2 and they are working properly
    },
    {
        key: 'result_code',
        label: 'Transaction Result Code',
        options: {
            '': 'N/A',
            'SP00000': 'Successful Transaction',
            'SP01001': 'Processing Transaction',
            'SP01002': 'Failed Transaction',
            'SP01003': 'Reversed Transaction',
            'SP01004': 'Transaction Under Review',
        }
    },
]

export default function TransactionsStatement({ back }) {
    const isMountedRef = useIsMountedRef()
    const [state, setState] = useState({
        openNote: false,
        vertical: 'top',
        horizontal: 'center',
        severity: 'error',
        message: ''
    });

    const { vertical, horizontal, severity, openNote, message, codes } = state;
    const [shop] = useLocalStorage('shop-details')
    const [transaction_code, setTransactionCode] = useState('')
    const [mobile_number, setMobileNumber] = useState('')
    const [page, setPage] = useState(1)
    const [loading, setFetching] = useState(false)
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [transactions, setTransactions] = useState({})
    const [queryTransactions, setQueryTransactions] = useState({})
    const [filter, setViewFilter] = useState(false)
    const [filterMode, setFilterMode] = useState(false)
    // const [startDate, setStartDate] = useState(new Date(dayjs(`${new Date()}`))); //.getFullYear()}-${new Date().getMonth()}-${new Date().getDate()
    const [endDate, setEndDate] = useState(new Date(dayjs(`${new Date()}`)));//.getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}
    const url = `/transactions/all-transactions/`
    const [showButton, setShowButton] = useState(false);
    const [value, setValue] = useState("");


    // const [hours, setHours] = useState();
    // const [minutes, setMinutes] = useState();

    const [startDate, setStartDate] = useState(
        setHours(setMinutes(new Date(), 0), 9),
    );

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };


    const getTransactions = useCallback(async () => {
        if (isMountedRef.current) {
            await getData(`${url}?page=${page}&page_size=${rowsPerPage}&account_number=${shop.account_number}`)
            getDashboard()
        }
    }, [isMountedRef])

    useEffect(() => {
        getTransactions()
    }, [getTransactions])

    const [dashboard, setDashboard] = useState({
        currency: "",
        total_money_in: 0,
        total_money_out: 0,
        total_shop_account_balance: 0
    })

    const getDashboard = async (from = '', to = '') => {
        // alert(from)
        const today = fDateDMY(new Date(dayjs(`${new Date()}`)))

        if (from == '') {
            from = today
        }
        if (to == '') {
            to = today
        }

        const query = `merchants/${AuthService.getUserId()}/shop/dashboard/?shop_id=${shop.id}&start_date=${from}&end_date=${to}`

        // console.log(query);
        const request = await call('get', query)
        setDashboard(request.data)
    }

    const [downloadedInfo, setDownloadedInfo] = useState({ document_url: '#' })

    const renderFilter = async (e, page = 1, rowsPerPage = 5) => {
        // setState({ ...state, openNote: true, message: 'Querying in filter mode' });
        setIsFiltering(true)
        await getBlob()
        await getData(`${url}?start_date=${fDateDMY(startDate)}&end_date=${fDateDMY(endDate)}&account_number=${shop.account_number}&page=${page}&page_size=${rowsPerPage}${mobile_number != "" && '&mobile_number=' + mobile_number || ''}${transaction_code != "" && '&transaction_code=' + transaction_code || ''}${transactionStatus != "0" && '&transaction_status=' + transactionStatus || ''}`)
        setFilterMode(true)
        setIsFiltering(false)
        setViewFilter(false)
    }

    const handleChangePage = async (event, newPage) => {
        if (filterMode) {
            await renderFilter(event, newPage, rowsPerPage)
        } else {
            await getData(`${url}?page=${newPage}&page_size=${rowsPerPage}&account_number=${shop.account_number}`, true)
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));

        if (filterMode) {
            await renderFilter(event, 1, event.target.value)
        } else {
            await getData(`${url}?page=${1}&page_size=${parseInt(event.target.value, 10)}&account_number=${shop.account_number}`, true)
        }

        setPage(1);
    };

    async function getData(url, isPaginating = false) {
        setFetching(true)
        getDashboard(fDateDMY(startDate), fDateDMY(endDate))

        const response = await call("get", url);

        if (!isPaginating && response.status) {
            setDownloadedInfo({ document_url: '#' })
        } else {
            setFetching(false)
        }

        if (filterMode) {
            setQueryTransactions(response.data)
        } else {
            setTransactions(response.data)
        }
    }

    const toggleOpen = (e) => {
        setViewFilter(!filter)
    }

    const handleStartChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndChange = (newValue) => {
        setEndDate(newValue);
    };

    const handleClose = () => {
        setState({ ...state, openNote: false });
    };

    const [transactionStatus, setTransactionStatus] = useState("0")

    const Paginator = (
        <>
            {loading && <>
                <Skeleton animation='pulse' variant="rectangular" height={10} />
                <Skeleton animation='pulse' variant="rectangular" height={10} />
                <Skeleton animation='pulse' variant="rectangular" height={10} />
                <Skeleton animation='pulse' variant="rectangular" height={10} />
                <Skeleton animation='pulse' variant="rectangular" height={10} />
                <Skeleton animation='pulse' variant="rectangular" height={10} />
            </>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                component="div"
                count={filterMode ? queryTransactions.count : transactions.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )

    const [filtering, setIsFiltering] = useState(false)
    const [downloading, setIsDownloading] = useState(false)


    async function getBlob() {

    }

    const downloadTransactions = async () => {
        try {
            setIsDownloading(true)
            const request = await call('get', `/transactions/download/?start_date=${fDateDMY(startDate)}&end_date=${fDateDMY(endDate)}&account_number=${shop.account_number}&page=${page}&page_size=${rowsPerPage}${mobile_number != "" && '&mobile_number=' + mobile_number || ''}${transaction_code != "" && '&transaction_code=' + transaction_code || ''}${transactionStatus != "0" && '&transaction_status=' + transactionStatus || ''}`)
            const response = request.data

            const blob = new Blob([response], { type: 'text/csv' });

            if (blob.size < 20) {

                setIsDownloading(false)
                return toast.success(response.message)
            }
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            // eslint-disable-next-line no-useless-concat
            let name = `start_date=${fDateDMY(startDate)}_end_date=${fDateDMY(endDate)}_account_number=${shop.account_number}_page=${page}_page_size=${rowsPerPage}${mobile_number != "" && '_mobile_number=' + mobile_number || ''}${transaction_code != "" && '_transaction_code=' + transaction_code || ''}${transactionStatus != "0" && '&transaction_status=' + transactionStatus || ''}_transactions`
            link.download = name + '.csv';
            link.click();
            // Clean up the temporary objects
            window.URL.revokeObjectURL(link);
            link.remove();
            setIsDownloading(false)
        } catch (error) {
        }
        setFetching(false)
    }

    const items = filterMode ? queryTransactions.data && queryTransactions.data.transactions || [] : transactions.data && transactions.data.transactions || []
    const reload = (event) => {
        const _ = filterMode ? renderFilter(event, page, rowsPerPage) : getData(`${url}?page=${page}&page_size=${rowsPerPage}&account_number=${shop && shop?.account_number}`)
    }

    const { currentTab, onChangeTab } = useTabs('transactions');
    const TABS = [
        {
            value: 'transactions',
            icon: <Iconify icon={'ci:list-unordered'} width={20} height={20} />,
            component: <TransactionComponent />
        },
        {
            value: 'Reports Download',
            icon: <Iconify icon={'ri:cloud-fill'} width={20} height={20} />,
            component: <Reports />,
        }
    ];


    function TransactionComponent() {
        return (
            <>
                <Box sx={{ p: 2 }} >
                    <Stack sx={{ float: 'right' }} spacing={1} direction='row'>
                        <LoadingButton
                            loading={downloading} onClick={() => downloadTransactions()}
                            variant='contained' size='small' color='info'>
                            <Iconify
                                icon="material-symbols:cloud-download"
                                sx={{ width: 20, height: 20, mr: 1 }} />Download</LoadingButton>

                        <Button onClick={(event) => filterMode ? renderFilter(event, page, rowsPerPage) : getData(`${url}?page=${page}&page_size=${rowsPerPage}&account_number=${shop && shop?.account_number}`)} variant='contained' size='small' color='warning'>Refresh</Button>
                        {filterMode && <Button onClick={toggleOpen} variant='contained' size='small' color='secondary'>Filter</Button>}
                        <Button onClick={() => {
                            if (filterMode) {
                                getData(`${url}?page=${1}&page_size=${5}`)
                                setMobileNumber("")
                                setTransactionCode("")
                                setTransactionStatus("0")
                                setStartDate(new Date(dayjs(`${new Date()}`)))
                                setEndDate(new Date(dayjs(`${new Date()}`)))
                                getDashboard()
                                // setState({ ...state, openNote: true, severity: 'success', message: 'Filter mode turned off' });
                            } else {
                                setPage(1)
                                setRowsPerPage(5)
                                setState({ ...state, openNote: true, severity: 'error', message: 'You are now in filter mode and all results will be guided by your filtering criteria!' });
                                toggleOpen()
                            }
                            setFilterMode(!filterMode)
                        }} variant='contained' size='small' color={filterMode ? 'success' : 'error'}>{filterMode && 'All Transactions' || 'Filter Transactions'}</Button>
                    </Stack>
                </Box>
                <Stack>
                    {filterMode && <Stack sx={{ px: 2, mt: 5 }} spacing={1} direction='row'>
                        <TextField sx={{ display: { xs: 'none', sm: 'block' } }} disabled value={fDateDMY(startDate)} label='Filtering From' />
                        <TextField sx={{ display: { xs: 'none', sm: 'block' } }} disabled value={fDateDMY(endDate)} label='Filtering To' />
                        <TextField sx={{ display: { xs: 'none', sm: 'block' } }} disabled value={mobile_number} label='Filtering Mobile' />
                        <TextField sx={{ display: { xs: 'none', sm: 'block' } }} disabled value={transaction_code} label='Filtering Transaction' />
                    </Stack>}
                    <CardHeader title={'Transactions'} subheader={'SasaPay Transactions'} />
                </Stack>
                <CardContent>
                    {items.length > 50 && Paginator}
                    <TransactionsStatementTable
                        headers={[
                            { id: 1, key: 'account', label: '' },
                            { id: 0, key: 'account', label: '' },
                            { id: 7, key: 'account', label: 'Account' },
                            { id: 2, key: 'amount', label: 'Amount' },
                            { id: 4, key: 'created_on', label: 'Initiation Time' },
                            { id: 5, key: 'transaction_code', label: 'Transaction Code' },
                            { id: 6, key: 'status', label: 'Status' },
                        ]}
                        items={items}
                        reload={reload}
                        isMobileView={back}
                    />
                    {Paginator}
                </CardContent>
            </>
        )
    }

    const ref = React.createRef()


    return (
        <Page title="Transactions Statement">

            <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={openNote} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

            <Container maxWidth='xl'>
                {/* {JSON.stringify(items[0])} */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                            <Box sx={{ mb: 0.5 }}> <Iconify icon={'tabler:arrows-transfer-up'} color={randomColor()} width={32} /></Box>

                            <Typography variant="h6">{fNumber(transactions.count || 0)}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Total Transactions
                            </Typography>
                        </Paper>
                    </Grid>

                    {filterMode && <Grid item xs={12} md={4} lg={4}>
                        <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                            <Box sx={{ mb: 0.5 }}> <Iconify icon={'ph:sort-ascending-fill'} color={randomColor()} width={32} /></Box>

                            <Typography variant="h6">{fNumber(queryTransactions.count || 0)}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Filtered Transactions
                            </Typography>
                        </Paper>
                    </Grid>}

                    {mobile_number == '' && transaction_code == '' && transactionStatus == '0' && <Grid item xs={12} md={4} lg={4}>
                        <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                            <Box sx={{ mb: 0.5 }}> <Iconify icon={'ic:twotone-call-received'} color={randomColor()} width={32} /></Box>

                            <Typography variant="h6">{fNumber(dashboard.total_money_in || 0)}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Total Money In
                            </Typography>
                        </Paper>
                    </Grid>}

                    {mobile_number == '' && transaction_code == '' && transactionStatus == '0' && <Grid item xs={12} md={4} lg={4}>
                        <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
                            <Box sx={{ mb: 0.5 }}> <Iconify icon={'ph:arrow-square-out-bold'} color={randomColor()} width={32} /></Box>

                            <Typography variant="h6">{fNumber(dashboard.total_money_out || 0)}</Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                Total Money Out
                            </Typography>
                        </Paper>
                    </Grid>}

                    {/* <TransactionComponent /> */}

                    <TabsWrapperStyle>
                        <Tabs
                            allowScrollButtonsMobile
                            variant="scrollable"
                            scrollButtons="auto"
                            value={currentTab}

                            onChange={onChangeTab}
                        >
                            {TABS.map((tab) => (
                                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} iconPosition='start' label={capitalCase(tab.value)} />
                            ))}
                        </Tabs>
                    </TabsWrapperStyle>
                    {TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched &&
                            <Grid item xs={12} key={tab.value} md={12} lg={12}>
                                <Card>
                                    {tab.component}
                                </Card>
                            </Grid>
                    })}
                </Grid>
            </Container>


            <CustomDialog
                onClose={() => setViewFilter(false)}
                maxWidth='md'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={filter}
            >
                <CustomDialogTitle id="customized-dialog-title" onClose={toggleOpen}>
                    Filter Transactions
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Stack spacing={3}>
                        <Stack spacing={1}>
                            <Typography variant='button'>Transaction Status</Typography>

                            <RadioGroup
                                row
                                aria-labelledby="success_option"
                                name="success_option"
                                onChange={(e) => {
                                    setTransactionStatus(e.target.value)
                                }}
                                value={transactionStatus}
                                defaultValue='0'
                            >
                                <Stack direction={'row'}>
                                    {
                                        transactionsHealth.map(({ key, value }, index) => <FormControlLabel key={index} value={value} control={<Radio />} label={<Typography variant='body2'>{key}</Typography>} />)
                                    }
                                </Stack>
                            </RadioGroup>
                        </Stack>
                        <TextField value={transaction_code} onChange={(e) => setTransactionCode(e.target.value)} label='Transaction Code' />
                        <TextField value={mobile_number} onChange={(e) => setMobileNumber(e.target.value)} label='Mobile Number' />
                        <Grid spacing={2} container direction='row'>
                        <Grid item xs={12} sm={6} md={6} lg={6}>

                            <InputLabel htmlFor="start-date-picker">Start Date</InputLabel>
                            <DatePicker
                            id="start-date-picker"
                            className='custom-date-picker'
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            timeInputLabel="Time:"
                            showTimeInput
                            withPortal
                            dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </Grid >
                        <Grid item xs={12} sm={6} md={6} lg={6}>

                            <InputLabel htmlFor="end-date-picker">End Date</InputLabel>
                            <DatePicker
                            id="end-date-picker"
                            className='custom-date-picker'
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            timeInputLabel="Time:"
                            showTimeInput
                            withPortal
                            dateFormat="MM/dd/yyyy h:mm aa"
                            />
                        </Grid >
                        </Grid>
                      

                        <LoadingButton onClick={renderFilter} disabled={filtering} color='info' variant='contained' size='small'>{filtering ? 'Fetching...' : 'Filter'}</LoadingButton>
                    </Stack>


                </DialogContent>

            </CustomDialog>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={downloading} >
                <SpinnerLoader text={'Please wait...'} />
            </Backdrop>
        </Page >
    );
}
