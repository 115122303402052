import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import ShopLayout from "../../../shared/layouts/shopLayout";
import InternalFundMovement from "../components/InternalFundMovement";
import B2C from "../components/SendMoney/B2C";
import B2B from "../components/SendMoney/B2B";
import SendMoney from "../components/SendMoney";
import Operators from "../../users/view/Operators";

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const ShopTransactions = Loadable(lazy(() => import('../view/ShopTransactions')));

export const ShopTransactionRoutes = [
    // Dashboard Routes

    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
                <ShopLayout />
            </AuthGuard>
        ),
        children: [
            { path: 'savings-account/internal-fund-movement', element: <InternalFundMovement /> },
            { path: 'savings-account/send-money', element: <SendMoney /> },
            { path: 'savings-account/send-money-b2c', element: <B2C /> },
            { path: 'savings-account/transactions', element: <ShopTransactions /> },
            { path: 'savings-account/send-money-b2b', element: <B2B /> },
            { path: 'savings-account/users/operators', element: <Operators /> },
        ],
    },
];


