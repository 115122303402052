// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, CardContent, Container } from '@mui/material';
import { ToastContainer } from "react-toastify";

// components
import Page from '../../../shared/components/Page';
// sections
import { LoginForm } from '../../../shared/sections/auth/login';

// ----------------------------------------------------------------------


const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));



const ContentStyle = styled(Box)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {



  return (

    <Box title="Login">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
      <RootStyle
        sx={{
          backgroundImage: `url("https://assets-global.website-files.com/6285dac1a83c4a57ea661389/630461373bf80fc3a698e5b3_globe.png")`
        }}
      >
        <Container maxWidth="sm">
          <ContentStyle>
            <Card>
              <CardContent>
                <LoginForm />
              </CardContent>
            </Card>
          </ContentStyle>
        </Container>
        <HeaderStyle>

        </HeaderStyle>

      </RootStyle>
    </Box>
  );
}
