import { appName } from "../environment/environment";
import jwtDecode from "jwt-decode";

class AuthService {
    constructor() {
        this.token = window.localStorage.getItem(appName + "_token");
        this.user = JSON.parse(window.localStorage.getItem(appName + "_user"));
        this.mobile_number = this.user ? this.user.mobile_number : null
    }
    decodeJWT(token) {
        return jwtDecode(token);
    }
    check() {
        return !!this.token;
    }
    getUserId() {
        return this.user.id
    }

    login(token, user) {
        const decoded = this.decodeJWT(token)
        window.localStorage.setItem(appName + "_token", token);
        window.localStorage.setItem(appName + "_user", JSON.stringify(decoded?.merchant));
        this.token = token;
        this.user = user;
    }
    logout() {
        window.localStorage.removeItem(appName + "_token");
        window.localStorage.removeItem(appName + "_user");
        window.location.href = "/auth/login"
    }
    store(key, value) {
        window.localStorage.setItem(appName + key, JSON.stringify(value));
    }
    remove(key) {
        window.localStorage.removeItem(appName + key)
    }
    lvalue(key) {
        let item = window.localStorage.getItem(appName + key);
        return JSON.parse(item)
    }
}

export default new AuthService();
