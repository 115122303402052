import {
    GET_JAZIA_SUBSCRIBERS,
    GET_JAZIA_SUBSCRIBERS_API_FAILED,
    GET_JAZIA_SUBSCRIBERS_API_SUCCESS,
    GET_SUBSCRIPTIONS,
    GET_SUBSCRIPTIONS_API_FAILED,
    GET_SUBSCRIPTIONS_API_SUCCESS,
    GET_SPONSORS,
    GET_SPONSORS_API_FAILED,
    GET_SPONSORS_API_SUCCESS,
    GET_CONFIGURATIONS,
    GET_CONFIGURATIONS_API_FAILED,
    GET_CONFIGURATIONS_API_SUCCESS,
    GET_LOANS,
    GET_LOANS_API_FAILED,
    GET_LOANS_API_SUCCESS,
    GET_REPAYMENT_SCHEDULE,
    GET_REPAYMENT_SCHEDULE_API_FAILED,
    GET_REPAYMENT_SCHEDULE_API_SUCCESS,
    GET_JAZIA_TYPES,
    GET_JAZIA_TYPES_API_FAILED,
    GET_JAZIA_TYPES_API_SUCCESS,
    GET_STATS,
    GET_STATS_API_FAILED,
    GET_STATS_API_SUCCESS,
    GET_DASHBOARD_API_SUCCESS,
    GET_SPONSOR_DASHBOARD_API_SUCCESS,
    GET_SPONSOR_DASHBOARD_API_FAILED,
    GET_SUBSCRIBED_API_SUCCESS,
    GET_SUBSCRIBED_API_FAILED,
    GET_PROVIDERS,
    GET_PROVIDERS_API_FAILED,
    GET_PROVIDERS_API_SUCCESS,
    GET_JAZIA_CONSUMER_LOANS,
    GET_JAZIA_CONSUMER_LOANS_API_FAILED,
    GET_JAZIA_CONSUMER_LOANS_API_SUCCESS,

} from "./JaziaActionTypes";

const initialState = {
    loading: false,
    message: "",
    my_subscribers: [],
    subscriptions: [],
    subscribers_available_for_enrollment: [],
    error: {},
    response: {},
    jazia_sponsors: [],
    jazia_providers: [],
    loans: [],

    dashboard: {},
    sponsor_dashboard: { all_members: 0, active_loans: 0, fully_paid_loans: 0, overdue_loans: 0 },
    subscribed: [],
    jazia_settings:[],
    consumer_loans: [],
    loading_jazia: false
}
export default function JaziaReducer(state = initialState, action) {
    const { type, payload, message, loading, loading_jazia, error, data } = action;
    switch (type) {
        case GET_JAZIA_SUBSCRIBERS:
            return {
                ...state,
                loading,
            };
        case GET_JAZIA_SUBSCRIBERS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                data: payload.data,
                count: payload.count, 
                pages: payload.pages
            }
        case GET_JAZIA_SUBSCRIBERS_API_FAILED:
            return {
                ...state,
                loading,
                error
            };
        case GET_SUBSCRIPTIONS:
            return {
                ...state,
                loading,
            };
        case GET_SUBSCRIPTIONS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                subscriptions: payload.subscriptions,
                data: data,
                response: data
            }
        case GET_SUBSCRIPTIONS_API_FAILED:
            return {
                ...state,
                loading,
                error
            }
        case GET_SPONSOR_DASHBOARD_API_FAILED:
            return {
                ...state,
                loading,
                sponsor_dashboard: payload.sponsor_dashboard,
                error
            }
        case GET_SUBSCRIBED_API_SUCCESS:
            return {
                ...state,
                subscribed: payload.subscriptions,
                jazia_settings: payload.jazia_settings,
                loading,
                error
            }
        case GET_SUBSCRIBED_API_FAILED:
            return {
                ...state,
                loading,
                subscribed: payload.subscriptions,
                error
            }
        case GET_PROVIDERS:
            return {
                ...state,
                loading,
            }
        case GET_PROVIDERS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                jazia_providers: payload.jazia_providers,
            }
        case GET_PROVIDERS_API_FAILED:
            return {
                ...state,
                loading,
                error
            }
        case GET_SPONSORS:
            return {
                ...state,
                loading,
            }
        case GET_SPONSORS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                jazia_sponsors: payload.jazia_sponsors,
            }
        case GET_SPONSORS_API_FAILED:
            return {
                ...state,
                loading,
                error
            }

            case GET_CONFIGURATIONS:
                return {
                    ...state,
                    loading,
                }
            case GET_CONFIGURATIONS_API_SUCCESS:
                return {
                    ...state,
                    loading,
                    message,
                    jazia_configurations: payload.jazia_configurations,
                }
            case GET_CONFIGURATIONS_API_FAILED:
                return {
                    ...state,
                    loading,
                    error
                }

        case GET_DASHBOARD_API_SUCCESS:
            return {
                ...state,
                dashboard: payload.dashboard_data,
            }
        case GET_SPONSOR_DASHBOARD_API_SUCCESS:
            return {
                ...state,
                sponsor_dashboard: payload.dashboard_data,
            }
        case GET_LOANS:
            return {
                ...state,
                loading,
            }
        case GET_LOANS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                loans: payload,
            }
        case GET_JAZIA_CONSUMER_LOANS:
            return {
                ...state,
                loading_jazia,
            }
        case GET_JAZIA_CONSUMER_LOANS_API_SUCCESS:
            return {
                ...state,
                loading_jazia,
                message,
                consumer_loans: payload.results,
            }
        case GET_JAZIA_CONSUMER_LOANS_API_FAILED:
            return {
                ...state,
                loading_jazia,
                error
            };
            case GET_STATS:
            return {
                ...state,
                loading,
            };
        case GET_STATS_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                stats: payload,
            }
        case GET_STATS_API_FAILED:
            return {
                ...state,
                loading,
                error
            }; 
        case GET_REPAYMENT_SCHEDULE:
            return {
                ...state,
                loading,
            };
        case GET_REPAYMENT_SCHEDULE_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                loans: payload,
            }
        case GET_REPAYMENT_SCHEDULE_API_FAILED:
            return {
                ...state,
                loading,
                error
            };
        case GET_JAZIA_TYPES:
            return {
                ...state,
                loading,
            };
        case GET_JAZIA_TYPES_API_SUCCESS:
            return {
                ...state,
                loading,
                message,
                jazia_types: payload.jazia_types,
            }
        case GET_JAZIA_TYPES_API_FAILED:
            return {
                ...state,
                loading,
                error
            };
        default:
            return state
    }
}