import {INDUSTRIES_API_FAILED, INDUSTRIES_API_REQUEST, INDUSTRIES_API_SUCCESS} from "./IndustriesActionTypes";

const initialState={
    loading: false,
    message: "",
    countries: [],
    error: {},
}
export default function IndustriesReducer(state=initialState, action){
    //
    const {type,payload,message,loading,error} = action;
    switch (type) {
        case INDUSTRIES_API_REQUEST:
            return {
                loading,
            }
        case INDUSTRIES_API_SUCCESS:
            return {
                loading,
                industries: payload.industries,
                message
            }
        case INDUSTRIES_API_FAILED:
            return {
                loading,
                message,
                error
            }
        default:
            return state
    }
}