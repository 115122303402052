import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableContainer,
    Dialog,
    DialogTitle,
    DialogContent,
    Stack,
    Slide,
    Backdrop,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    CardHeader,
    Typography,
    Container
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import Scrollbar from "src/shared/components/Scrollbar";
import Iconify from "src/shared/components/Iconify";
import { formatDate } from "../../../shared/utils/helpers/helpers";
import Page from "../../../shared/components/Page";
import AuthService from "../../../core/access-control/AuthService";
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import { getOperators } from "../../../shared/reducers/users/SharedUsersAction";
import { FormProvider, RHFTextField } from "../../../shared/components/hook-form";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useNavigate } from "react-router-dom";
import { PATH_SHOP } from "src/routes/paths";
import SharedUsersConstants from "src/shared/reducers/users/SharedUsersConstants";
import call from "src/core/services/http";
import ListHead from "src/shared/TableComponents/ListHead";
import { CustomCell, CustomRow } from "src/shared/TableComponents/TableStyles";
import Title from "src/shared/Text/Title";
import CheckboxComponent from "src/shared/components/checkbox";
import ContainerWrapper from './../../../shared/wrapper/ContainerWrapper';
import MyAvatar from "src/shared/components/profile/MyAvatar";

const TABLE_HEAD = [
    { id: 'logo', label: "", alignLeft: true },
    { id: 'displayName', label: "Name", alignLeft: true },
    { id: 'mobileNumber', label: 'Mobile Number', alignLeft: true },
    { id: 'operatorType', label: "Operator Type", alignLeft: true },
    { id: 'registeredOn', label: "Registered On", alignLeft: true },
    { id: 'signatory', label: "Signatory Status", alignLeft: true },
    { id: 'checker', label: "Checker Status", alignLeft: true },
    { id: 'maker', label: "Maker Status", alignLeft: true },
    // { id: 'operatorStatus', label: "Operator Status", alignLeft: true },
    { id: 'action', label: "Account Status", alignLeft: true },
]
//
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
//
const notifyError = msg => {
    toast.error(msg)
}
//

const OperatorSchema = Yup.object().shape({
    account_operator_type: Yup.string().required('Please provide operator type'),
    notification_type: Yup.string().required('Provide notification type'),
    operator_sasapay_account_number: Yup.string().required('Provide an account number'),
});






export default function Operators() {
    const dispatch = useDispatch()
    const [shop] = useLocalStorage('shop-details')
    const [open, setOpen] = useState(false)
    const [notification, setNotificationOption] = useState(" ")

    const { users, loading, accountTypes } = useSelector(state => state.sharedUser)

    const defaultValues = {
        account_operator_type: '',
        notification_type: "sms",
        operator_sasapay_account_number: "",
        merchantId: AuthService?.getUserId(),
        savingsId: shop.id
    };
    //
    const methods = useForm({
        resolver: yupResolver(OperatorSchema),
        defaultValues,
    });
    //
    const updateNotification = (val) => {
        setValue("notification_type", notification)
        setNotificationOption(val)
    }
    const { handleSubmit, getValues, setValue } = methods;
    //
    const onSubmit = async () => {
        const payload = getValues();
        const res = await call("post", SharedUsersConstants.OPERATORS(payload), payload);

        if (res.data.status) {
            // toast.success(res.data.message)

            getUsers()

        } else {
            toast.error(res.data.message)
        }
    }
    const onFail = () => {
        notifyError('Operation not Successful')
    }

    //

    useEffect(() => {
        //set payload
        getUsers()
    }, [dispatch]);


    const getUsers = () => {
        let payload = {
            merchantId: AuthService.getUserId(),
            savingsId: shop.id
        }
        //dispatch
        dispatch(getOperators(payload))
        setOpen(false)
    }
    const navigate = useNavigate()


    const updateStatus = async (currentStatus, operatorId) => {
        const payload = {
            operator_status: currentStatus === "1" ? "0" : "1",
            merchantId: AuthService.getUserId(),
        }

        const res = await call("post", SharedUsersConstants.UPDATE_STATUS(payload.merchantId, operatorId), payload);

        if (res.data.status) {
            // toast.success(res.data.message)

            getUsers()
        } else {
            toast.error(res.data.message)
        }
    }

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('account_name');
    const [selected, setSelected] = useState([]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {

        if (event.target.checked) {
            const newSelecteds = users.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    function SignatoryComponent({ operator }) {
        const { id, display_name, is_signatory } = operator
        const [signatory, set_is_signatory] = useState(is_signatory)
        const [loading, set_loading] = useState(false)
        const handleChange = async (e) => {
            set_loading(!loading)
            setTimeout(async () => {
                set_loading(false)
                const url = `merchants/${AuthService.getUserId()}/savings-accounts/${shop.id}/account-operators/${id}/update-signatory-status/`
                const req = await call('patch', url, {
                    is_signatory: !signatory
                })

                set_is_signatory(!signatory)
                if (req.data.status) {
                    toast.success(req.data.message)
                } else {
                    toast.error(req.data.message)
                }
                // getUsers()
            }, 4000)
        }


        return (
            <CheckboxComponent acceptText={'+ signatory'} negateText='- signatory' checked={signatory} handleChange={handleChange} loading={loading} key='signatorys' />
        )
    }

    function MakerComponent({ operator }) {
        const { id, is_maker } = operator
        const [maker, set_is_maker] = useState(is_maker)
        const [loading, set_loading] = useState(false)
        const handleChange = async (e) => {
            set_loading(!loading)

            setTimeout(async () => {
                set_loading(false)
                const url = `merchants/${AuthService.getUserId()}/savings-accounts/${shop.id}/account-operators/${id}/update-is-maker/`
                const req = await call('patch', url, {
                    is_maker: !maker
                })

                set_is_maker(!maker)
                if (req.data.status) {
                    toast.success(req.data.message)
                } else {
                    toast.error(req.data.message)
                }
                // getUsers()
            }, 4000)
        }


        return (
            <CheckboxComponent acceptText={'+ maker'} negateText='- maker' checked={maker} handleChange={handleChange} loading={loading} key='makers' />
        )
    }

    function CheckerComponent({ operator }) {
        const { id, is_checker } = operator
        const [checker, set_is_checker] = useState(is_checker)
        const [loading, set_loading] = useState(false)
        const handleChange = async (e) => {
            set_loading(!loading)

            setTimeout(async () => {
                set_loading(false)
                const url = `merchants/${AuthService.getUserId()}/savings-accounts/${shop.id}/account-operators/${id}/update-is-checker/`
                const req = await call('patch', url, {
                    is_checker: !checker
                })

                set_is_checker(!checker)
                if (req.data.status) {
                    toast.success(req.data.message)
                } else {
                    toast.error(req.data.message)
                }
                // getUsers()
            }, 4000)
        }

        return (
            <CheckboxComponent acceptText={'+ checker'} negateText='- checker' checked={checker} handleChange={handleChange} loading={loading} key='checkers' />
        )
    }


    function SubscriberStatusComponent({ operator }) {
        const { id, status, merchantId } = operator
        const [active, set_is_active] = useState(status === "1")
        const [loading, set_loading] = useState(false)
        const handleChange = async (e) => {
            set_loading(!loading)

            setTimeout(async () => {
                set_loading(false)
                const url = SharedUsersConstants.UPDATE_STATUS(AuthService.getUserId(), id)
                const req = await call('post', url, {
                    operator_status: active ? "0" : "1",
                    merchantId: AuthService.getUserId(),
                })

                set_is_active(!active)
                if (req.data.status) {
                    toast.success(req.data.message)
                } else {
                    toast.error(req.data.message)
                }
                // getUsers()
            }, 4000)
        }

        return (
            <CheckboxComponent acceptText={'+ enable'} negateText='- disable' checked={active} handleChange={handleChange} loading={loading} key='checkers' />
        )
    }

    return (
        <Page title="Operators">

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <Dialog
                    maxWidth='md'
                    fullWidth
                    TransitionComponent={Transition}
                    open={open}
                    onClose={() => setOpen(false)}>

                    <DialogTitle>Add New Operator</DialogTitle>
                    <DialogContent>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onFail)}>
                            <Stack spacing={2} mt={2}>
                                {/* {JSON.stringify(accountTypes)} */}
                                <RHFTextField
                                    size={'medium'}
                                    name="account_operator_type"
                                    label='Account Operator Type'
                                    select
                                >
                                    {
                                        accountTypes?.map(el => {
                                            return (
                                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                            )
                                        })
                                    }
                                </RHFTextField>
                                <RHFTextField size={'medium'} name="operator_sasapay_account_number" label='Mobile Number' />

                                <FormLabel id="payment_option">Select Notification Option</FormLabel>
                                <Stack direction="row" spacing={3}>
                                    <RadioGroup
                                        row
                                        aria-labelledby="notification_type"
                                        name="notification_type"
                                        onChange={(e) => {
                                            updateNotification(e.target.value)
                                        }}
                                        defaultValue={1}
                                    >
                                        <Stack direction={'row'}>
                                            {
                                                ["SMS", "Email"].map((value, index) => <FormControlLabel key={index + 1} value={index + 1} control={<Radio />} label={value} />)
                                            }
                                        </Stack>
                                    </RadioGroup>
                                </Stack>

                                <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                                    <LoadingButton fullWidth size="medium" onClick={() => setOpen(!open)} variant='outlined' color='error'>
                                        Cancel
                                    </LoadingButton>
                                    <LoadingButton loading={loading} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </FormProvider>
                    </DialogContent>
                </Dialog>
            </Backdrop>

            <ContainerWrapper heading="Operators"
                links={[
                    { name: 'Dashboard', href: '/shop-dashboard/savings-account/' },
                    { name: 'Operators', href: '/shop-dashboard/savings-account/users/operators' },
                    { name: 'List' },
                ]}>

                <Card
                    sx={{
                        mb: 3,
                        position: 'relative',
                    }}
                >
                    <CardHeader
                        sx={{ mb: 1, paddingTop: 2 }}
                        title={<Typography color="text.info">Operators</Typography>}
                        action={<Button Button onClick={() => navigate(PATH_SHOP.shop.add_operator, { replace: false })} variant="contained" color='primary' startIcon={<Iconify icon="eva:plus-fill" />}>Add Operator</Button>}
                    />
                    <CardContent>
                        {
                            loading ?
                                <Grid container sx={{ justifyContent: 'center' }}> <SpinnerLoader /></Grid>
                                :
                                <>
                                    <Scrollbar>

                                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                            <Table size={'small'}>
                                                <ListHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    headLabel={TABLE_HEAD}
                                                    rowCount={users && users.length}
                                                    numSelected={selected.length}
                                                    onRequestSort={handleRequestSort}
                                                    onSelectAllClick={handleSelectAllClick}
                                                />
                                                <TableBody>
                                                    {
                                                        loading ?
                                                            <><SpinnerLoader /></> :
                                                            users?.map((item) => {
                                                                const { id, is_signatory, operator, operator_type, display_name, created_at, status } = item

                                                                const { name } = operator_type
                                                                return <>
                                                                    <CustomRow
                                                                        hover
                                                                        key={id}
                                                                        tabIndex={id}
                                                                        role="checkbox"
                                                                    >
                                                                        <CustomCell component="th" scope="row" align="left"> 
                                                                        <MyAvatar
                                                                            displayName={display_name}
                                                                            photoURL={''}
                                                                            sx={{
                                                                                mb: 2,
                                                                                mx: 'auto',
                                                                                borderWidth: 2,
                                                                                borderStyle: 'solid',
                                                                                borderColor: 'common.white',
                                                                                fontSize:14,
                                                                                width: { xs: 50, md: 50 },
                                                                                height: { xs: 50, md: 50 },
                                                                            }}
                                                                        /></CustomCell>
                                                                        <CustomCell component="th" scope="row" align="left">{display_name}</CustomCell>
                                                                        <CustomCell component="th" scope="row" align="left">{operator?.mobile_number}</CustomCell>
                                                                        <CustomCell component="th" scope="row" align="left">{name}</CustomCell>
                                                                        <CustomCell component="th" scope="row" >{formatDate(created_at)}</CustomCell>
                                                                        <CustomCell component="th" scope="row" >
                                                                            <SignatoryComponent operator={item} />
                                                                        </CustomCell>

                                                                        <CustomCell component="th" scope="row" >
                                                                            <CheckerComponent operator={item} />
                                                                        </CustomCell>
                                                                        <CustomCell component="th" scope="row" >
                                                                            <MakerComponent operator={item} />
                                                                        </CustomCell>
                                                                        {/* {status == 1 ?
                                                                        <CustomCell align="left"><Badge badgeContent="Active"></Badge> </CustomCell> :
                                                                        <CustomCell align="left"><Badge badgeContent="Inactive"></Badge> </CustomCell>
                                                                    } */}
                                                                        <CustomCell component="th" scope="row" >
                                                                            <SubscriberStatusComponent operator={item} />
                                                                        </CustomCell>
                                                                        {/* <CustomCell component="th" scope="row" >
                                                                        <Button variant="contained" size="small" onClick={() => updateStatus(status, id)} color={status === "1" ? 'error' : 'success'}>{status === "0" ? 'Activate' : 'Suspend'}</Button>
                                                                    </CustomCell> */}
                                                                    </CustomRow>
                                                                </>
                                                            })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Scrollbar>
                                </>
                        }
                    </CardContent>
                </Card>
            </ContainerWrapper>
        </Page>
    )
}