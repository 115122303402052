import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import ShopLayout from "src/shared/layouts/shopLayout/ShopLayout";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import DashboardLayout from "../../../shared/layouts/dashboard";

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const CashBack = Loadable(lazy(() => import('../view/CashBack')));

export const CashBackRoutes = [
    // Dashboard Routes
    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
                <ShopLayout />
            </AuthGuard>
        ),
        children: [
            { path: 'savings-account/cashback', element: <CashBack /> },
        ],
    },
];
