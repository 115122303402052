import { toast } from 'react-toastify';

import call from 'src/core/services/http';
import {
    GET_BULKPAYMENT_LIST,
    GET_BULKPAYMENT_LIST_FAILED,
    GET_BULKPAYMENT_LIST_SUCCESS,
    GET_SINGLE_BULKPAYMENT_LIST,
    GET_SINGLE_BULKPAYMENT_LIST_FAILED,
    GET_SINGLE_BULKPAYMENT_LIST_SUCCESS,
    GET_CHECKERS_LIST,
    GET_CHECKERS_LIST_FAILED,
    GET_CHECKERS_LIST_SUCCESS,
    CLOSE_SINGLE_BULK_MODAL,
} from './BulkpaymentActionTypes';
import BulkpaymentConstants from './BulkpaymentConstants';
const notifyError = (msg) => {
    toast.error(msg);
};

/**
 * get BulkPayment List
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const getBulkPaymentList = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: GET_BULKPAYMENT_LIST,
            loading: true,
        });
        const response = await call('get', BulkpaymentConstants.BULKPAYMENTLIST(payload));
        if (response.data.status) {
            dispatch({
                type: GET_BULKPAYMENT_LIST_SUCCESS,
                loading: false,
                data: response.data,
                bulkPaymentList: response.data.result.bulk_payments,
                message: response.data.message,
            });
        } else {
            dispatch({
                type: GET_BULKPAYMENT_LIST_FAILED,
                payload: response.data,
                bulkPaymentList: [],
                loading: false,
            });
            notifyError(response.data.message);
        }
    } catch (error) { }
};

/**
 * get BulkPayment List
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const getSingleBulkPaymentList = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: GET_SINGLE_BULKPAYMENT_LIST,
            loading: true,
        });
        const response = await call('get', BulkpaymentConstants.SINGLEBULKPAYMENT(payload));
        if (response.data.status) {
            dispatch({
                type: GET_SINGLE_BULKPAYMENT_LIST_SUCCESS,
                loading: false,
                openSingle: true,
                reason: payload.reason,
                data: response.data,
                singleBulkPaymentList: response.data.result.bulk_payment_details,
                message: response.data.message,
            });
        } else {
            dispatch({
                type: GET_SINGLE_BULKPAYMENT_LIST_FAILED,
                payload: response.data,
                singleBulkPaymentList: [],
                loading: false,
            });
            notifyError(response.data.message);
        }
    } catch (error) { }
};

/**
 * get list of checkers
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const getCheckers = (payload) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CHECKERS_LIST,
            loading: true,
        });
        const response = await call('get', BulkpaymentConstants.CHECKERSLIST(payload));
        if (response.data.status) {
            dispatch({
                type: GET_CHECKERS_LIST_SUCCESS,
                loading: false,
                data: response.data,
                bulkpaymentCheckers: response.data.results.account_operators,
                message: response.data.message,
            });
        } else {
            dispatch({
                type: GET_CHECKERS_LIST_FAILED,
                payload: response.data,
                bulkpaymentCheckers: [],
                loading: false,
            });
            notifyError(response.data.message);
        }
    } catch (error) { }
};


export const closeSingleModal = (payload) => (dispatch) => {
    dispatch({
        type: CLOSE_SINGLE_BULK_MODAL,
        openSingle: false,
        reason: payload.reason,
        singleBulkPaymentList: []

    })
}