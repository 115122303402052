import { Box, Typography, Button, Card, CardContent, Tabs, Grid, Stack, InputAdornment, ListItemText, MenuItem, OutlinedInput, InputLabel, FormControl, Select, DialogContent, DialogActions, Tab } from '@mui/material';
import React, { useEffect, useState } from 'react'

import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { withStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { filter, upperCase } from 'lodash';
import UserConstants from 'src/packages/users/store/UserConstants';
import call from 'src/core/services/http';
import { FormProvider, RHFTextField } from "../../../../../shared/components/hook-form";
import TransactionConstants from '../../../store/TransactionConstants';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';
import { CenteredCard } from 'src/shared/components/global';
import Iconify from 'src/shared/components/Iconify';

const defaultValues = {
    recipient_account_number: '',
    sender_account_number: '',
    reason: '',
    channel_code: '',
    option: '0',
    alias: '',
    bill_number: '',
    sending_account_currency: '',
    receipient_account_number: '',
    receipient_account_currency: '',
    sending_amount: 0,
    receiving_amount: 0,
}

const options = [
    {
        key: '0',
        label: ' Account Number ',
    },
    {
        // key: '1',
        // label: 'Sub-Wallet A/C',
    },
]

const ValidationSchema = Yup.object().shape(
    {
        receipient_account_number: Yup.string()
            .when("option", {
                is: (option) => option === "1" || option === "0",
                then: Yup.string().required(`Please enter the account number`),
            }),

        bill_number: Yup.string()
            .when("option", {
                is: (option) => option === "3" || option === "4",
                then: Yup.string().required("Please enter the Paybill/Till number"),
            }),
        sender_account_number: Yup.string(),
        sending_amount: Yup.number().required("Please enter the amount to send"),
        receiving_amount: Yup.number().required("Please enter the amount to send"),
        reason: Yup.string().when("option", {
            is: (option) => option === "3",
            then: Yup.string().required("Please enter the account number"),
        }),
        option: Yup.string().required("Select a payment option"),
        channel_code: Yup.string()
    }
)
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const inputSwich = [
    {
        key: '0',
        name: 'recipient_account_number',
        label: ' Till/PayBill / Wallet/ MobileNo(+255) '
    },
    // {
    //     key: '1',
    //     name: 'recipient_account_number',
    //     label: 'Mobile Number A/C'
    // },

]



function WapiX() {

    const [shop] = useLocalStorage('shop-details')
    const methods = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues,
    });

    const { handleSubmit, reset, setValue, watch,
        formState: { isSubmitting } } = methods;
    const values = watch()


    const { option, receipient_account_number, sending_amount, bill_number } = values
    const payementOptionInputContol = filter(inputSwich, p => p.key === option)[0]

    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState('')

    const [rate, setRate] = useState({
        sending_currency: '',
        receiving_currency: '',
        sending_amount: 0,
        recieving_amount: 0,
        rate_one: 0,
        rate_two: 0
    })

    const { recieving_amount } = rate
    const onSubmit = async () => {
        const payload = {
            sender_account_number: shop.account_number,
            bill_number,
            sending_account_currency: 'USD',
            receipient_account_number,
            receipient_account_currency: '',
            sending_amount,
            receiving_amount: recieving_amount,
        };
        let url = ''

        const key = payementOptionInputContol.key
        if (key === '0') {
            //SP paybill/till number
            url = open ? TransactionConstants.SENDMONEYWAPIX : `${TransactionConstants.SENDMONEYWAPIX}confirm/`
        } else if (key === '1') {
            //SP account number
            url = open ? TransactionConstants.SENDMONEYWAPIX : `${TransactionConstants.SENDMONEYWAPIX}confirm/`
        } else if (key === '2') {
            //SP alias
            url = open ? '' : `${TransactionConstants.SENDMONEYB2C}confirm/`
        } else if (key === '3' || key === '4') {
            //MPESA Paybill
            url = open ? TransactionConstants.SENDMONEYMB2C : `${TransactionConstants.SENDMONEYMB2C}confirm/`
        }

        const response = await call("post", url, payload)
        if (response.data.status) {
            setConfirmation(response.data.message)
            setOpen(!open)

            if (open) {
                reset()
                setDisplay('')
                toast.success(response.data.message)
            }
        } else {
            toast.error(response.data.message)
        }

    }
    const [receiving_currency, set_receiving_currency] = useState('TZS')
    const [fetching, setFetching] = useState(false)

    const [display, setDisplay] = useState('')

    const searchInput = async (e) => {
        setValue('sending_amount', 0)
        if (payementOptionInputContol.key === '0') {
            const response = await call('post', TransactionConstants.WAPIXACCOUNT, { bill_number: e.target.value })
            if (response.status) {
                setDisplay(response.data.merchant.name)
                set_receiving_currency(response.data.merchant.currency)
            }
        } else if (payementOptionInputContol.key === '1') {
            const response = await call('post', TransactionConstants.WAPIXACCOUNT, { mobile_number: e.target.value })
            let data = response.data
            if (data.status) {
                data = data.results.clients[0]
                setDisplay(data.display_name)
                setValue('receipient_account_number,', data.mobile_number)
                set_receiving_currency(response.data.merchant.currency)
                // transferAmountOnBlur(e)
            } else {
                setValue('receipient_account_number', '')
            }
        }
    }


    const transferAmountOnBlur = async (e) => {
        setRate({ ...rate, recieving_amount: 0 })
        if (receiving_currency != shop.product_currency_code) {

            setFetching(true)
            const payload = {
                "sending_currency": shop.product_currency_code,
                "receiving_currency": receiving_currency,
                "sending_amount": sending_amount
            }

            const response = await call('post', TransactionConstants.WAPIXEXCHNGE, payload)
            setFetching(false)
            if (response.data.status) {
                setRate(response.data.data)
                setValue("receiving_amount", response.data.data.recieving_amount)
            } else {
                toast.success(response.data.message)
            }
        } else {
            setRate({ ...rate, recieving_amount: e.target.value })
        }

    }

    return (
        <>
            <CenteredCard>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <CustomDialog onClose={() => setOpen(false)}
                            maxWidth='sm'
                            fullWidth
                            aria-labelledby="dialog"
                            open={open}>
                            <CustomDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                                Confirm
                            </CustomDialogTitle>
                            <DialogContent>
                                {confirmation}
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton onClick={handleSubmit(onSubmit)} variant='contained' size='small' loading={isSubmitting} color='info' >Confirm</LoadingButton>
                                <Button size='small' onClick={() => setOpen(false)} color='error' variant='contained'>Cancel</Button>
                            </DialogActions>
                        </CustomDialog>

                        <Stack spacing={2}>
                            <FormControl>
                                <InputLabel id="countries-multiple-checkbox-label"> Select Option </InputLabel>
                                <Select
                                    labelId="optionlabelId"
                                    id="optionId"
                                    value={values.option}
                                    onChange={(e) => {
                                        reset()
                                        setDisplay("")
                                        setValue('option', e.target.value)
                                    }}
                                    name='option'
                                    input={<OutlinedInput label="Payment Option" />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="0" disabled>---SELECT---</MenuItem>
                                    {options.map(({ key, label }) => (
                                        <MenuItem key={key} value={key}>
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <RHFTextField
                                // onKeyUp={searchInput}
                                onBlur={searchInput}
                                size={'medium'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"><Typography sx={{ color: 'green' }} variant='caption'>{upperCase(display)}</Typography></InputAdornment>
                                    ),
                                }}
                                name={payementOptionInputContol.name || ''}
                                label={payementOptionInputContol.label || ''}
                            />

                            <Stack spacing={2} direction='row'>
                                {/* <input onKeyUp={}/> */}
                                <RHFTextField
                                    name="sending_amount"
                                    focused
                                    // onBlur={transferAmountOnBlur}
                                    onKeyUp={transferAmountOnBlur}
                                    size={'medium'}
                                    label='Amount to Send'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">{shop.product_currency_code}</InputAdornment>
                                        ),
                                    }}
                                />
                                <Iconify sx={{ m: 0.5, width: 50, height: 50 }} icon={fetching && 'svg-spinners:gooey-balls-1' || 'ci:arrow-left-right'} />

                                <RHFTextField
                                    size={'medium'}
                                    focused
                                    name=" receiving_amount"
                                    value={rate.recieving_amount}
                                    disabled
                                    label='Amount to be Received'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">{receiving_currency}</InputAdornment>
                                        ),
                                    }}
                                />

                            </Stack>
                            {shop.product_currency_code !== receiving_currency && rate.rate_two != 0 &&
                                < Stack spacing={2} direction='row'>
                                    <Typography variant='' sx={{ color: 'green' }}>Exchange rate 1{shop.product_currency_code}  =  {receiving_currency}{rate.rate_two}</Typography>
                                </Stack>
                            }

                            <LoadingButton variant='contained' size='medium' loading={isSubmitting} fullWidth type='submit'>Send Money</LoadingButton>
                        </Stack>
                    </FormProvider>
                </CardContent>
            </CenteredCard>
        </>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default WapiX

