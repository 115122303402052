import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import ShopLayout from "../../../shared/layouts/shopLayout";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/shop-dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const AutoSettlement = Loadable(lazy(() => import('../view/AutoSettlement')));
const AutoSettlementCard = Loadable(lazy(() => import('../components/AutoSettlementCard')));

export const AutoSettlementRoutes = [
    // Dashboard Routes
    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
            <ShopLayout />
            </AuthGuard>
        ),
        children: [
            { path: 'schedule-settlement', element: <AutoSettlement/> },
            { path: 'schedule-settlement-card', element: <AutoSettlementCard/> },

        ],
    },
];


