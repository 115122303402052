import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    CardHeader,
    Grid,
    InputAdornment,
    MenuItem,
    TableCell,
    Slide, Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    Typography, Card
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { getControlAccounts, transferFunds } from "../store/TransactionsActions";
import { RHFTextField, FormProvider } from "../../../shared/components/hook-form";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import Iconify from "src/shared/components/Iconify";
import Page from "src/shared/components/Page";
import call from "src/core/services/http";
import TransactionConstants from "../store/TransactionConstants";
import { PATH_SHOP } from "src/routes/paths";
import { fCurrency } from "src/shared/utils/formatNumber";
import { getTheAccounts } from "src/shared/utils/helpers/helpers";
import { transferChoices } from "src/shared/utils/utilArrays";
import { appName } from "src/core/environment/environment";

const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

//
const notifySuccess = msg => {
    toast.success(msg)
}
const notifyError = msg => {
    toast.error(msg)
}
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
//
export default function InternalFundMovement() {
    const [shop] = useLocalStorage('shop-details');
    const [merchant] = useLocalStorage(`${appName}_user`)
   
    const { loading, controlAccounts } = useSelector(state => state.transactions)

    const dispatch = useDispatch()
    const InternalTransferSchema = Yup.object().shape({
        account_selected: Yup.string().required("Please select the accounts"),
        amount: Yup.string().required('Provide an amount'),
    });

    const defaultValues = {
        account_selected: "",
        amount: '',
    };
    //
    const [open, setOpen] = useState(false)
    const methods = useForm({
        resolver: yupResolver(InternalTransferSchema),
        defaultValues,
    });
    const navigate = useNavigate()
    //
    const { handleSubmit, reset, formState: { isSubmitting }, getValues } = methods;

    const onSubmit = async () => {
        const { amount, account_selected } = getValues()
        const payload = {
            amount: parseInt(amount),
            account_selected
        }
        const selectAccounts = getTheAccounts(account_selected, controlAccounts)
        payload.from_account_control = selectAccounts.accountFrom.id
        payload.to_account_control = selectAccounts.accountTo.id
        //console.log("Payload", payload)

        const response = await call('post', TransactionConstants.CONTROL_ACCOUNTS({ merchantId: merchant.id, savingsAccountId: shop.id }), payload)

        if (response.data.status) {
            notifySuccess(response.data.message)
            reset()
            dispatch(getControlAccounts({ merchantId: merchant.id, savingsAccountId: shop.id }))
        } else {
            notifyError(response.data.message)

        }
    }
    //
    useEffect(() => {
        dispatch(getControlAccounts({ merchantId: merchant?.id, savingsAccountId: shop?.id }))
    }, [dispatch])


    const accountsValues = controlAccounts?.map(account => parseInt(account.account_balance))

    const totalAmount = accountsValues?.reduce((acc, curr) => acc + curr, 0)

    return (
        <Page title="IFT">
           {/* {JSON.stringify(merchant)}  */}
            <ToastContainer theme={"colored"} />
            <CardHeader
                sx={{ mb: 1, paddingTop: 0 }}
                title={<Typography color="text.primary">Internal Fund Movement</Typography>}
                action={<Button onClick={() => navigate(PATH_SHOP.shop.main, { replace: true })} variant="outlined" color='primary' startIcon={<Iconify icon="uil:angle-left" />}>Back</Button>}
            />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item sx={{ xs: { display: 'none' }, lg: { display: 'block' } }} lg={2}></Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Card sx={{ p: 3 }}>
                        <Typography variant="subtitle2" gutterBottom>
                            Your Current Balance
                        </Typography>

                        <Stack spacing={2}>
                            <Typography variant="h3">{fCurrency(totalAmount, shop.product_currency_code)}</Typography>

                            {controlAccounts?.map(({ id, account_balance, account_control_type, currency_code, }) => (
                                <RowStyle key={id}>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {account_control_type?.name}
                                    </Typography>
                                    <Typography variant="body2">{fCurrency(account_balance, currency_code)}</Typography>
                                </RowStyle>
                            ))}

                        </Stack>
                    </Card>

                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Card sx={{ p: 3 }} >
                        <Typography variant="subtitle2" gutterBottom>
                            Fund Movement
                        </Typography>
                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                            <Stack spacing={2} mt={2}>
                                <RHFTextField
                                    size={'small'}
                                    name="account_selected"
                                    label='Account'
                                    select
                                >
                                    {
                                        transferChoices?.map(el => {
                                            return (
                                                <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                            )
                                        })

                                    }
                                </RHFTextField>
                                {/* {JSON.stringify(shop.product_currency_code)} */}

                                <RHFTextField size={'small'} name="amount" label='Amount'
                                    InputProps={{
                                        startAdornment: (

                                            <InputAdornment position="start">{shop.product_currency_code}</InputAdornment>
                                        ),
                                    }} />

                                <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                                    <LoadingButton loading={isSubmitting} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                                        Submit
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </FormProvider></Card>
                </Grid>
            </Grid>

        </Page >

    )
}