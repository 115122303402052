import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import { toast } from 'react-toastify';
import AuthService from 'src/core/access-control/AuthService';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------



export default function DashboardLayout({ navConfig }) {
  const [open, setOpen] = useState(false);

  let timeout = null;

  useEffect(() => {
    restartAutoReset()
  }, [])

  const restartAutoReset = () => {
    console.log("MOUSE MOVING");
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      // Insert your code to reset you app here
      toast.error("Your session has timed out due to inactivity. Please log in again to continue using the system.", { delay: 60000, progress: true, autoClose: false, pauseOnHover: true })
      // navigate('/auth/login', { replace: true })
      AuthService.logout()
    }, 60000 * 60); // 5 mins
  };

  const onMouseMove = (e) => {
    restartAutoReset();
  };

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav navConfig={navConfig} openNav={open} onCloseNav={() => setOpen(false)} />
      <Main onMouseMove={onMouseMove}>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}
