// utils
import createAvatar from '../../utils/createAvatar';
//
import Avatar from './Avatar';

import T from 'prop-types'

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  photoURL: T.string,
  displayName: T.string,
}
export default function MyAvatar({ photoURL, displayName, ...other }) {

  return (
    <Avatar
      src={photoURL}
      alt={displayName}
      color={photoURL ? 'default' : createAvatar(displayName).color}
      {...other}
    >
      {createAvatar(displayName).name}
    </Avatar>
  );
}
