import T from 'prop-types'
/*eslint-disable*/
import { Box, Button, Collapse, Divider, Grid, IconButton, ListItem, ListItemText, ListSubheader, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react'
import ListHead from 'src/shared/TableComponents/ListHead'
import Scrollbar from 'src/shared/components/Scrollbar';
import { CustomCell, CustomRow } from 'src/shared/TableComponents/TableStyles';
import { fNumber } from 'src/shared/utils/formatNumber';
import { fDateDMY } from 'src/shared/utils/formatTime';
import { sentenceCase } from 'change-case';
import Label from 'src/shared/components/Label';
import useResponsive from 'src/shared/hooks/useResponsive';
// import InitialsAvatar from 'react-initials-avatar';
// 'react-initials-avatar/lib/ReactInitialsAvatar.css';
// @mui

const TABLE_HEAD = [
    { id: 'index', abel: 'SNo#', alignRight: false },
    { id: 'client', label: 'Client', alignRight: false },
    { id: 'jazia_sponsor', label: 'Sponsor Details', alignRight: false },
    { id: 'limit_amount', label: 'Loan Limit', alignRight: false },
    { id: 'principal_amount', label: 'Principle Amount', alignRight: false },
    { id: 'interest_charged_derived', label: 'Interest Charge Derived', alignRight: false },
    { id: 'outstanding_balance', label: 'Outstanding Loan Amount', alignRight: false },
    { id: 'loan_type_enum', label: 'Loan Type', alignRight: false },
    { id: 'status', label: 'Loan Status', alignRight: false },
    { id: 'disbursedon_date', label: 'Disbursed On', alignRight: false },
    { id: 'action', label: '', alignRight: false },
];

const getLoanType = (status) => {
    let type = 'Unknown'
    let color = 'error'
    switch (parseInt(status)) {
        case 1:
            color = 'success'
            type = 'Loan'
            break;
        case 2:
            color = 'info'
            type = 'BNPL'
            break;
        case 3:
            color = 'warning'
            type = 'Salary Advance'
            break;
    }


    return <Label color={color} variant='filled' sx={{ color: 'white' }}>{sentenceCase(type)}</Label>
}

const getLoanStatus = (status) => {
    let loan_status = 'Unknown'
    let color = 'error'
    switch (parseInt(status)) {
        case 1:
            color = 'error'
            loan_status = 'Unpaid Loan'
            break;
        case 2:
            color = 'success'
            loan_status = 'Cleared'
            break;
        case 3:
            color = 'secondary'
            loan_status = 'Partially Paid'
            break;
        case 4:
            color = 'error'
            loan_status = 'Overdue'
            break;
        case 4:
            color = 'error'
            loan_status = 'Written Off'
            break;
        case 0:
            color = 'warning'
            loan_status = 'Awaiting Processing'
            break;
    }


    return <Label color={color} variant='filled' sx={{ color: 'white' }}>{sentenceCase(loan_status)}</Label>
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

LoansTable.propTypes = {
    list: T.array,
    pages: T.number,
    Paginator: T.any,
    rowsPerPage: T.number,
    page: T.number,
}


LoansTable.defaultProps = {
    list: [],
    pages: 0
}

export default function LoansTable({ list, Paginator, queryConfirmProcessing }) {
    const [filterName, setFilterName] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');

    const isDesktop = useResponsive('up', 'md');

    const [selected, setSelected] = useState([]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => { }, [list])

    const filteredLoans = applySortFilter(list, getComparator(order, orderBy), filterName);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = list.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isNotFound = !filteredLoans.length && !!filterName;

    const MobileRowComponent = ({ row, index }) => {
        // console.log("DATA:", row)
        const { id, jazia, period_description, period_percentage,due_date,
            client: { first_name, middle_name, last_name, mobile_number, display_name }
            , total_outstanding_derived, principal_amount, interest_charged_derived, loan_type_enum, disbursedon_date, grace_period_end_date, subscription_code, loan_status_id } = row

        const [open, setOpen] = React.useState(false);
        
        const PrimaryComponent = () => (
            <Grid spacing={2} container>
                <Grid item md={6} xs={6}>
                    <Stack>
                        <Typography variant='button'>CLIENT INFORMATION</Typography>

                        <Typography variant='caption'>
                            {display_name}
                        </Typography>

                        <Typography variant='caption'>
                            {mobile_number}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Stack>
                        <Typography variant='button'>LOAN INFORMATION</Typography>

                        <Typography variant='caption'>
                            Principle:  {fNumber(principal_amount)}
                        </Typography>
                        <Typography variant='caption'>
                            Interest: {fNumber(interest_charged_derived)}
                        </Typography>
                        <Typography variant='caption'>
                            Outstanding: {fNumber(jazia.outstanding_balance)}
                        </Typography>
                        <Typography variant='caption'>
                            Period Description: {period_description}
                        </Typography>
                        <Typography variant='caption'>
                            Period Percentage: {period_percentage}%
                        </Typography>
                        {/* {loan_status_id === 0 && <Typography sx={{ mt: 2 }} variant='caption'>
                            <Button variant='contained' size='small' color='info' onClick={() => queryConfirmProcessing(row)}>Process</Button>
                        </Typography>} */}

                    </Stack>
                </Grid>
                <Grid item md={6} xs={6}>
                    <Stack >
                        <Typography variant='button'>SPONSOR INFORMATION</Typography>

                        <Typography variant='caption'>
                            {jazia?.sponsor && jazia?.sponsor?.description}
                        </Typography>

                        <Typography variant='caption'>
                            {jazia?.sponsor && jazia?.sponsor?.account_number}
                        </Typography>
                    </Stack>
                </Grid>

            </Grid>
        )

        const SecondaryComponent = () => (
            <Grid container>

                <Grid item md={6} xs={6}>
                    <Stack sx={{ mt: 1 }}>

                        <Typography variant='caption'>
                            {getLoanStatus(loan_status_id)}
                        </Typography>


                    </Stack>
                </Grid>

                <Grid item md={6} xs={6}>
                    <IconButton disableRipple disableTouchRipple onClick={() => setOpen(!open)}> {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}  <Typography variant='caption'>more...</Typography> </IconButton>
                </Grid>

            </Grid>
        )
        return (
            <>
                <Paper key={id} square sx={{ pb: '50px' }}>
                    <ListSubheader sx={{ bgcolor: 'background.paper' }}>
                        {/* <ListItemAvatar>
                            <InitialsAvatar name={`${first_name} ${last_name}`} />
                        </ListItemAvatar> */}
                        <Stack>
                            {`${first_name} ${last_name}`}
                            <Typography>{getLoanType(loan_type_enum)}</Typography>
                        </Stack>

                    </ListSubheader>
                    <ListItem onClick={() => setOpen(!open)} button>

                        <ListItemText primary={<PrimaryComponent />} secondary={<SecondaryComponent />} />
                        {/* <IconButton onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton> */}
                    </ListItem>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, mt: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4} >
                                    <Stack spacing={2} >
                                        <TextField size='small' disabled fullWidth defaultValue={jazia.limit_amount} label='Loan Limit' />
                                        <TextField size='small' disabled fullWidth defaultValue={jazia.available_amount} label='Avaliable Loan Limit' />
                                        <TextField size='small' disabled fullWidth defaultValue={jazia.consumed_amount} label='Consumed Loan Amount' />
                                        <TextField size='small' disabled fullWidth defaultValue={fDateDMY(disbursedon_date)} label='Disbursed On' />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Stack spacing={2} >
                                        <TextField size='small' disabled fullWidth defaultValue={fNumber(total_outstanding_derived)} label='Outstanding Balance' />
                                        <TextField size='small' disabled fullWidth defaultValue={fNumber(principal_amount)} label='Loan Principle' />
                                        <TextField size='small' disabled fullWidth defaultValue={fNumber(interest_charged_derived)} label='Interest Charged on Principle' />
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Stack spacing={2} >
                                        <TextField size='small' disabled fullWidth defaultValue={fDateDMY(grace_period_end_date)} label='Grace Period Expiry On' />
                                        <TextField size='small' disabled fullWidth defaultValue={fDateDMY(due_date)} label='Maturity Date' />
                                        <TextField size='small' disabled fullWidth defaultValue={fDateDMY(due_date)} label='Repayment Date' />
                                    </Stack>
                                </Grid>

                            </Grid>
                        </Box>
                        <Stack>
                            <IconButton onClick={() => setOpen(!open)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>

                            <Divider color='#555' />
                            {/* <Button size='small' variant='contained' color='error' onClick={() => setOpen(!open)}>Close</Button> */}
                        </Stack>
                    </Collapse>
                </Paper>

            </>
        )
    }


    const DesktopRowComponent = ({ row, index }) => {

        const { id, jazia,
           period_description, period_percentage ,
            client: { first_name, middle_name, last_name, mobile_number, display_name }
            , total_outstanding_derived, principal_amount, interest_charged_derived, loan_type_enum, disbursedon_date, grace_period_end_date,subscriber_account_number, subscription_code, loan_status_id } = row
        const [open, setOpen] = React.useState(false);

        return (
            <>
                <CustomRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {index+1}
                        </Typography>
                    </CustomCell>
                    <CustomCell align="left">
                        <Stack spacing={1}>

                            <Typography variant='caption'>
                                {display_name}
                            </Typography>

                            <Typography variant='caption'>
                                {subscriber_account_number}
                            </Typography>
                        </Stack>
                    </CustomCell>
                    <CustomCell align="left">
                        <Stack spacing={1}>
                            <Typography variant='caption'>
                                {jazia?.sponsor && jazia?.sponsor?.description}
                            </Typography>

                            <Typography variant='caption'>
                                {jazia?.sponsor && jazia?.sponsor?.account_number}
                            </Typography>

                        </Stack>
                    </CustomCell>

                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {fNumber(jazia.limit_amount)}
                        </Typography>
                    </CustomCell>

                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {fNumber(principal_amount)}
                        </Typography>

                    </CustomCell>
                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {fNumber(interest_charged_derived)}
                        </Typography>
                    </CustomCell>
                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {fNumber(total_outstanding_derived)}
                        </Typography>
                    </CustomCell>
                    <CustomCell align="left">
                        {getLoanType(loan_type_enum)}
                    </CustomCell>
                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {getLoanStatus(loan_status_id)}
                        </Typography>
                    </CustomCell>
                    <CustomCell align="left">
                        <Typography variant='caption'>
                            {fDateDMY(disbursedon_date)}
                        </Typography>
                    </CustomCell>

                    <CustomCell>
                        <IconButton onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </CustomCell>
                </CustomRow >

                <TableRow >
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1, mt: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} >
                                        <Stack spacing={2} >
                                            <TextField size='small' disabled fullWidth defaultValue={jazia.limit_amount} label='Loan Limit' />
                                            <TextField size='small' disabled fullWidth defaultValue={jazia.available_amount} label='Avaliable Loan Limit' />
                                            <TextField size='small' disabled fullWidth defaultValue={jazia.consumed_amount} label='Consumed Loan Amount' />

                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Stack spacing={2} >
                                            <TextField size='small' disabled fullWidth defaultValue={fNumber(jazia.outstanding_balance)} label='Outstanding Balance' />
                                            <TextField size='small' disabled fullWidth defaultValue={fNumber(principal_amount)} label='Loan Principle' />
                                            <TextField size='small' disabled fullWidth defaultValue={fNumber(interest_charged_derived)} label='Interest Charged on Principle' />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Stack spacing={2} >
                                            <TextField size='small' disabled fullWidth defaultValue={fDateDMY(grace_period_end_date)} label='Grace Period Expiry On' />
                                            <TextField size='small' disabled fullWidth defaultValue={`${period_description || ''} at ${period_percentage || ''}%`} label='Period Description' />
                                            {/* <TextField size='small' disabled fullWidth defaultValue={fDateDMY(grace_period_end_date)} label='Maturity Date' />
                                            <TextField size='small' disabled fullWidth defaultValue={fDateDMY(grace_period_end_date)} label='Repayment Date' /> */}

                                            {loan_status_id === 0 && <Typography sx={{ mt: 2 }} variant='caption'>
                                                <Button variant='contained' size='small' color='info' onClick={() => queryConfirmProcessing(row)}>Process</Button>
                                            </Typography>}
                                        </Stack>

                                    </Grid>

                                </Grid>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        )
    }


    return (
        <Scrollbar>

            <TableContainer >
                <Table>

                    {isDesktop && <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={list.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                    />}
                    <TableBody >
                        {filteredLoans.map((subscriber, index) => {
                            return (<>
                                {
                                    isDesktop && <DesktopRowComponent row={subscriber} index={index} /> || <MobileRowComponent row={subscriber} index={index} />
                                }
                            </>)
                        })}
                    </TableBody>
                    {filteredLoans.length === 0 && (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                    <Paper
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography variant="h6" paragraph>
                                            Not found
                                        </Typography>

                                        <Typography variant="body2">
                                            No results found for &nbsp;
                                            <strong>&quot;{filterName}&quot;</strong>.
                                            <br /> Try checking for typos or using complete words.
                                        </Typography>
                                    </Paper>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    )}
                </Table>

            </TableContainer>
            {Paginator}
        </Scrollbar>
    )
}


