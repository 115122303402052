import {
    Card,
    CardContent,
    Container,
    Grid,
    Slide,
    Stack,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText



} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton } from "@mui/lab";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import AuthService from "src/core/access-control/AuthService";
import { FormProvider, RHFTextField } from "../../../shared/components/hook-form";
import { fCurrency } from "src/shared/utils/formatNumber";
import call from "src/core/services/http";
import BillNumberConstants from "src/shared/reducers/BillNumber/BillNumberConstants";
import TransactionConstants from '../../../packages/transactions/store/TransactionConstants';





const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const SendMoneySchema = Yup.object().shape({
    sacco_sasapay_account_number: Yup.string().required('sacco account is required'),
    beneficiary_account_number: Yup.string().required('Beneficiary account is required'),
    amount: Yup.string().required('Provide an amount'),
});

const defaultValues = {
    sacco_sasapay_account_number: "",
    beneficiary_account_number: "",
    amount: "",
};


export default function SendMoney() {


    const [shop] = useLocalStorage('shop-details');
    const { controlAccounts } = useSelector(state => state.transactions)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const methods = useForm({
        resolver: yupResolver(SendMoneySchema),
        defaultValues,
    });


    const { handleSubmit, reset, setValue, getValues, formState: { isSubmitting } } = methods;

    // Search bill number
    const [open, setOpen] = useState(false)
    const [searchingBillNumber, setSearchingBillNumber] = useState("")
    const [billNumber, setBillNumber] = useState("")
    const [sendTo, setSendTo] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [searchedSuccess, setSearchedSuccess] = useState(false)
    const [state, setState] = useState({
        account_number: "",
        account: { account_number: "", account_name: "" }
    })
    const [openConfirmation, setOpenConfirmation] = useState(false);
    
    const [completedMessage, setCompletedMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");




    const searchAccount = async (accountNumber) => {
        try {
            const response = await call('post', BillNumberConstants.ACCOUNT_SEARCH, { account_number: accountNumber });

            if (response.data.status) {
                const { account_name } = response.data.account;
                setState((prevState) => ({
                    ...prevState,
                    account: { account_number: accountNumber, account_name },
                }));
                setValue("merchant_code", accountNumber);
            } else {
                setState((prevState) => ({
                    ...prevState,
                    account: { account_number: accountNumber, account_name: '' },
                }));
            }
        } catch (error) {
            console.error(error);
        }
    };





    const onSubmit = async () => {
        try {
            setOpenConfirmation(true);
            setLoading(true);
            const { amount, sacco_sasapay_account_number, beneficiary_account_number } = getValues();

            const payload = {
                merchant_account_number: shop.account_number,
                sacco_sasapay_account_number,
                beneficiary_account_number,
                amount,
            };

            const res = await call("post", `/transfers/send-sacco-payment/`, payload);

            if (res.data.status) {
                toast.success(res.data.message);
                setCompletedMessage(res.data.message);
                reset(defaultValues);
                setOpenConfirmation(false)
            } else {
                toast.error(res.data.message);
            }
           
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    const handleOpenConfirmation = (e) => {
        setOpenConfirmation(true);
    };


    const handleCloseConfirmation = (e) => {
        setOpenConfirmation(false);
    };





    return (
        <>
            <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle>Confirm Submission</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Confirm Deposit of  TSH {getValues("amount")} to  {state.account.account_name} Account Number {getValues("sacco_sasapay_account_number")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton variant="contained" onClick={handleCloseConfirmation} color="error">
                        Cancel
                    </LoadingButton>
                    <LoadingButton variant="contained" loading={loading} onClick={onSubmit} color="primary" autoFocus>
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Container maxWidth='xl'>
                <ToastContainer theme={"colored"} />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 12, sm: 12, md: 3 }}>
                    <Grid item sx={{ xs: { display: 'none' }, lg: { display: 'block' } }} lg={4}></Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <Card>
                            <CardContent>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Stack spacing={2} mt={2}>
                                        <RHFTextField
                                            size={'medium'}
                                            name="sacco_sasapay_account_number"
                                            label='Sacco Account Number'
                                            onBlur={(e) => searchAccount(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <span style={{ color: 'green' }}>{state.account.account_name}</span>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        <RHFTextField size={'medium'} name="beneficiary_account_number" label='Beneficiary Account Number ' />
                                        <RHFTextField size={'medium'} name="amount" label='Amount' />
                                        <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                                            <LoadingButton
                                                onClick={handleSubmit(handleOpenConfirmation)}
                                                fullWidth
                                                size="medium"
                                                variant='contained'
                                                color='primary'
                                            >
                                                Submit
                                            </LoadingButton>
                                        </Stack>
                                    </Stack>
                                </FormProvider>

                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Container>
        </ >
    )
}