import { SHOP_TYPE_API_FAILED, SHOP_TYPE_API_REQUEST, SHOP_TYPE_API_SUCCESS } from "./ShopTypesActionTypes";

const initialState = {
    loading: false,
    message: "",
    shopTypes: [],
    error: {},
}
export default function ShopTypesReducer(state = initialState, action) {
    //
    const { type, payload, message, loading, error } = action;
    switch (type) {
        case SHOP_TYPE_API_REQUEST:
            return {
                loading,
            }
        case SHOP_TYPE_API_SUCCESS:
            return {
                loading,
                shopTypes: payload?.savings_products,
                message
            }
        case SHOP_TYPE_API_FAILED:
            return {
                loading,
                message,
                error
            }
        default:
            return state
    }
}