import { toast } from "react-toastify";
// @mui
import {
    Card,
    Stack,
    Button,
    MenuItem,
    Dialog,
    Slide,
    CardHeader,
    Typography,
    CardContent,
    Backdrop,
    DialogTitle,
    DialogContent,
    Grid
} from '@mui/material';
// utils

// components

import { fCurrency } from 'src/shared/utils/formatNumber';
import Iconify from 'src/shared/components/Iconify';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getControlAccounts } from 'src/packages/transactions/store/TransactionsActions';
import AuthService from 'src/core/access-control/AuthService';
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { RHFTextField, FormProvider } from "../../../shared/components/hook-form";
import { LoadingButton } from '@mui/lab';
import TransactionConstants from "src/packages/transactions/store/TransactionConstants";
import call from "src/core/services/http";
import { appName } from "src/core/environment/environment";

// ----------------------------------------------------------------------
const notifySuccess = msg => {
    toast.success(msg)
}
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
//
const InternalTransferSchema = Yup.object().shape({
    from_account_control: Yup.string().required('Please provide a sender account'),
    to_account_control: Yup.string(),
    amount: Yup.string().required('Provide an amount'),
});

const defaultValues = {
    from_account_control: '',
    to_account_control: '',
    amount: '',
};

export default function AccountBalancesSummary() {
    const dispatch = useDispatch()
    const { loading, controlAccounts } = useSelector(state => state.transactions)
    const newControlAccounts = controlAccounts?.filter(element => element.account_control_type.short_code !== "BULKACC");
    const bulkAccount = controlAccounts?.filter(element => element.account_control_type.short_code === "BULKACC");
    //console.log("New accounts", newControlAccounts, bulkAccount)
    const [shop] = useLocalStorage('shop-details');
    useEffect(() => {
        dispatch(getControlAccounts({ merchantId: AuthService.getUserId(), savingsAccountId: shop.id }))
    }, [dispatch])


    const [merchant] = useLocalStorage(`${appName}_user`)
    //

    //
    const [open, setOpen] = useState(false)
    const methods = useForm({
        resolver: yupResolver(InternalTransferSchema),
        defaultValues,
    });

    //
    const { handleSubmit, reset, formState: { isSubmitting }, getValues } = methods;

    const onSubmit = async () => {

        const { amount, from_account_control, } = getValues()
        const payload = {
            amount: parseInt(amount),
            from_account_control,
            to_account_control: bulkAccount[0].id
        }

        const response = await call('post', TransactionConstants.CONTROL_ACCOUNTS({ merchantId: merchant.id, savingsAccountId: shop.id }), payload)

        if (response.data.status) {
            notifySuccess(response.data.message)
            setOpen(false)

            reset()
            dispatch(getControlAccounts({ merchantId: merchant.id, savingsAccountId: shop.id }))
        }
    }

    return (
        <Card sx={{ mb: 3 }}>
            <CardHeader
                title="Accounts Balances Summary"
                action={
                    <Button size="large" onClick={() => setOpen(!open)} startIcon={<Iconify icon={'eva:edit-fill'} />}>
                        Move Funds
                    </Button>
                }
            />
            <CardContent>
                <Stack spacing={2}>

                    {controlAccounts?.map(({ id, account_balance, account_control_type }) => (
                        <Stack key={id} direction="row" justifyContent="space-between">
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                {account_control_type?.name}
                            </Typography>
                            <Typography variant="subtitle2">{fCurrency(account_balance,shop.currency_code)}</Typography>
                        </Stack>
                    ))
                    }
                </Stack>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                >

                    <Dialog
                        maxWidth='sm'
                        fullWidth
                        TransitionComponent={Transition}
                        open={open}
                        onClose={() => setOpen(false)}>

                        <DialogTitle>Fund Movement</DialogTitle>
                        <DialogContent>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Stack spacing={2} mt={2}>
                                    <RHFTextField
                                        size={'small'}
                                        name="from_account_control"
                                        label='From Account'
                                        select
                                    >
                                        {
                                            newControlAccounts?.map(el => {
                                                return (
                                                    <MenuItem key={el.id} value={el.id}>{el.account_control_type?.name}</MenuItem>
                                                )
                                            })
                                        }
                                    </RHFTextField>

                                    <RHFTextField size={'small'} name="amount" label='Amount' />
                                    <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>
                                        <LoadingButton loading={isSubmitting} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                                            Submit
                                        </LoadingButton>
                                    </Stack>
                                </Stack>
                            </FormProvider>
                        </DialogContent>
                    </Dialog>

                </Backdrop>
            </CardContent>
        </Card>
    );
}
