import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../../packages/auth/view/LoginPage';
// components
import LoadingScreen from '../components/LoadingScreen';
import AuthService from "../../core/access-control/AuthService";
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {

  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  let timeout = null;

  const navigate = useNavigate()

  useEffect(() => {
    // initiate timeout
    restartAutoReset();
    // listen for mouse events
    // window.addEventListener('onmousemove', onMouseMove);   i'll sit with you
  }, [pathname])

  const restartAutoReset = () => {

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      // Insert your code to reset you app here
      toast.error("Your session has timed out due to inactivity. Please log in again to continue using the system.", { delay: 60000, progress: true, autoClose: false, pauseOnHover: true })
      // navigate('/auth/login', { replace: true })
      AuthService.logout()
    }, 60000 * 60); // 5 mins
  };

  const onMouseMove = (e) => {
    restartAutoReset();
  };

  if (!AuthService.check()) {
    //console.log("It is not useless", !AuthService.check())
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <div onMouseMove={onMouseMove}>{children}</div>;
}
