import {
    GET_SHOP_INVOICES,
    GET_SHOP_INVOICES_API_FAILED,
    GET_SHOP_INVOICES_API_SUCCESS,
    PUSH_INVOICES
} from "./InvoiceActionTypes";

const initialState = {
    loading: false,
    message: "",
    invoices: [],
    error: {},
    response: {}
}
export default function InvoiceReducer(state = initialState, action) {
    const { type, payload, message, loading, error, data } = action;
    switch (type) {
        case GET_SHOP_INVOICES:
            return {
                loading,
            };
        case GET_SHOP_INVOICES_API_SUCCESS:
            return {
                loading,
                message,
                invoices: payload,
                data: data,
                response: data
            }
        case GET_SHOP_INVOICES_API_FAILED:
            return {
                loading,
                error
            };
        case PUSH_INVOICES:
            return {
                loading,
                error
            };
        default:
            return state
    }
}