import Navigation from 'src/layouts/dashboard/nav';
import { paybillLinks, dealerLinks, jaziaSponsors, jaziCoLinks, jaziaProviderLinks, jaziaCoTills, jaziaSponsornProvider } from 'src/shared/layouts/shopLayout/nav/config';


import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Header from './header';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { useEffect } from 'react';
import Iconify from "../../../shared/components/Iconify";
import { filter } from 'lodash';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
// import Nav from './nav';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  backgroundColor: "white",
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ShopLayout() {
  const [open, setOpen] = useState(false);
  const [shop,] = useLocalStorage('shop-details')


  const { scopes } = shop;

  const checkScopeJazia = (scope) => scope.name === "JAZIA-PROVIDER";
  const jazia = scopes?.some(checkScopeJazia);

  const checkScopeSponsor = (scope) => scope.name === "JAZIA-SPONSOR";
  const jaziaSponsor = scopes?.some(checkScopeSponsor);

  const [config, setConfig] = useState()

  useEffect(() => {
    getConfig()
  }, [])

  const getConfig = () => {
    let config = [];
    if (shop.product_short_name.includes('PBBO') || shop.product_short_name.includes('PBCO') || shop.product_short_name.includes('WAAS')) {
      config = paybillLinks

      if (!jazia) {
        config = jaziCoLinks
      }
    } else {
      config = jaziaCoTills
    }

    if (shop.product_short_name.includes('DL')) {
      config = dealerLinks

    }

    if (jaziaSponsor) {
      config = jaziaSponsors
    }

    if (jazia) {
      config = jaziaProviderLinks
    }

    if (jaziaSponsor && jaziaProviderLinks) {
      config = jaziaSponsornProvider
    }
    const FINTECH = scopes?.some((scope) => scope.name === "FINTECH");

    if (FINTECH) {

      const any = filter(config, p => p.title === 'Fintech')

      if (any.length === 0) {
        config.push({
          title: 'My Fintech',
          path: '/shop-dashboard/savings-account/beneficiary/beneficiarypage',
          icon: getIcon('mdi-users'),
        })
      }

    }

    setConfig(config)
  }

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />

      {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
      <Navigation openNav={open} isShop={false} navConfig={config} onCloseNav={() => setOpen(false)} />

      <Main>
        <Outlet />
      </Main>
    </StyledRoot>
  );
}