import { USERS_API_FAILED, USERS_API_REQUEST, USERS_API_SUCCESS, CUSTOMERS_API_REQUEST, CUSTOMERS_API_SUCCESS, CUSTOMERS_API_FAILED } from "./SharedUsersActionTypes";

const initialState = {
    loading: false,
    users: [],
    accountTypes: [],
    error: {},
    customers: []
}
export default function SharedUsersReducer(state = initialState, action) {
    //
    const { type, payload, loading, error } = action;
    switch (type) {
        case USERS_API_REQUEST:
            return {
                loading,
            }
        case USERS_API_SUCCESS:
            return {
                loading,
                users: payload.results.account_operators,
                accountTypes: payload.results.account_operator_type
            }
        case USERS_API_FAILED:
            return {
                loading,
                error
            }
        case CUSTOMERS_API_REQUEST:
            return {
                loading,
            }
        case CUSTOMERS_API_SUCCESS:
            return {
                loading,
                customers: payload.customers,

            }
        case CUSTOMERS_API_FAILED:
            return {
                loading,
                error
            }
        default:
            return state
    }
}