import { lazy, Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";

import LoadingScreen from "../../../shared/components/LoadingScreen";
import AuthGuard from "../../../shared/guards/AuthGuard";
import ShopLayout from "../../../shared/layouts/shopLayout";
import Escrow from "src/packages/escrow/view/Escrow";
import SplitBill from "src/packages/splitbill/views/SplitBill";
// import TransactionsApproval from "../components/TransactionsApproval";
import SaccoView from "src/packages/sacco/view/SaccoView";
import ExcelTemplatePage from "src/packages/beneficiary/view/BulkInvitePreparation";
import Successful from "src/packages/dealer/components/Successful";


const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();

    
    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
            <Component {...props} />
        </Suspense>
    );
};
const Shop = Loadable(lazy(() => import('../view/Shop')));
const InternalFundMovement = Loadable(lazy(() => import('../../transactions/components/InternalFundMovement')));
const SendMoney = Loadable(lazy(() => import('../../transactions/components/SendMoney')));
const TransactionsStatement = Loadable(lazy(() => import('../../transactionsStatement/view/TransactionsStatement')))
const TransactionDetails = Loadable(lazy(() => import('../../transactionsStatement/view/TransactionDetails')))
const Operators = Loadable(lazy(() => import('../../users/view/Operators')));
const BillPresentation = Loadable(lazy(() => import('../../bill-presentment/view/BillPresentation')))
const Customers = Loadable(lazy(() => import("../../users/view/Customers")))
const Beneficiary = Loadable(lazy(() => import("../../beneficiary/view/Beneficiarypage")))
const Details = Loadable(lazy(() => import("../../beneficiary/components/Details")))
const Jaziaurl = Loadable(lazy(() => import("../../urlsettings/view/Jaziaurl")))
const UrlTable = Loadable(lazy(() => import("../../urlsettings/view/Urltable")))
const Products = Loadable(lazy(() => import("../../jazia/view/Products")))
const Merchantt = Loadable(lazy(() => import("../../beneficiary/components/ManualMerchantInviteProcess")))
const Jazia = Loadable(lazy(() => import("src/packages/jazia/view/JaziaProvider")))
const JaziaLoans = Loadable(lazy(() => import("src/packages/jazia/view/JaziaLoans")))
const JaziaConsumer = Loadable(lazy(() => import('../../jazia/view/JaziaConsumer')))
const JaziaSponsors = Loadable(lazy(() => import('../../jazia/view/JaziaSponsors')))
const NewSubscribers = Loadable(lazy(() => import('../../jazia/view/NewSubscribers')))
const JaziaProvider = Loadable(lazy(() => import('../../jazia/JaziaProviderNew/JaziaProviders')))
const TransactionTable = Loadable(lazy(() => import("../../bill-presentment/components/TransactionTable")))
const CashBack = Loadable(lazy(() => import("../../cashBack/view/CashBack")))
// const ExcelTemplatePage = Loadable(lazy(() => import("../..//beneficiary/BulkInvitePreparation.js")))
const AccountSettings = Loadable(lazy(() => import("../../account-settings/view/Basigoo")))
const TransactionsApproval = Loadable(lazy(() => import("../components/TransactionsApproval")))
const AddOperator = Loadable(lazy(() => import('./../../users/view/Addoperator')));
const ApiRequests = Loadable(lazy(() => import("../../apis/view/ApiRequests"))); 
const MainSettings =Loadable(lazy(() => import('../../../shared/layouts/MainSettings')));


export const ShopRoutes = [
    // Dashboard Routes
    {
        path: 'shop-dashboard',
        element: (
            <AuthGuard>
                <ShopLayout />
            </AuthGuard>
        ),
        
        children: [
            { path: 'savings-account', element: <Shop /> },
            { path: 'savings-account/internal-fund-movement', element: <InternalFundMovement /> },
            { path: 'savings-account/send-money', element: <SendMoney /> },
            { path: 'savings-account/transactions-statement', element: <TransactionsStatement /> },
            { path: 'savings-account/transactions-statement/:id', element: <TransactionDetails /> },
            { path: 'savings-account/users/operators', element: <Operators /> },
            { path: 'savings-account/users/add-operator', element: <AddOperator /> },
            { path: 'savings-account/bill-presentation', element: <BillPresentation /> },
            { path: 'savings-account/bill-transactions', element: <TransactionTable /> },
            { path: 'savings-account/users/customers', element: <Customers /> },
            { path: 'savings-account/beneficiary/beneficiarypage', element: <Beneficiary /> },
            { path: 'savings-account/urlsettings/jaziaurl', element: <Jaziaurl /> },
            { path: 'savings-account/urlsettings/urltable', element: <UrlTable /> },
            { path: 'savings-account/beneficiary/details/:id', element: <Details /> },
            { path: 'savings-account/jazia', element: <Jazia /> },
            { path: 'savings-account/jazia/products', element: <Products /> },
            { path: 'savings-account/jazia-consumer', element: <JaziaConsumer /> },
            { path: 'savings-account/jazia-sponsored-loans', element: <JaziaSponsors /> },
            { path: 'savings-account/jazia/JaziaProviderNew/JaziaProviders', element: <JaziaProvider /> },
            { path: 'savings-account/jazia/view/NewSubscribers', element: <NewSubscribers /> },
            { path: 'savings-account/jazia-loans', element: <JaziaLoans /> },
            { path: 'savings-account/cashback', element: <CashBack /> },
            { path: 'savings-account/escrow', element: <Escrow /> },
            { path: 'savings-account/splitbill', element: <SplitBill /> },
            { path: 'savings-account/beneficiary/BulkInvitePreparation', element: <ExcelTemplatePage /> },
            { path: 'savings-account/account-settings/basigoo', element: <AccountSettings /> },
            { path: 'savings-account/transactions-approval', element: <TransactionsApproval /> },
            { path: 'savings-account/settings', element: <MainSettings /> },
            { path: 'savings-account/apis', element: <ApiRequests /> },
            { path: 'savings-account/sacco', element: <SaccoView /> },
        ],
    },

];  