import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
import Iconify from 'src/shared/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse, shop }) {
    return (

        <RootStyle
            sx={{
                ...(isCollapse && {
                    bgcolor: 'transparent',
                }),
                color: (theme) => theme.palette['primary'].darker,
                bgcolor: "inherit",
                mx: 2
            }}
        >
            <Iconify icon="material-symbols:store-outline-rounded" width={32} height={32} />
            <Box
                sx={{

                    ml: 2,
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            duration: theme.transitions.duration.shorter,
                        }),
                    ...(isCollapse && {
                        ml: 0,
                        width: 0,
                    }),
                }}
            >
                <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                    {shop?.description}
                </Typography>
                <Typography variant="subtitle2" noWrap>
                    {shop?.account_number}
                </Typography>
            </Box>
        </RootStyle>
    );
}
