import { Box, Typography, Button, Card, CardContent, Tabs, Grid, Stack, InputAdornment, ListItemText, MenuItem, OutlinedInput, InputLabel, FormControl, Select, DialogContent, DialogActions, Tab } from '@mui/material';
import React, { useState } from 'react'

import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { withStyles } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { filter, upperCase } from 'lodash';
import UserConstants from 'src/packages/users/store/UserConstants';
import call from 'src/core/services/http';
import { FormProvider, RHFTextField } from "../../../../../shared/components/hook-form";
import TransactionConstants from '../../../store/TransactionConstants';
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';
import { CenteredCard } from 'src/shared/components/global';



const defaultValues = {
    recipient_account_number: '',
    sender_account_number: '',
    amount: '',
    reason: '',
    channel_code: '',
    option: '0',
    alias: '',
    bill_number: ''
}

const options = [
    {
        key: '0',
        label: 'SasaPay Business Account',
    },
    {
        key: '1',
        label: 'SasaPay Mobile Number',
    },
    // {
    //     key: '2',
    //     label: 'PesaPay Alias Number',
    // },
    // {
    //     key: '3',
    //     label: 'MPESA Paybill Number',
    // },
    // {
    //     key: '4',
    //     label: 'MPESA Till Number',
    // }
]

const ValidationSchema = Yup.object().shape(
    {
        recipient_account_number: Yup.string()
            .when("option", {
                is: (option) => option === "1" || option === "0",
                then: Yup.string().required(`Please enter the account number`),
            }),

        alias: Yup.string()
            .when("option", {
                is: '2',
                then: Yup.string().required("Please enter the alias number"),
            }),
        bill_number: Yup.string()
            .when("option", {
                is: (option) => option === "3" || option === "4",
                then: Yup.string().required("Please enter the Paybill/Till number"),
            }),

        sender_account_number: Yup.string(),
        amount: Yup.string().required("Please enter the amount"),
        reason: Yup.string().when("option", {
            is: (option) => option === "3",
            then: Yup.string().required("Please enter the account number"),
        }),
        option: Yup.string().required("Select a payemnt option"),
        channel_code: Yup.string()
    }
)
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const inputSwich = [
    {
        key: '0',
        name: 'recipient_account_number',
        label: 'PayBill/Till Number'
    },
    {
        key: '1',
        name: 'recipient_account_number',
        label: 'SasaPay Mobile Number'
    },
    {
        key: '2',
        name: 'alias',
        label: 'SasaPay Alias Number'
    },
    {
        key: '3',
        name: 'bill_number',
        label: 'MPESA Paybill Number',
        transaction_type: 'PAYBILL'
    },
    {
        key: '4',
        name: 'bill_number',
        label: 'MPESA Till Number',
        transaction_type: 'TILL'
    }
]



function LocalTransfers() {
    const [shop] = useLocalStorage('shop-details')
    const methods = useForm({
        resolver: yupResolver(ValidationSchema),
        defaultValues,
    });

    const { handleSubmit, reset, setValue, watch,
        formState: { isSubmitting } } = methods;
    const values = watch()



    const { option, amount, alias, reason, recipient_account_number, bill_number } = values
    const payementOptionInputContol = filter(inputSwich, p => p.key === option)[0]

    const [open, setOpen] = useState(false)
    const [confirmation, setConfirmation] = useState('')
    const [requires_jazia, set_requires_jazia] = useState(false)
    const [subscription_id, set_subscription_id] = useState('')
    const onSubmit = async () => {
        const payload = {
            sender_account_number: shop.account_number,
            bill_number,
            recipient_account_number,
            reason,
            reference_number: reason,
            amount,
            transaction_type: payementOptionInputContol.transaction_type || undefined
        };
        let url = ''
        const key = payementOptionInputContol.key
        if (key === '0') {
            //SP paybill/till number
            url = open ? (requires_jazia ? TransactionConstants.PROCESS_JAZIA(subscription_id) : TransactionConstants.SENDMONEYB2B) : `${TransactionConstants.SENDMONEYB2B}confirm/`
        } else if (key === '1') {
            //SP account number
            url = open ? TransactionConstants.SENDMONEYB2C : `${TransactionConstants.SENDMONEYB2C}confirm/`
        } else if (key === '2') {
            //SP alias
            url = open ? '' : `${TransactionConstants.SENDMONEYB2C}confirm/`
        } else if (key === '3' || key === '4') {
            //MPESA Paybill
            url = open ? TransactionConstants.SENDMONEYMB2C : `${TransactionConstants.SENDMONEYMB2C}confirm/`
        }
        const response = await call("post", url, payload).catch((error)=>{
            
        })
        if (response.data.status) {
            if (key === "0") {
                if (response.data.requires_jazia) {
                    set_requires_jazia(response.data.requires_jazia || false)
                    set_subscription_id(response.data.subscription_id)
                }
            }

            setConfirmation(response.data.message)
            setOpen(!open)

            if (open) {
                reset()
                setDisplay('')
                toast.success(response.data.message)
            }
        } else {
            toast.error(response.data.message)
        }

    }


    const [display, setDisplay] = useState('')

    const searchInput = async () => {
        if (payementOptionInputContol.key === '0') {
            const response = await call('post', TransactionConstants.MERCHANTCODE, { bill_number: recipient_account_number })
            if (response.status) {
                setDisplay(response.data.merchant.description)
            }
        } else if (payementOptionInputContol.key === '1') {
            const response = await call('post', UserConstants.SEARCH_USER, { mobile_number: recipient_account_number })
            let data = response.data
            if (data.status) {
                data = data.results.clients[0]
                setDisplay(data.display_name)
                setValue('recipient_account_number', data.mobile_number)
            } else {
                setValue('recipient_account_number', '')
            }
        } else if (payementOptionInputContol.key === '2') {
            const response = await call('post', UserConstants.SEARCH_ALIAS, { alias_number: alias })
            let data = response.data
            if (data.status) {
                data = data.results.clients
                setDisplay(data.display_name)
                setValue('recipient_account_number', data.mobile_number)
            } else {
                setValue('recipient_account_number', '')
            }

        }
    }
    return (
        <>
            <CenteredCard>
                <CardContent>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <CustomDialog onClose={() => setOpen(false)}
                            maxWidth='sm'
                            fullWidth
                            aria-labelledby="dialog"
                            open={open}>
                            <CustomDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                                Confirm
                            </CustomDialogTitle>
                            <DialogContent>
                                {confirmation}
                            </DialogContent>
                            <DialogActions>
                                <LoadingButton onClick={handleSubmit(onSubmit)} variant='contained' size='small' loading={isSubmitting} color='info' >Confirm</LoadingButton>
                                <Button size='small' onClick={() => setOpen(false)} color='error' variant='contained'>Cancel</Button>
                            </DialogActions>
                        </CustomDialog>

                        <Stack spacing={2}>
                            <FormControl>
                                <InputLabel id="countries-multiple-checkbox-label">Payment Option</InputLabel>
                                <Select
                                    labelId="optionlabelId"
                                    id="optionId"
                                    value={values.option}
                                    onChange={(e) => {
                                        reset()
                                        setDisplay("")
                                        setValue('option', e.target.value)
                                    }}
                                    name='option'
                                    input={<OutlinedInput label="Payment Option" />}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="0" disabled>---SELECT---</MenuItem>
                                    {options.map(({ key, label }) => (
                                        <MenuItem key={key} value={key}>
                                            <ListItemText primary={label} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <RHFTextField
                                // onKeyUp={searchInput}
                                onBlur={searchInput}
                                size={'medium'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"><Typography sx={{ color: 'green' }} variant='caption'>{upperCase(display)}</Typography></InputAdornment>
                                    ),
                                }}
                                name={payementOptionInputContol.name || ''}
                                label={payementOptionInputContol.label || ''}
                            />
                            <RHFTextField
                                size={'medium'}
                                name="reason"
                                label='Account Number/Reference'
                            />
                            <RHFTextField
                                name="amount"
                                size={'medium'}
                                label='Amount'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{shop.product_currency_code}</InputAdornment>
                                    ),
                                }}
                            />

                            <LoadingButton variant='contained' size='medium' loading={isSubmitting} fullWidth type='submit'>Send Money</LoadingButton>
                        </Stack>
                    </FormProvider>
                </CardContent>
            </CenteredCard>
        </>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default LocalTransfers