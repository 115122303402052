import { AUTH_API_FAILED, VERIFY_AUTH_API_FAILED, OPERATOR_AUTH_API_FAILED, AUTH_LOGOUT_REQUEST, AUTH_API_REQUEST, OPERATOR_AUTH_API_SUCCESS, VERIFY_AUTH_API_SUCCESS, AUTH_API_SUCCESS } from "./AuthActionTypes";

const initialState = {
    loading: false,
    error: {},
    showSignup: true,
    showOTP: false,
    isOperator: false,
}
export default function AuthReducer(state = initialState, action) {
    const { type, payload, isOperator, showSignup, showOTP, loading, error, } = action;
    switch (type) {
        case AUTH_API_REQUEST:
            return {
                ...state,
                loading
            };
        case AUTH_API_SUCCESS:
            return {
                ...state,
                loading,
                payload,
                showSignup,
                showOTP,
            }
        case VERIFY_AUTH_API_SUCCESS:
            return {
                ...state,
                loading,
                payload,
                isOperator,
            }
        case OPERATOR_AUTH_API_SUCCESS:
            return {
                loading,
                payload,
                isOperator: true,
                error: {},
                showSignup: true,
                showOTP: false,
            }

        case OPERATOR_AUTH_API_FAILED:
            return {
                loading,
                payload,
                isOperator: true,
                error: {},
                showSignup: true,
                showOTP: false,
            }

        case AUTH_API_FAILED:
            return {
                ...state,
                loading,
                error,
                showSignup,
                showOTP,
            };

        case VERIFY_AUTH_API_FAILED:
            return {
                ...state,
                loading,
                error,
                showSignup: false,
                showOTP: true,
            };

        case AUTH_LOGOUT_REQUEST:
            return {
                loading,
                payload,
                isOperator: false,
                error: {},
                showSignup: true,
                showOTP: false,

            }
        default:
            return state
    }
}