import * as React from 'react';
import call from 'src/core/services/http';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import TransactionsStatementTable from '../components/TransactionsStatementTable';
import useIsMountedRef from 'use-is-mounted-ref';


export default function TransactionsStatementShop(props) {
    const isMountedRef = useIsMountedRef()

    const [shop] = useLocalStorage('shop-details')
    const [loading, setFetching] = useState(false)
    const [transactions, setTransactions] = useState({})

    const getTransactions = useCallback(async () => {
        if (isMountedRef.current) {
            await getData(`/transactions/all-transactions/?page=1&page_size=7&account_number=${shop.account_number}`)
        }
    }, [isMountedRef])

    useEffect(() => {
        getTransactions()
    }, [getTransactions])



    async function getData(url, isPaginating = false) {
        setFetching(true)

        const response = await call("get", url);

        setTransactions(response.data)

        setFetching(false)
    }


    return (
        <TransactionsStatementTable
            headers={[
                { id: 1, key: 'account', label: '' },
                { id: 0, key: 'account', label: '' },
                { id: 7, key: 'account', label: 'Account' },
                { id: 2, key: 'amount', label: 'Amount' },
                { id: 4, key: 'created_on', label: 'Initiation Time' },
                { id: 5, key: 'transaction_code', label: 'Transaction Code' },
                { id: 6, key: 'status', label: 'Status' },
            ]}
            items={transactions.data && transactions.data.transactions || []}
        />
    );
}
