// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SHOP = '/shop-dashboard';


// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),

  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  landing: '/',
  bulkpayment: '/bulk-payment',
  c2b: '/customer-to-business',
  b2c: '/business-to-customer',
  b2b: '/business-to-business',

  invoicing: '/invoicing',
  escrow: '/escrow',
  page404: '/404',
  page500: '/500',
  loyalty: '/loyalty-program',
  customer: '/customer-directory',
  api: '/api',
  marketplace: '/market-place',
  linkpayment: '/link-payment',
  smspayment: '/sms-payment',
  checkout: '/checkout',
  utilitypayment: '/utility-payment',
  billpresentment: '/bill-presentment',
  mandatepayment: '/mandate-payment'

};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    shops: path(ROOTS_DASHBOARD, '/my-shops')
  },

};

export const PATH_SHOP = {
  root: ROOTS_SHOP,
  shop: {
    operators: path(ROOTS_SHOP, '/savings-account/users/operators'),
    add_operator: path(ROOTS_SHOP, '/savings-account/users/add-operator'),
    beneficiary: path(ROOTS_SHOP, '/savings-account/beneficiary/registered'),
    accountSetting: path(ROOTS_SHOP, '/savings-account/accset'),
    main: path(ROOTS_SHOP, '/savings-account'),
    billPresentment: path(ROOTS_SHOP, '/savings-account/bill-presentation'),
    jazia: path(ROOTS_SHOP, '/savings-account/jazia'),
    bulkPayment: path(ROOTS_SHOP, '/savings-account/bulkpayment'),
    approval: path(ROOTS_SHOP, '/savings-account/transactions-approval'),
    invoice: path(ROOTS_SHOP, '/savings-account/invoice'),
    transferFund: path(ROOTS_SHOP, '/savings-account/transfer-fund'),
    internalFundMovement: path(ROOTS_SHOP, '/savings-account/internal-fund-movement'),
    sendMoney: path(ROOTS_SHOP, '/savings-account/send-money'),
    sendMoneyB2B: path(ROOTS_SHOP, '/savings-account/send-money-b2b'),
    sendMoneyB2C: path(ROOTS_SHOP, '/savings-account/send-money-b2c'),
    transactionsStatement: path(ROOTS_SHOP, '/savings-account/transactions-statement'),
    viewTransaction: (id) => path(ROOTS_SHOP, `/savings-account/transactions-statement/${id}`),
    cashBack: path(ROOTS_SHOP, '/savings-account/cashback'),
    shopSettings: path(ROOTS_SHOP, '/savings-account/shop-settings'),
  },
}

