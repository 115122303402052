import React from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,

} from "@mui/material";
import useTabs from "../../../shared/hooks/useTabs";
import { capitalCase } from "change-case";
import Page from "../../../shared/components/Page";
import SendMoney from "../components/SendMoney";
import Title from "src/shared/Text/Title";
import SaccoBulkView from "./saccoBulkView"


export default function Escrow() {
  const { currentTab, onChangeTab } = useTabs('Single Sacco Payment');


  const TABS = [
    {
      value: 'Single Sacco Payment',
      component: <SendMoney />,
    },
    {
        value: ' Sacco Bulk Payment',
        component: <SaccoBulkView />,
      },
  
  ];


  return (
    <Page title="Sacco payment">
      <Box sx={{ m: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: "space-between", mb: 2 }} >
          <Title text="Sacco payment" sx={{ color: 'primary' }} />
        </Box>
      </Box>
      <Grid item xs={12} md={12}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>
        </div>
        <Box sx={{ mb: 5 }} />
        {TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Grid>


    </Page>
  );
}
