import {
    Backdrop,
    Chip,
    Card,
    CardContent,
    CardHeader,
    Container,
    Box,
    Grid,
    DialogContent,
    DialogTitle, Slide, Stack,
    Table,
    TableBody,
    TableContainer, TableHead,
    Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton } from "@mui/lab";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import AuthService from "src/core/access-control/AuthService";
import { getControlAccounts, sendMoney } from "../../store/TransactionsActions";
import { StyledTableCell, StyledTableRow } from "../../../../shared/sections/@dashboard/tableStyle/tableBody";
import { FormProvider, RHFTextField } from "../../../../shared/components/hook-form";
import { fCurrency } from "src/shared/utils/formatNumber";
import call from "src/core/services/http";
import BillNumberConstants from "src/shared/reducers/BillNumber/BillNumberConstants";
import TransactionsReducer from "../../store/TransactionReducer";
import TransactionsStatementConstants from "src/packages/transactionsStatement/store/TransactionsStatementConstants";
import TransactionConstants from "../../store/TransactionConstants";
//
const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
//
const InternalTransferSchema = Yup.object().shape({
    recipient_account_number: Yup.string(),
    amount: Yup.string().required('Provide an amount'),
});

const defaultValues = {
    amount: '',
    sender_account_number: "11150",
    recipient_account_number: "",
    reason: "",
};
export default function B2B() {
    const [shop] = useLocalStorage('shop-details');
    //
    const { loading, controlAccounts } = useSelector(state => state.transactions)
    //
    const dispatch = useDispatch()
    //
    const [open, setOpen] = useState(false)
    //
    // const onSubmit = () => {
    //     dispatch(sendMoney(getValues()))
    // }
    //
    useEffect(() => {
        dispatch(getControlAccounts({ merchantId: AuthService.getUserId(), savingsAccountId: shop.id }))
    }, [dispatch])
    //
    const methods = useForm({
        resolver: yupResolver(InternalTransferSchema),
        defaultValues,
    });
    const { handleSubmit, reset, setValue, getValues } = methods;


    // Search bill number
    const [searchingBillNumber, setSearchingBillNumber] = useState("")
    const [billNumber, setBillNumber] = useState("")
    const [sendTo, setSendTo] = useState(null)
    const [errorMessage, setErrorMessage] = useState("")
    const [searchedSuccess, setSearchedSuccess] = useState(false)
    const searchBillNumber = async () => {
        if (billNumber === "") {
            setErrorMessage("Business Account number is required")
        }
       
        else {
            setSearchingBillNumber(true)
            const response = await call('post', TransactionConstants.MERCHANTCODE, { bill_number: billNumber })
            setSearchingBillNumber(false)
            if (response.data.status) {
                setValue("recipient_account_number", billNumber)
                setSendTo(`Send money to ${response.data.merchant.description}`)
                setSearchedSuccess(true)
            }
         //   console.log(response)
            else {
              
                toast.error(response.data.message)} 
        }

    }
    const [completedMessage, setCompletedMessage] = useState("")
    const [confirmMessage, setConfirmMessage] = useState("")

    const onSubmit = async (btnName) => {
        const { amount, recipient_account_number, reason } = getValues()

        const payload = {
            sender_account_number: shop.account_number,
            recipient_account_number,
            reason,
            amount
        };

        if (btnName === "confirm") {
            setConfirming(true)
            const res = await call("post", TransactionConstants.SENDMONEYB2B, payload);
            if (res.data.status) {
                setConfirming(true)
                setCompletedMessage(res.data.message)
                toast.success(res.data.message)
                setShowConfirm(false)
                setShowInput(false)
                setShowDone(true)
            }
             else {
                // setConfirming(false)
                // setCompletedMessage(res.data.message)
                // toast.success(res.data.message)
         
                toast.error(res.data.message)
            }
        } else {
            const response = await call("post", `${TransactionConstants.SENDMONEYB2B}confirm/`, payload);
            if (response.data.status) {
                setConfirmMessage(response.data.message)
                setShowConfirm(true)
                setShowInput(false)
            } else {
              
              toast.error(response.data.message)
            }
        }

    };


    // track status based on stage
    const [showInput, setShowInput] = useState(true);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDone, setShowDone] = useState(false);

    const [confirming, setConfirming] = useState(false)


    const onDoneClicked = () => {
        setShowInput(true)
        setShowConfirm(false)
        setShowDone(false)
        setSendTo("")
        setSearchedSuccess(false)
        setBillNumber("")
        reset()
    }
    //
    return (
        <Container maxWidth='xl'>
            {/* <ToastContainer theme={"colored"} /> */}

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item sx={{ xs: { display: 'none' }, lg: { display: 'block' } }} lg={4}></Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Card>
                        <CardContent>
                            {showInput && <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Stack spacing={2} mt={2}>

                                    {!searchedSuccess && <>
                                        <RHFTextField
                                            size={'medium'}
                                            name="recipient_account_number"
                                            label='Business Account Number'
                                            error={errorMessage}
                                            helperText={errorMessage}
                                            value={billNumber}
                                            onChange={e => { setErrorMessage(""); setBillNumber(e.target.value); setSendTo(null) }}

                                        />

                                        <LoadingButton onClick={searchBillNumber} sx={{ mt: 1 }} loading={searchingBillNumber} size="medium" variant="contained">Next</LoadingButton>
                                    </>
                                    }

                                    {sendTo && <Chip label={sendTo} color="success" variant="outlined" />}

                                    {searchedSuccess && <>
                                        <RHFTextField size={'medium'} name="amount" label='Amount' />
                                        <RHFTextField size={'medium'} name="reason" label='Transaction Reference' />

                                        <Stack direction={{ sm: 'column', md: 'row' }} spacing={5} alignContent='space-between'>

                                            <LoadingButton loading={loading} fullWidth size="medium" type="submit" variant='contained' color='primary'>
                                                Submit
                                            </LoadingButton>
                                        </Stack>
                                    </>
                                    }
                                </Stack>
                            </FormProvider>
                            }
                            {showConfirm && <Box sx={{ display: 'flex', justifyContent: 'center', }} >
                                <Stack sx={{ maxWidth: 320 }} direction='column' spacing={2} my={2}>
                                    <Typography >
                                        {confirmMessage}
                                    </Typography>
                                    <LoadingButton name="confirm" onClick={(e) => { onSubmit(e.target.name) }} loading={confirming} variant="contained" size='medium' >Confirm</LoadingButton>
                                </Stack>
                            </Box>
                            }

                            {showDone && <Box sx={{ display: 'flex', justifyContent: 'center', }} >
                                <Stack sx={{ maxWidth: 320 }} direction='column' spacing={2} my={2}>
                                    <Typography >
                                        {completedMessage}
                                    </Typography>
                                    <LoadingButton onClick={() => { onDoneClicked() }} variant="contained" size='medium' >Done</LoadingButton>
                                </Stack>
                            </Box>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {/* <TableContainer >
                        <Table>
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Balance</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {controlAccounts?.map(({ id, account_balance, account_control_type }, index) => (
                                    <StyledTableRow key={id}>
                                        <StyledTableCell component="th" scope="row" align="left">{account_control_type?.name}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">{fCurrency(account_balance)}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
        </Container>
    )
}