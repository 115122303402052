import {
    GET_SHOP_CONFIG,
    GET_SHOP_CONFIG_API_FAILED,
    GET_SHOP_CONFIG_API_SUCCESS,
    PUSH_CONFIG
} from "./TransferFundActionTypes";

const initialState = {
    loading: false,
    message: "",
    my_banks: [],
    error: {},
    response: {}
}
export default function TransferFundReducer(state = initialState, action) {
    const { type, payload, message, loading, error, data } = action;
    switch (type) {
        case GET_SHOP_CONFIG:
            return {
                loading,
            };
        case GET_SHOP_CONFIG_API_SUCCESS:
            return {
                loading,
                message,
                my_banks: payload,
                data: data,
                response: data
            }
        case GET_SHOP_CONFIG_API_FAILED:
            return {
                loading,
                error
            };
        case PUSH_CONFIG:
            return {
                loading,
                error
            };
        default:
            return state
    }
}