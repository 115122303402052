
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import {
  Box, CardHeader, Paper, Grid, Stack, Link, TextField
} from '@mui/material';
import call from 'src/core/services/http';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import Page from "src/shared/components/Page";
import LoansTable from './Tables/Loans';
import { Paginator } from 'src/shared/sharebles/items';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Iconify from './../../../shared/components/Iconify';
import MyAvatar from "../../../shared/components/profile/Avatar";
import { alpha, styled } from '@mui/material/styles';
import Label from 'src/shared/components/Label';



const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));





const CornerlessCard = styled(Card)(({ theme }) => ({
  borderRadius: '0px 0px 0px 0px',
  padding: theme.spacing(0, 0.5, 0.5, 0.5),
}));



const Profile = () => {
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState({})
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState({});
  const { id } = useParams();
  const [shop] = useLocalStorage('shop-details');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = Object.keys(data).map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected({});
  };

  const getSubscriberLoans = async () => {
    try {

      const response = await call('get', `/jazilia/all-subscribers/${shop.id}/subscriber/${id}/`);
      if (response.data.status) {
        setData(response.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getSubscriberLoans();
  }, []);


  const getSubscriberProfile = async () => {
    try {

      const response = await call('get', `/jazilia/all-subscribers/${shop.id}/subscriber/${id}/details/`);
      if (response.data.status) {
        setProfile(response.data);
        console.log(response.data)
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {
    getSubscriberProfile();
  }, []);







  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = async (event, newPage) => {
    const page = newPage + 1
    await getSubscriberLoans(`/jazilia/all-subscribers/${shop.id}/subscriber/${id}/?page=${page}&page_size=${rowsPerPage}`)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    await getSubscriberLoans(`/jazilia/all-subscribers/${shop.id}/subscriber/${id}/?page=${1}&page_size=${parseInt(event.target.value, 10)} `)
    setPage(0);
  };

  return (
    <Page title='Customer Profile'>
      <CardHeader subheader='Manage Account' />
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Card sx={{
            bgcolor: alpha('#1CCAFF', 0.08),
            border: `solid 2px ${'#1CCAFF'}`,
            boxShadow: `inset 0 4px 8px 0 ${alpha('#1CCAFF', 0.24)}`,
          }} >
            <Stack spacing={2} sx={{ p: 3, textAlign: 'center', height: 330 }}>
              <MyAvatar
                displayName={profile.subscriber_details?.display_name}
                // photoURL={imageUrl}
                sx={{
                  mb: 2,
                  mx: 'auto',
                  borderWidth: 2,
                  borderStyle: 'solid',
                  borderColor: 'common.white',
                  width: { xs: 50, md: 50 },
                  height: { xs: 50, md: 50 },
                }}
              />
              <Box
                sx={{
                  ml: { md: 3 },
                  mt: { xs: 1, md: 0 },
                  color: 'common.black',
                  textAlign: { xs: 'center', md: 'left' },
                }}
              >
                <Typography variant="h4">{profile.subscriber_details?.display_name}</Typography>
                <Typography variant="body2" sx={{ color: 'text.primary', opacity: 0.72 }}>
                  Name &nbsp;
                  <Typography sx={{ opacity: 0.72 }}>{profile.subscriber_details?.display_name}</Typography>
                </Typography>
              </Box>
              <Stack direction={'row'} alignContent='space-between' justifyContent={'space-between'}>
                {/* <Typography variant="body2">{capitalCase(account?.product?.name || '')}</Typography> */}
                <Label
                  variant={'filled'}
                  color={(profile.subscriber_details?.client_status === '100' && 'error') || 'info'}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {profile.subscriber_details?.client_status === '300' ? 'Inactive' : 'Active'}
                </Label>
              </Stack>
              <Stack direction="row">
                <IconStyle icon={'ci:user-01'} />
                <Typography variant="body2">
                  Name &nbsp;
                  <Link component="span" variant="subtitle2" color="text.primary">
                    {profile.subscriber_details?.display_name}
                  </Link>
                </Typography>
              </Stack>
              <Stack direction="row">
                <IconStyle icon={'eva:phone-fill'} />
                <Typography variant="body2" color="text.primary">
                  Mobile Number &nbsp;
                  {profile.subscriber_details?.mobile_number}
                </Typography>
              </Stack>
              <Stack direction="row">
                <IconStyle icon={'ci:calendar-days'} />
                <Typography variant="body2">
                  Activation Date &nbsp;
                  {profile.subscriber_details?.activation_date}
                </Typography>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12} md={4} lg={8} spacing={3} sx={{ p: 2 }}>
          <Paper variant="outlined" sx={{ py: 2, textAlign: 'center', height: 330, p: 2 }}>
            <Typography variant="h6">Account Scores</Typography>
            <form>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Total Score &nbsp;
                  </Typography>
                  <TextField
                    value={`${profile.savings_account_scores?.total_score || ''} / 900`}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,

                    }}
                  />

                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Transaction Volume Points &nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.transaction_volume_points || ''}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,

                    }}
                  />

                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Transaction Value Points &nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.transaction_value_points || ''}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,

                    }}
                  />

                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Time Points &nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.time_points || ''}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,

                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Transaction Value&nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.transaction_value_mode_points || ''}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,

                    }}
                  />

                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Loan Volume Points &nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.loan_volume_points}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,
                    }}
                  />

                  <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
                    Loan Value Points &nbsp;
                  </Typography>
                  <TextField
                    value={profile.savings_account_scores?.loan_value_points || ''}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                    sx={{
                      width: '100%',
                      mb: 2,
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        <Grid item xs={12} md={2} lg={2}>
          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', height: 100 }}>
            <Box sx={{ mb: 0.5 }}>
              <ArrowDownwardIcon />
            </Box>

            <Typography variant="h6">Total Loans</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {profile.loan_dashboard?.total_loans}
            </Typography>

          </Paper>
        </Grid>
        <Grid item xs={12} md={2} lg={2}>

          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', height: 100 }}>
            <Box sx={{ mb: 0.5 }}>
              <SwapVertIcon />
            </Box>
            <Typography variant="h6">Active Loans</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {profile.loan_dashboard?.active_loans}
            </Typography>

          </Paper>
        </Grid>


        <Grid item xs={12} md={2} lg={2}>

          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', height: 100 }}>
            <Box sx={{ mb: 0.5 }}>
              {/* <Iconify width={30} height={30} /> */}
              <SwapVertIcon />
            </Box>
            <Typography variant="h6">Paid Loans</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {profile.loan_dashboard?.paid_loans}
            </Typography>

          </Paper>
        </Grid>

        <Grid item xs={12} md={2} lg={2}>

          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', height: 100 }}>
            <Box sx={{ mb: 0.5 }}>
              {/* <Iconify width={30} height={30} /> */}
              <AutorenewIcon />
            </Box>
            <Typography variant="h6">Partially Paid Loans</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {profile.loan_dashboard?.partially_paid_loans}
            </Typography>

          </Paper>
        </Grid>

        <Grid item xs={12} md={2} lg={2}>

          <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center', height: 100 }}>
            <Box sx={{ mb: 0.5 }}>
              {/* <Iconify width={30} height={30} /> */}
              <AttachMoneyIcon />
            </Box>
            <Typography variant="h6">Consumed Amount</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {profile.loan_dashboard?.consumed_amount}
            </Typography>

          </Paper>
        </Grid>


        <Grid item sm={12}>
          <CornerlessCard>
            <CardHeader title='Customer Loans' subheader='View and Manage Loans' />
            <CardContent>
              <LoansTable
                queryConfirmProcessing={false}
                Paginator={<Paginator count={10} loading={false} page={page} rowsPerPage={rowsPerPage} handleChangePage={handleChangePage} handleChangeRowsPerPage={handleChangeRowsPerPage} />}
                rowsPerPage={rowsPerPage}
                page={page}
                list={data?.data}
              />
            </CardContent>
          </CornerlessCard>
        </Grid>
      </Grid>
    </Page>


  )
}

export default Profile;

// import React, { useState, useEffect } from 'react';
// import Grid from '@mui/material/Grid';
// import { Box, Paper, Typography, Container, Tooltip, CardContent, CardHeader, Stack, Card, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
// import Title from 'src/shared/Text/Title';
// import Page from "src/shared/components/Page";
// import { CenteredCard } from 'src/shared/components/global';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import PaidIcon from '@mui/icons-material/Paid';
// import ReceiptIcon from '@mui/icons-material/Receipt';
// const Profile = () => {
//   const [loansData, setLoansData] = useState([]);
//   const [transactionsData, setTransactionsData] = useState([]);
//   const [profileName, setProfileName] = useState(""); 

//   useEffect(() => {
//     // Fetch Profile Name data from an API endpoint
//     fetch('API_ENDPOINT_FOR_PROFILE_NAME')
//       .then((response) => response.json())
//       .then((data) => setProfileName(data.name)) 
//       .catch((error) => console.error('Error fetching Profile Name:', error));

//     // Fetch Loans data from an API endpoint
//     fetch('API_ENDPOINT_FOR_LOANS_DATA')
//       .then((response) => response.json())
//       .then((data) => setLoansData(data))
//       .catch((error) => console.error('Error fetching Loans data:', error));

//     // Fetch Transactions data from an API endpoint
//     fetch('API_ENDPOINT_FOR_TRANSACTIONS_DATA')
//       .then((response) => response.json())
//       .then((data) => setTransactionsData(data))
//       .catch((error) => console.error('Error fetching Transactions data:', error));
//   }, []);

//   return (
//     <>
//       <CardHeader title={<Title variant={'subtitle2'} text='profile' />} />
//       <CenteredCard>
//         <CardContent>
//           <Grid container spacing={3}>
//             <Grid item xs={12} md={4} lg={4}>
//               <Stack sx={{ height: 150 }}>
//                 <AccountCircleIcon sx={{ fontSize: 80 }} />
//                 <Typography>{profileName}</Typography> {/* Display the profile name */}
//               </Stack>
//             </Grid>
//           </Grid>
//         </CardContent>
//       </CenteredCard>
//       <Page sx={{ marginTop: 6 }}>
//         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
//           <Grid item xs={12} md={5} lg={5}>
//             <Card sx={{ p: 3 }}>
//             <PaidIcon sx={{ fontSize: 40 }} />

//               <Typography variant="subtitle2" gutterBottom>
//                 Loans
//               </Typography>

//             </Card>
//           </Grid>
//           <Grid item xs={12} md={5} lg={5}>
//             <Card sx={{ p: 3 }}>
//             <ReceiptIcon sx={{ fontSize: 40 }} />


//               <Typography variant="subtitle2" gutterBottom>
//                 Transactions
//               </Typography>

//             </Card>
//           </Grid>
//         </Grid>
//       </Page>
//     </>
//   );
// };

// export default Profile;

