import {
    Box,
    Button,
    Card,
    CardContent, CardHeader,
    Container,
    Divider,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import Page from "../../../shared/components/Page";
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import Iconify from "../../../shared/components/Iconify";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import { PATH_SHOP } from "src/routes/paths";
import TransactionsStatement from "src/packages/transactionsStatement/view/TransactionsStatement";
import { useSelector } from "react-redux";
import call from "src/core/services/http";
import { useState } from "react";
import AuthService from "src/core/access-control/AuthService";
import { useEffect } from "react";
import RoundedButton from "src/packages/dashboard/components/rounded";
import AppWidgetSummary from "src/packages/dashboard/components/AppWidgetSummary";
import TransactionsStatementShop from "../../transactionsStatement/view/TransactionStatementShop";





//
export default function Shop() {
    //
    const loading = false;
    const navigate = useNavigate()

    const [shop, setShop] = useLocalStorage('shop-details')

    const { isOperator } = shop

    const { scopes } = shop;

    const checkScopeJazia = (scope) => scope.name === "JAZIA-PROVIDER";
    const jazia = scopes?.some(checkScopeJazia);

    //console.log("Scope Jazia", jazia)

    const menuItems = [
        // {
        //     uniqueId: 1,
        //     display: 'Request',
        //     icon: "fa6-solid:hand-holding-dollar",
        //     route: PATH_SHOP.shop.invoice,
        //     caption: 'Payment',
        //     iconSize: { width: 24, height: 24 }
        // },

        {
            uniqueId: 2,
            display: 'Internal',
            icon: 'ci:transfer',
            route: PATH_SHOP.shop.internalFundMovement,
            caption: 'Fund Movement',
            iconSize: { width: 24, height: 24 }
        },
        {
            uniqueId: 3,
            display: 'Transfer',
            icon: 'mdi:bank-transfer-in',
            route: PATH_SHOP.shop.transferFund,
            caption: 'Fund',
            iconSize: { width: 36, height: 36 }
        },
        {
            uniqueId: 4,
            display: 'Send',
            icon: 'bi:send-fill',
            route: PATH_SHOP.shop.sendMoney,
            caption: 'B2C/C2B',
            iconSize: { width: 24, height: 24 }
        },
        // {
        //     uniqueId: 6,
        //     display: 'BulkPayment',
        //     icon: 'uil:money-withdraw',
        //     route: PATH_SHOP.shop.bulkPayment,
        //     caption: 'Disbursement',
        //     iconSize: { width: 24, height: 24 }
        // },
        // {
        //     uniqueId: 8,
        //     display: 'Notification Number',
        //     icon: 'mdi-settings',
        //     route: PATH_SHOP.shop.shopSettings,
        //     caption: 'Notification Number',
        //     iconSize: { width: 24, height: 24 }
        // },

    ]

    useEffect(() => {
        getDashboard()
    }, [])

    //merchants/${merchant_id}/shop/dashboard/?shop_id=${shop_id}
    const [dashboard, setDashboard] = useState({
        currency: "",
        total_money_in: 0,
        total_money_out: 0,
        total_shop_account_balance: 0
    })

    const getDashboard = async () => {
        const request = await call('get', `merchants/${AuthService.getUserId()}/shop/dashboard/?shop_id=${shop.id}`)
        setDashboard(request.data)
    }


    const iconSize = { width: 24, height: 24 }


    return (
        <Page title="Applications">


            <Container sx={{ ml: 4, }} maxWidth="xl">
                {!isOperator && <Box sx={{ mx: 3, my: 2 }}>
                    <CardHeader
                        title={""}
                        action={<Button onClick={() => navigate('/dashboard/my-shops')} variant="outlined" color='primary' startIcon={<Iconify icon="uil:angle-left" />}>Back</Button>}
                    />
                </Box>}
                <Grid container spacing={3}>
                    {menuItems.map(menuItem => {
                        return (
                            <Grid item xs={6} sm={2} md={2}>
                                <RoundedButton iconSize={iconSize} route={menuItem.route} icon={menuItem.icon} handleClick={() => { }} display={menuItem.display} caption={menuItem.caption} />
                            </Grid>
                        )
                    })}

                </Grid>
            </Container>
            <Divider sx={{ mt: 2 }} />
            <Container maxWidth="xl">
                <Grid sx={{ my: 3 }} container spacing={3}>
                    <Grid item xs={12} sm={12} md={4}>
                        <AppWidgetSummary isInt={true} title='Total Shop Balance' currency={dashboard?.currency} value={(dashboard?.total_shop_account_balance)} icon='ic:round-account-balance-wallet' color='primary' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <AppWidgetSummary isInt={true} title='Daily Total Money In' currency={dashboard?.currency} value={dashboard?.total_money_in} icon='ic:twotone-call-received' color='success' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <AppWidgetSummary isInt={true} title='Daily Total Money Out' currency={dashboard?.currency} value={(dashboard?.total_money_out)} icon='ph:arrow-square-out-bold' color='error' />
                    </Grid>
                </Grid>
                <TransactionsStatementShop back={true} />
            </Container>
        </Page >
    )
}