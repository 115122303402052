import Iconify from "../../../shared/components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const ShopLinks = [
   
    {
        title: 'Dashboard',
        path: '/shop-dashboard/savings-account/',
        icon: getIcon('icon-park-solid:laptop'),
    },
    {
        title: 'Transactions Statement',
        path: '/shop-dashboard/savings-account/transactions-statement',
        icon: getIcon('icon-park-solid:transaction'),
    },
    {
        title: 'Charges Statement',
        path: '/shop-dashboard/savings-account/charges',
        icon: getIcon('carbon:cost'),
    },
    {
        title: 'Internal Fund Movement',
        path: '/shop-dashboard/savings-account/internal-fund-movement',
        icon: getIcon('ci:transfer'),
    },
    {
        title: 'Request for Payment(STK)',
        path: '/shop-dashboard/savings-account/invoice',
        icon: getIcon("fa6-solid:hand-holding-dollar"),
    },
    {
        title: 'Send to Mobile Money / Banks',
        path: '/shop-dashboard/savings-account/transfer-fund',
        icon: getIcon('mdi:bank-transfer-in'),
    },
    {
        title: 'Send Money to SasaPay B/s',
        path: '/shop-dashboard/savings-account/send-money',
        icon: getIcon('bi:send-fill'),
    },
    {
        title: 'Bulk Disbursements',
        path: '/shop-dashboard/savings-account/bulkpayment',
        icon: getIcon('uil:money-withdraw'),
    },
    // {
    //     title: 'Bulk Remit',
    //     path: '/shop-dashboard/savings-account/bulk-remit',
    //     icon: getIcon('logos:sendgrid-icon'),
    // },
    {
        title: 'Auto-Settle Funds to Bank',
        path: '/shop-dashboard/auto-settlement-card',
        icon: getIcon('arcticons:dustsettle'),
    },
    {
        title: 'Standing Order to Mobile/Banks',
        path: '/shop-dashboard/schedule-settlement',
        icon: getIcon('material-symbols:auto-awesome'),
    },
    // {
    //     title: 'EsCrow',
    //      path: '/shop-dashboard/savings-account/escrow',
    //      icon: getIcon('ri:shake-hands-fill'),
    //  },
    //  {
    //     title: 'Split Bills',
    //     path: '/shop-dashboard/savings-account/splitbill',
    //     icon: getIcon("uil:bill"),
    // },
    //  {
    //     title: 'CashBack',
    //      path: '/shop-dashboard/savings-account/cashback',
    //      icon: getIcon('mdi-cash-refund'),
    //  },
    //  {
    //     title: 'Sacco Payments',
    //     path: '/shop-dashboard/savings-account/sacco/',
    //     icon: getIcon('bi:send-fill'),
    // },
    {
        title: 'Operators',
        path: '/shop-dashboard/savings-account/users/operators',
        icon: getIcon('mdi-users'),
    },
    // {
    //     title: 'My Customers',
    //     path: '/shop-dashboard/savings-account/users/customers',
    //     icon: getIcon('mdi-users'),
    // },
    // {
    //     title: 'API Requests',
    //     path: '/shop-dashboard/savings-account/apis',
    //     icon: getIcon('tabler:api-app'),
    // },
    {
        title: 'Shop Settings',
        path: '/shop-dashboard/savings-account/account-settings/basigoo',
        icon: getIcon('mdi-users'),
    },
]
