import { useState } from 'react';
import { CircularProgress, Tooltip, Grid, Collapse, Stack, Typography, TableContainer, Table, TableCell, Avatar, MenuItem, TableRow, IconButton, TableHead, TableBody, Button, Box, DialogContent, TextField } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import createAvatar from 'src/shared/components/CreateAvator';

import { StyledTableCell, StyledTableRow } from 'src/shared/sections/@dashboard/tableStyle/tableBody';
import { flattenObject } from 'src/shared/utils/flattenObject';
import { fDateTime } from 'src/shared/utils/formatTime';
import { fCurrency } from 'src/shared/utils/formatNumber';
import Label from 'src/shared/components/Label';
import TableMoreMenu from 'src/shared/components/TableMoreMenu';
import Iconify from '../../../shared/components/Iconify';
import { useNavigate } from 'react-router-dom';
import { PATH_SHOP } from 'src/routes/paths';
import TransactionPDF from './TransactionPDF';
import { CustomCell, CustomRow } from 'src/shared/TableComponents/TableStyles';
import ListHead from 'src/shared/TableComponents/ListHead';
import { sentenceCase } from 'change-case';
import { Link } from 'react-router-dom';
import call from '../../../core/services/http';
import { toast, ToastContainer } from "react-toastify";
import { CustomDialog, CustomDialogTitle } from 'src/shared/sharebles/items';
import useLocalStorage from 'src/shared/hooks/useLocalStorage';
import { LoadingButton } from '@mui/lab';

export default function TransactionsStatementTable(props) {
    const { items, headers, showButton, reload } = props;

    const newItems = [];
    items?.forEach(element => {
        newItems.push(flattenObject(element))
    });

    const ExpandableTableRow = ({ children, expandComponent, row, ...otherProps }) => {
        const [openMenu, setOpenMenuActions] = useState(null);

        const handleOpenMenu = (event) => {
            setOpenMenuActions(event.currentTarget);
        };

        const handleCloseMenu = () => {
            setOpenMenuActions(null);
        };

        const [isExpanded, setIsExpanded] = useState(false);

        const { result_code, transaction_status } = row

        return (
            <>

                <CustomRow {...otherProps}>
                    <CustomCell padding="checkbox">
                        <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </CustomCell>
                    {children}
                    <CustomCell component="th" scope="row" align="left" >
                        <Stack direction="row" alignItems="center" >
                            {/* {getTransactionStatus(result_code,transaction_status)} */}
                            {getTransactionStatus(result_code || '', transaction_status || 'N/A')}
                            <TableMoreMenu
                                open={openMenu}
                                onOpen={handleOpenMenu}
                                onClose={handleCloseMenu}
                                actions={
                                    <MenuItem>
                                        <PDFDownloadLink
                                            document={<TransactionPDF transactionRecord={row} />}
                                            fileName={`Transaction-statement-${row.transaction_code}-${row.created_date}`}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            {({ loading }) => (
                                                <Tooltip title="Download">
                                                    <Button>
                                                        {loading ? <CircularProgress size={24} color="inherit" /> : <> <Iconify icon={'eva:download-fill'} /> Download</>}
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </PDFDownloadLink>
                                    </MenuItem>
                                }
                            />
                        </Stack>
                    </CustomCell>
                </CustomRow>
                {isExpanded && (
                    <CustomRow>
                        <CustomCell padding="checkbox" />
                        {expandComponent}
                    </CustomRow>
                )}
            </>
        );
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('account_name');
    const [selected, setSelected] = useState([]);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = items.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const getTransactionStatus = (result_code, transaction_status) => {
        let color = 'error'

        switch (result_code) {
            // Success
            case 'SP00000':
                color = 'success'
                break;
                
            //Processing
            case 'SP01001':
                color = 'warning'
                break;

            //Failed
            case 'SP01002':
                color = 'error'
                break;

            //Failed

            case 'SP01003':
                color = 'error'
                break;

            //Under Review
            case 'SP01004':
                color = 'info'
                // transaction_status = 'Under Review'
                break


            default: color = 'info';
        }


        return <Label variant='filled' color={color}>{sentenceCase(transaction_status)}</Label>



    }

    const query_status = async (transaction_id) => {
        try {
            const payload = {
                transaction_id: transaction_id,
            };
            const res = await call('post', 'transactions/query-status/', payload);
            if (res.data.status) {
                toast.success(res.data.message)

            } else {

                toast.error(res.data.message)
            }
        } catch (error) {
            console.error(error);

        }
    };

    const [item, setItem] = useState({ description: '' })
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)


    const initiateReversal = (item) => {
        setItem({ ...item, description: '' })
        setOpen(true)
    }


    const [shop] = useLocalStorage('shop-details')
    const submitReversal = async () => {
        setProcessing(true)

        const data = {
            transaction_id: item.id,
            merchant_code: shop?.account_number,
            description: item.description
        }

        const resp = await call('post', '/transactions/request-reversal/', data).catch(e => {
            toast.error(e.response.data.message);
            reset()
        })


        reset()
        if (resp.status === 200) {
            const response = resp.data

            if (response.status) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
        }

    }


    const reset = () => {
        setOpen(false)
        setItem({ ...item, description: '' })
        setProcessing(false)
        reload()
    }


    
    return (
        <>
            <CustomDialog
                onClose={() => setOpen(false)}
                maxWidth='sm'
                fullWidth
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <CustomDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
                    Request Reversal
                </CustomDialogTitle>
                <DialogContent dividers>
                    <Stack >
                        <TextField label="Reversal Comment" maxRows={10} rows={5} multiline value={item.description} onChange={(e) => setItem({ ...item, description: e.target.value })} />
                    </Stack>
                    <LoadingButton sx={{ float: 'right', mt: 2 }} onClick={submitReversal} loading={processing} color='info' variant='contained' size='small'>Submit Request</LoadingButton>
                </DialogContent>
            </CustomDialog>
            <TableContainer sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Table size='small'>
                    <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={headers}
                        rowCount={items && items.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>

                        {newItems?.map((row) => {
                            const { id, account, amount, party_B_display_name, party_B_party_B_account_name, reversal_transaction_code, party_B_third_party_transaction_code, is_reversed, account_operator_operator_display_name, external_reference, result_description, description, charge_amount, transaction_type_enum, party_B_account_number, party_B_party_B_party_B_account_name, running_balance_derived, created_date, transaction_code, result_code, } = row
                            return <ExpandableTableRow
                                key={id}
                                row={row}
                                expandComponent={<>
                                    <CustomCell></CustomCell>
                                    <CustomCell colSpan="11" >

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Balance</Typography>
                                                <Typography align='left' variant="body2">{fCurrency(running_balance_derived)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Charge Amount</Typography>
                                                <Typography align='left' variant="body2">{fCurrency(charge_amount)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Transaction Description</Typography>
                                                <Typography align='left' variant="body2">{description || 'N/A'}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>External Reference</Typography>
                                                <Typography align='left' variant="body2">{party_B_third_party_transaction_code || 'N/A'} - {party_B_party_B_account_name}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Reversed?</Typography>
                                                <Typography align='left' variant="body2">{is_reversed === 1 ? "YES" : "NO"}</Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Transaction Date</Typography>
                                                <Typography align='left' variant="body2">{fDateTime(created_date)}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Merchant Reference</Typography>
                                                <Typography align='left' variant="body2">{external_reference || 'N/A'}</Typography>
                                            </Grid>

                                            <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Result Description</Typography>
                                                <Typography align='left' variant="body2">{result_description || 'null'}</Typography>
                                            </Grid>
                                            {is_reversed === 0 && result_code !== "SP01004" && <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
                                                }}
                                            >
                                                <Button color='error' onClick={() => initiateReversal(row)} variant='contained' size='small'>Request Reversal</Button>
                                            </Grid>}
                                            {reversal_transaction_code !== "" && <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Reversal Transaction Code</Typography>
                                                <Typography align='left' variant="body2">{reversal_transaction_code || 'N/A'}</Typography>
                                            </Grid>
                                            }
                                            {account_operator_operator_display_name && <Grid item xs={12} sm={6} md={4} lg={3}
                                                sx={{
                                                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                                                }}
                                            >
                                                <Typography align='left' variant="subtitle2" sx={{ color: 'text.disabled' }}>Operator</Typography>
                                                <Typography align='left' variant="body2">{account_operator_operator_display_name}</Typography>

                                            </Grid>}


                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                {result_code === 'SP01001' && 'SP01002' && <Button size='small' variant='contained' onClick={() => query_status(id)}>Query Status</Button>}
                                            </Grid>


                                        </Grid>

                                    </CustomCell>
                                </>
                                }
                            >
                                <CustomCell component="th" align="left" sx={{ pr: 0 }} >
                                    <Avatar alt={party_B_display_name} color={createAvatar(party_B_display_name).color} >
                                        {createAvatar(party_B_display_name).name}
                                    </Avatar>
                                </CustomCell>
                                <CustomCell component="th" align="left" sx={{ px: 0 }} >
                                    <Stack>
                                        <Typography variant="subtitle2" noWrap>
                                            {party_B_display_name}
                                        </Typography>
                                        <Typography noWrap variant="body2" sx={{ color: 'text.disabled', cursor: 'pointer' }}>
                                            {party_B_account_number}  {party_B_party_B_account_name}
                                        </Typography>
                                    </Stack>
                                    {account}

                                </CustomCell>

                                <CustomCell component="th" scope="row" align="left" >
                                    {transaction_type_enum === 1 ?

                                        <Typography noWrap variant="body2" sx={{ color: 'green' }}>
                                            +  {fCurrency(amount,shop.product_currency_code)}
                                        </Typography>
                                        : transaction_type_enum === 2 ?
                                            <Typography noWrap variant="body2" sx={{ color: 'red' }}>
                                                - {fCurrency(amount,shop.product_currency_code)}
                                            </Typography>
                                            : ""}
                                </CustomCell>
                                <CustomCell component="th" scope="row" align="left">{fDateTime(created_date)}</CustomCell>
                                <CustomCell component="th" scope="row" align="left">{transaction_code}</CustomCell>
                            </ExpandableTableRow>
                            // <Row
                            //     key={row.id}
                            //     row={row}
                            //     onPrintRow={() => handlePrintRow(row.id)}
                            // />
                        })}
                    </TableBody>
                </Table>
            </TableContainer>



            <Box sx={{ display: { xs: 'block', sm: 'none', } }}>
                {newItems?.map((row) => (
                    <div key={row.id}>
                        <Box sx={{ border: 0, my: 1, boxShadow: 3, borderRadius: 5, p: 2 }}>
                            <Box sx={{ color: (theme) => theme.palette['primary'].dark, display: 'flex', my: 1, justifyContent: "space-between" }}>
                                <Typography variant='body2' >{row.transaction_code}</Typography>
                                <PDFDownloadLink key={row.id}
                                    document={<TransactionPDF transactionRecord={row} />}
                                    fileName={`Transaction-statement-${row.transaction_code}-${row.created_date}`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    {({ loading }) => (
                                        <Tooltip title="Download">
                                            <Button variant='outlined'  >
                                                {loading ? <CircularProgress size={24} color="primary" /> : <Iconify icon={'eva:download-fill'} />}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </PDFDownloadLink>
                            </Box>
                            <dl>
                                {[
                                    { id: 1, key: 'party_B_display_name', label: 'Account' },
                                    { id: 2, key: 'party_B_account_number', label: 'Number' },
                                    { id: 3, key: 'party_B_account_name', label: 'Name' },
                                    { id: 4, key: 'amount', label: 'Amount' },
                                    { id: 5, key: 'transaction_type_enum', label: "Transaction Type" },
                                    { id: 6, key: 'running_balance_derived', label: 'Balance' },
                                    { id: 7, key: 'created_date', label: 'Initiation Time' },
                                    { id: 8, key: 'transaction_code', label: 'Transaction Code' },
                                    { id: 9, key: 'is_reversed', label: 'Reversed' },
                                    { id: 10, key: 'account_operator_operator_display_name', label: 'Operator' },

                                ].map((header) => {
                                    if (header.key === 'transaction_type_enum') {
                                        return <>
                                            <dt>{header.label}</dt>
                                            <dd>{row[header.key] === 1 ? "In" : row[header.key] === 2 ? "Out" : ""}</dd>
                                        </>
                                    }

                                    if (header.key === 'amount' || header.key === 'running_balance_derived') {
                                        return <>
                                            <dt>{header.label}</dt>
                                            <dd>{fCurrency(row[header.key],shop.product_currency_code)}</dd>
                                        </>
                                    }
                                    if (header.key === 'transaction_code') {
                                        return <></>
                                    }
                                    if (header.key === 'is_reversed') {
                                        return <>
                                            <dt>{header.label}</dt>
                                            <dd>{row[header.key] === 1 ? <Label color="success">YES</Label> : <Label color="error">NO</Label>}</dd>
                                        </>
                                    }
                                    if (header.key === 'account_operator_operator_display_name') {
                                        return <>
                                            {row[header.key] && <> <dt>{header.label}</dt>
                                                <dd>{row[header.key]}</dd>
                                            </>
                                            }
                                        </>
                                    }
                                    if (header.key === 'created_date') {
                                        return <>
                                            <dt>{header.label}</dt>
                                            <dd>{fDateTime(row[header.key])}</dd>
                                        </>
                                    }
                                    return <>
                                        <dt>{header.label}</dt>
                                        <dd>{row[header.key]}</dd>
                                    </>
                                })}
                            </dl>
                        </Box>
                    </div>
                ))}
            </Box>
            <div>
                {newItems?.length > 0 && showButton && // conditionally render the button based on showButton value
                    <Button variant="outlined" style={{ float: 'right', marginRight: '10px' }} component={Link} to="/shop-dashboard/savings-account/transactions-statement">
                        View More
                    </Button>
                }
            </div>


        </>
    );
}