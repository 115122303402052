import React from 'react'
import {
    Button,
    Card,
    Paper,
    Box,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableHead,
    TableBody,
    TableContainer,
    Typography,
    Slide,
    TableRow,
    TableCell,
    Tabs,
    Tab
} from "@mui/material";
import useTabs from "../../../shared/hooks/useTabs";
import { toast, ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import call from '../../../core/services/http';
import useLocalStorage from "src/shared/hooks/useLocalStorage";
import Scrollbar from "src/shared/components/Scrollbar";
import Iconify from "src/shared/components/Iconify";
import Page from "../../../shared/components/Page";
import AuthService from "../../../core/access-control/AuthService";
import { StyledTableCell, StyledTableRow } from "../../../shared/sections/@dashboard/tableStyle/tableBody";
import SpinnerLoader from "../../../shared/plugin/loader/SpinnerLoader";
import { getCustomers, saveOperators } from "../../../shared/reducers/users/SharedUsersAction";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useNavigate } from "react-router-dom";
import { PATH_SHOP } from "src/routes/paths";
import { flattenObject } from "src/shared/utils/flattenObject";
import { fDateTime } from 'src/shared/utils/formatTime';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { fCurrency } from "src/shared/utils/formatNumber";
import TablePagination from '@mui/material/TablePagination';



const TABLE_HEAD = [

    { id: 'account_number', label: "Account Number", alignLeft: true },
    { id: 'description', label: 'Description', alignLeft: true },
    { id: 'date_created', label: 'Date Created', alignLeft: true },
    { id: 'action', label: '', alignLeft: true },

]

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
//
const notifyError = msg => {
    toast.error(msg)
}


const OperatorSchema = Yup.object().shape({
    account_operator_type: Yup.string().required('Please provide operator type'),
    notification_type: Yup.string().required('Provide notification type'),
    operator_sasapay_account_number: Yup.string().required('Provide an account number'),
});



const Successful = () => {

    const dispatch = useDispatch()
    const [shop] = useLocalStorage('shop-details')
    const [referred, setReferred] = useState([]);
    const [open, setOpen] = useState(false);
    const [notification, setNotificationOption] = useState("");
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);


    const { customers, loading, accountTypes } = useSelector(state => state.sharedUser)

    //
    const newcustomers = customers?.forEach(customer => flattenObject(customer));

    //console.log(customers, newcustomers)

    const defaultValues = {
        account_operator_type: '',
        notification_type: "",
        operator_sasapay_account_number: "",
        merchantId: AuthService?.getUserId(),
        savingsId: shop.id,


    };
    //
    const methods = useForm({
        resolver: yupResolver(OperatorSchema),
        defaultValues,
    });


    useEffect(() => {
        //set payload
        let payload = {
            merchantId: AuthService.getUserId(),
            savingsId: shop.id
        }
        //dispatch
        dispatch(getCustomers(payload))
    }, [dispatch]);

    const navigate = useNavigate()


    const getReferredAccounts = async (page, rowsPerPage) => {
        try {
            const res = await call('get', `dealership/${shop.id}`, { params: { page, rowsPerPage } });
            if (res.data.status) {
                setReferred(res.data.results);
                setTotalCount(res.data.count);
            }
            toast.success(res.data);
        } catch (error) {
            toast.error(error);
        }
    };
    

    useEffect(() => {
        getReferredAccounts(page, rowsPerPage);
    }, [page, rowsPerPage]);
    

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    return (
        <>
            <Page title="Customer">
                <ToastContainer theme="colored" />
                <CardHeader
                    sx={{ mb: 1, paddingTop: 0 }}
                    title={<Typography color="text.primary">Succesful</Typography>}
                // action={<Button onClick={() => navigate(PATH_SHOP.shop.main, { replace: true })} variant="outlined" color='primary' startIcon={<Iconify icon="uil:angle-left" />}>Back</Button>}
                />
                <Card sx={{ mb: 5 }} elevation={0}>


                    <CardContent>
                        {
                            loading ?
                                <Grid container sx={{ justifyContent: 'center' }}> <SpinnerLoader /></Grid>
                                :
                                <>
                                    <Scrollbar>

                                        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                                            <Table size={'small'}>
                                                <TableHead>
                                                    <StyledTableRow>
                                                        {TABLE_HEAD.map((headCell) => (
                                                            <StyledTableCell key={headCell.id} align={headCell.alignRight ? 'right' : 'left'}>{headCell.label}</StyledTableCell>
                                                        ))}
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        loading ?
                                                            <><SpinnerLoader /></> :
                                                            referred?.map((item) => {
                                                                const { id, account_number, description, date_created } = item
                                                                //const { first_name, middle_name, last_name, display_name, email, external_id, mobile_number, client_status, activation_date } = customer

                                                                return <>
                                                                    <TableRow
                                                                        hover
                                                                        key={id}
                                                                        tabIndex={id}
                                                                        role="checkbox"
                                                                    >
                                                                        <TableCell component="th" scope="row" align="left">{account_number}</TableCell>
                                                                        <TableCell component="th" scope="row" align="left">{description}</TableCell>
                                                                        <TableCell component="th" scope="row" align="left">{fDateTime(date_created)}</TableCell>
                                                                        <Button variant="contained" size="small" onClick={() => navigate(`/shop-dashboard/savings-account/dealer-transactions/${id}`)}>View More</Button>

                                                                    </TableRow>
                                                                </>
                                                            })
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            component="div"
                                            count={totalCount}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Scrollbar>
                                </>
                        }
                    </CardContent>
                </Card >
            </Page>

        </>
    )
}

export default Successful;
